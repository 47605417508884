import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import { Button, required, TextInput, useNotify, useRefresh } from "react-admin";
import { getAuthenticatedBackendClient } from "../api/backend.api.js";
import { reportError } from "../backoffice.utils.js";
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, Tooltip } from "@mui/material";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import { FormProvider, useForm } from "react-hook-form";
import { Invoice as InvoiceDto, UserAddress } from "../generated/backendClient";
import EditIcon from "@mui/icons-material/EditOutlined";

export function UpdateAddressOnPDFButton({ invoice }: { invoice: InvoiceDto }) {
  const refresh = useRefresh();
  const [dialogOpen, setDialogOpen] = useState(false);
  const formProps = useForm<UserAddress>({
    mode: "onChange",
    defaultValues: {
      name: invoice.recipient_address?.name || "",
      company: invoice.recipient_address?.company || "",
      street: invoice.recipient_address?.street || "",
      street2: invoice.recipient_address?.street2 || "",
      zipcode: invoice.recipient_address?.zipcode || "",
      city: invoice.recipient_address?.city || "",
      country: "DE",
    },
  });
  const onClose = () => {
    // prevent closing the dialog when the action is still running
    if (formProps.formState.isSubmitting) return;
    setDialogOpen(false);
  };

  const notify = useNotify();
  const updateAddressOnPDF = async (userAddress: UserAddress) => {
    try {
      const backendClient = await getAuthenticatedBackendClient();
      notify(`Das PDF wird neu generiert...`, { type: "success" });
      await backendClient.invoice.invoiceUpdateAddressOnPdfCreate({ id: invoice.id, requestBody: userAddress });
      notify(`Fertig. Bitte überprüfe manuell noch einmal ob das PDF nun korrekt ist.`, { type: "success" });
      refresh();
    } catch (error) {
      reportError(`Error when regenerating invoice PDF ${invoice.id}`, error);
      notify(`Das Rechnungs PDF konnte nicht neu generiert werden.`, { type: "error" });
    } finally {
      setDialogOpen(false);
    }
  };

  return (
    <>
      <Tooltip title="Editieren">
        <Button onClick={() => setDialogOpen(true)} startIcon={<EditIcon style={{ fontSize: 20 }} />} label="" />
      </Tooltip>
      {dialogOpen && (
        <Dialog open={dialogOpen} onClose={onClose}>
          <DialogTitle>Rechnungsaddresse ändern</DialogTitle>
          <DialogCloseButton onClose={onClose} disabled={formProps.formState.isSubmitting} />
          <DialogContent>
            <FormProvider {...formProps}>
              <form>
                <Stack style={{ marginTop: "10px" }}>
                  <TextInput
                    disabled={formProps.formState.isSubmitting}
                    source="name"
                    label="Name"
                    validate={required()}
                  />
                  <TextInput disabled={formProps.formState.isSubmitting} source="company" label="Firma" />
                  <TextInput
                    disabled={formProps.formState.isSubmitting}
                    source="street"
                    label="Straße"
                    validate={required()}
                  />
                  <TextInput disabled={formProps.formState.isSubmitting} source="street2" label="Straße 2" />
                  <TextInput
                    disabled={formProps.formState.isSubmitting}
                    source="city"
                    label="Ort"
                    validate={required()}
                  />
                  <TextInput
                    disabled={formProps.formState.isSubmitting}
                    source="zipcode"
                    label="PLZ"
                    validate={required()}
                  />
                  {/*<TextInput source="country" />*/}
                </Stack>
              </form>
            </FormProvider>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              variant="contained"
              onClick={formProps.handleSubmit(updateAddressOnPDF)}
              loading={formProps.formState.isSubmitting}
              disabled={formProps.formState.isSubmitting}
            >
              Speichern
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
