import { Instructor } from "../providers/instructorsProvider";
import { Student } from "../providers/studentsProvider";
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { type ReactNode, useEffect, useRef, useState } from "react";
import { StudentSelect } from "../inputs/StudentSelect";
import { LoadingButton } from "@mui/lab";
import { LinearProgress, Tab, useGetManyReference, useGetOne, useNotify, useRefresh } from "react-admin";
import { DrivingLicenseClass, isForMotorcycle, isForTrailer } from "../model/DrivingLicenseClass";
import { ExamType } from "../model/ExamType";
import { Controller, FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";
import { DateTime, Interval } from "luxon";
import { ExamLocation } from "../model/ExamLocation";
import { useQueryClient } from "react-query";
import {
  bookDrivingLesson,
  bookExam,
  getPriceSuggestion,
  GetPriceSuggestionRequestSchema,
} from "../api/backoffice.api";
import { examLocationsProvider } from "../providers/examLocationsProvider";
import { Money } from "../model/Money";
import { moneyFormatter } from "../misc/Money";
import { grants } from "../backoffice.access_control";
import { renderAddress } from "../misc/AddressDisplay";
import { handleBookingError } from "../utils/handleBookingError";
import { Tabs } from "../misc/Tabs";
import { startingPointsProvider } from "../providers/startingPointsProvider";
import { DrivingLesson } from "../model/autovioCalendarEvents";
import { instructorRecommendationSettingsProvider } from "../providers/instructorRecommendationSettingsProvider";
import { Row } from "../misc/Row";
import { type DateClickArg } from "@fullcalendar/interaction";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { autovioColors } from "../misc/backofficeTheme";
import { Column } from "../misc/Column";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { formatTime } from "../utils/calendar";
import { SelectInput } from "../inputs/SelectInput";
import { InstructorInput } from "../inputs/InstructorInput";
import { DateTimeInput } from "../inputs/DateTimeInput";
import { AppointmentDurationInput } from "../inputs/AppointmentDurationInput";
import { DrivingLessonTypeInput } from "../inputs/DrivingLessonTypeInput";
import { DrivingLicenseClassInput } from "../inputs/DrivingLicenseClassInput";
import { VehiclesInput, OWN_MOTORCYCLE_PSEUDO_ID } from "../inputs/VehiclesInput";
import { StartingPointInput } from "../inputs/StartingPointInput";

interface AddAppointmentFormValues {
  drivingLessonType: "" | DrivingLesson["drivingLessonType"] | "theoretischePruefung";
  dateTime: undefined | DateTime;
  durationInMinutes: number;
  startingPointId: string;
  theoryExamLocationId: string;
  practicalExamLocationId: string;
  instructorId: string;
  student: undefined | Student;
  drivingLicenseClass: "" | DrivingLicenseClass;
  carId: string;
  motorcycleId: string;
  trailerId: string;
  secondMotorcycleOrCarId: string;
}

interface BookDrivingLessonParams {
  drivingLessonType: "normal" | "ueberlandfahrt" | "autobahnfahrt" | "nachtfahrt";
  examType?: never;
  instructorId: string;
  studentId: string;
  bookedTrainingId: string;
  dateTime: DateTime;
  duration: { minutes: number };
  startingPointId: string;
  examLocationId?: never;
  resourceIds: Array<string>;
}

interface BookTheoryExamParams {
  drivingLessonType: "theoretischePruefung";
  instructorId: string;
  studentId: string;
  bookedTrainingId: string;
  dateTime: DateTime;
  duration?: never;
  startingPointId?: never;
  examLocationId: string;
}

interface BookPracticalExamParams {
  drivingLessonType: "praktischePruefung";
  instructorId: string;
  studentId: string;
  bookedTrainingId: string;
  dateTime: DateTime;
  duration?: never;
  startingPointId?: never;
  examLocationId: string;
  resourceIds: Array<string>;
}

function validate(
  formValues: AddAppointmentFormValues,
): BookDrivingLessonParams | BookTheoryExamParams | BookPracticalExamParams {
  const {
    dateTime,
    durationInMinutes,
    drivingLessonType,
    startingPointId,
    theoryExamLocationId,
    practicalExamLocationId,
    student,
    drivingLicenseClass,
    carId,
    motorcycleId,
    secondMotorcycleOrCarId,
    trailerId,
  } = formValues;
  if (!dateTime) {
    throw new Error(`Invalid dateTime: ${dateTime}`);
  }
  if (!drivingLessonType) {
    throw new Error(`Invalid drivingLessonType: ${drivingLessonType}`);
  }
  if (!student) {
    throw new Error(`Invalid student: ${student}`);
  }
  if (drivingLessonType === "theoretischePruefung") {
    if (!theoryExamLocationId) {
      throw new Error(`Invalid theoryExamLocationId: ${theoryExamLocationId}`);
    }
    let instructorId = formValues.instructorId;
    if (!instructorId) {
      instructorId = student.instructorIds[0];
    }
    if (!instructorId) {
      throw new Error(`Student ${student.id} has no instructor`);
    }
    const relevantBookedTrainings = student.bookedTrainings.filter((it) => it.includesTheoryExam);
    // Prefer booked training for motorcycle if present ...
    const bookedTraining =
      relevantBookedTrainings.find((it) => isForMotorcycle(it.drivingLicenseClass)) ?? relevantBookedTrainings[0];
    if (!bookedTraining) {
      throw new Error(`Student ${student.id} has no booked training with a theory exam`);
    }
    return {
      drivingLessonType: "theoretischePruefung",
      studentId: student.id,
      instructorId,
      bookedTrainingId: bookedTraining.id,
      dateTime,
      examLocationId: theoryExamLocationId,
    } satisfies BookTheoryExamParams;
  } else {
    const instructorId = formValues.instructorId;
    if (!instructorId) {
      throw new Error(`Invalid instructorId: ${instructorId}`);
    }
    if (!drivingLicenseClass) {
      throw new Error(`Invalid drivingLicenseClass: ${drivingLicenseClass}`);
    }
    let resourceIds: Array<string>;
    if (isForMotorcycle(drivingLicenseClass)) {
      if (!motorcycleId) {
        throw new Error(`Invalid motorcycleId: ${motorcycleId}`);
      }
      const studentUsesOwnMotorcycle = motorcycleId === OWN_MOTORCYCLE_PSEUDO_ID;
      if (drivingLessonType === "praktischePruefung") {
        if (!carId) {
          throw new Error(`Invalid carId: ${carId}`);
        }
        resourceIds = studentUsesOwnMotorcycle ? [carId] : [motorcycleId, carId];
      } else {
        if (!secondMotorcycleOrCarId) {
          throw new Error(`Invalid secondMotorcycleOrCarId: ${secondMotorcycleOrCarId}`);
        }
        if (secondMotorcycleOrCarId === motorcycleId) {
          throw new Error(`secondMotorcycleOrCarId must not be equal to motorcycleId`);
        }
        resourceIds = studentUsesOwnMotorcycle ? [secondMotorcycleOrCarId] : [motorcycleId, secondMotorcycleOrCarId];
      }
    } else if (isForTrailer(drivingLicenseClass)) {
      if (!carId) {
        throw new Error(`Invalid carId: ${carId}`);
      }
      if (!trailerId) {
        throw new Error(`Invalid trailerId: ${motorcycleId}`);
      }
      resourceIds = [carId, trailerId];
    } else {
      if (!carId) {
        throw new Error(`Invalid carId: ${carId}`);
      }
      resourceIds = [carId];
    }
    const bookedTraining = student.bookedTrainings.find((it) => it.drivingLicenseClass === drivingLicenseClass);
    if (!bookedTraining) {
      throw new Error(`Student ${student.id} has no booked training for driving license class ${drivingLicenseClass}`);
    }
    if (drivingLessonType === "praktischePruefung") {
      if (!practicalExamLocationId) {
        throw new Error(`Invalid practicalExamLocationId: ${practicalExamLocationId}`);
      }
      return {
        drivingLessonType: "praktischePruefung",
        instructorId,
        studentId: student.id,
        bookedTrainingId: bookedTraining.id,
        dateTime,
        examLocationId: practicalExamLocationId,
        resourceIds,
      } satisfies BookPracticalExamParams;
    } else {
      if (!["normal", "ueberlandfahrt", "autobahnfahrt", "nachtfahrt"].includes(drivingLessonType)) {
        throw new Error(`Invalid drivingLessonType: ${drivingLessonType}`);
      }
      if (!startingPointId) {
        throw new Error(`Invalid startingPointId: ${startingPointId}`);
      }
      if (!durationInMinutes) {
        throw new Error(`Invalid durationInMinutes: ${durationInMinutes}`);
      }
      return {
        drivingLessonType: drivingLessonType as "normal" | "ueberlandfahrt" | "autobahnfahrt" | "nachtfahrt",
        instructorId,
        studentId: student.id,
        bookedTrainingId: bookedTraining.id,
        dateTime,
        duration: { minutes: durationInMinutes },
        startingPointId,
        resourceIds,
      } satisfies BookDrivingLessonParams;
    }
  }
}

interface AddAppointmentDialogProps {
  open: boolean;
  onClose: () => void;
  instructor?: Instructor;
  drivingSchoolId?: string;
  mode?: "only exams";
}

interface AddAppointmentDialogType {
  (props: AddAppointmentDialogProps): ReactNode;

  initialDateTime?: DateTime;
  fullCalendarDateClickHandler: (openDialog: () => void) => (arg: DateClickArg) => void;
}

const _drivingLessonTypeOptions: Array<[string, string]> = [
  ["normal", "normale Übungsstunde"],
  ["autobahnfahrt", "Autobahnfahrt"],
  ["nachtfahrt", "Nachtfahrt"],
  ["ueberlandfahrt", "Überlandfahrt"],
];

function _AddAppointmentDialog(props: AddAppointmentDialogProps) {
  const notify = useNotify();
  const refresh = useRefresh();
  const queryClient = useQueryClient();
  const initialDateTime = AddAppointmentDialog.initialDateTime;
  AddAppointmentDialog.initialDateTime = undefined; // <-- Side effect: reset AddAppointmentDialog.initialDateTime
  const defaultValues = {
    drivingLessonType: props.mode === "only exams" ? "" : "normal",
    dateTime: initialDateTime,
    durationInMinutes: 0,
    startingPointId: "",
    theoryExamLocationId: "",
    practicalExamLocationId: "",
    instructorId: props.instructor?.id ?? "",
    student: undefined,
    drivingLicenseClass: "",
    carId: "",
    motorcycleId: "",
    trailerId: "",
    secondMotorcycleOrCarId: "",
  } satisfies AddAppointmentFormValues;
  const formProps = useForm<AddAppointmentFormValues>({
    defaultValues:
      props.mode === "only exams"
        ? defaultValues
        : async () => {
            let durationInMinutes = defaultValues.durationInMinutes;
            if (props.instructor) {
              const { data } = await instructorRecommendationSettingsProvider.getOne("", { id: props.instructor.id });
              if (data) {
                durationInMinutes = data.preferredDurationMinsPerDrivingLessonType.normal[0];
              }
            }
            return { ...defaultValues, durationInMinutes };
          },
  });

  const drivingSchoolId = (props.instructor?.drivingSchoolId ?? props.drivingSchoolId) as string;
  const {
    drivingLessonType,
    dateTime,
    durationInMinutes,
    startingPointId,
    theoryExamLocationId,
    practicalExamLocationId,
    instructorId,
    student,
    drivingLicenseClass,
    carId,
    motorcycleId,
    trailerId,
    secondMotorcycleOrCarId,
  } = formProps.watch();

  const [price, setPrice] = useState<undefined | Money>();
  const [authorityFee, setAuthorityFee] = useState<undefined | Money>();
  const priceSuggestionCache = useRef<Record<string, ReturnType<typeof getPriceSuggestion>>>({}).current;
  useEffect(() => {
    setPrice(undefined);
    setAuthorityFee(undefined);
    let params: undefined | ReturnType<typeof validate>;
    try {
      params = validate({
        drivingLessonType,
        dateTime,
        durationInMinutes,
        startingPointId,
        theoryExamLocationId,
        practicalExamLocationId,
        instructorId,
        student,
        drivingLicenseClass,
        carId,
        motorcycleId,
        trailerId,
        secondMotorcycleOrCarId,
      });
    } catch (error) {
      // form is invalid
      return;
    }
    void (async () => {
      try {
        const request = GetPriceSuggestionRequestSchema.parse({
          eventType: "DrivingLesson",
          drivingLessonType,
          instructorUid: params.instructorId,
          studentUid: params.studentId,
          bookedTrainingId: params.bookedTrainingId,
          start: params.dateTime,
          ...(params.duration ? { end: params.dateTime.plus(params.duration) } : {}),
        });
        const cacheKey = JSON.stringify(request);
        let promise = priceSuggestionCache[cacheKey];
        if (!promise) {
          priceSuggestionCache[cacheKey] = promise = getPriceSuggestion(request);
        }
        const priceSuggestion = await promise;
        let priceSuggestionMatchesFormValues = false;
        try {
          const params2 = validate(formProps.getValues());
          priceSuggestionMatchesFormValues =
            params.drivingLessonType === params2.drivingLessonType &&
            params.instructorId === params2.instructorId &&
            params.studentId === params2.studentId &&
            params.bookedTrainingId === params2.bookedTrainingId &&
            params.dateTime === params2.dateTime &&
            params.duration?.minutes === params2.duration?.minutes;
        } catch {
          // Ignored: form is invalid
          return;
        }
        if (priceSuggestionMatchesFormValues) {
          setPrice(priceSuggestion.price);
          setAuthorityFee(priceSuggestion.authorityFee);
        }
      } catch (error) {
        console.error("Failed to get price suggestion", error);
      }
    })();
  }, [
    formProps,
    drivingLessonType,
    dateTime,
    durationInMinutes,
    instructorId,
    student?.id,
    drivingLicenseClass,
    carId,
    motorcycleId,
    trailerId,
    secondMotorcycleOrCarId,
    startingPointId,
    theoryExamLocationId,
    practicalExamLocationId,
  ]);

  const { data: instructor } = useGetOne("instructors", { id: instructorId }, { enabled: !!instructorId });

  const onSubmit = async (formValues: AddAppointmentFormValues) => {
    try {
      const params = validate(formValues);
      const studentRsvp = params.dateTime < DateTime.now() ? "accepted" : "pending";
      if (params.drivingLessonType === "theoretischePruefung" || params.drivingLessonType === "praktischePruefung") {
        const { drivingLessonType, examLocationId, ...rest } = params;
        const examType = drivingLessonType === "theoretischePruefung" ? "theoryExam" : "practicalExam";
        const { data: examLocation } = await examLocationsProvider.getOne("examLocations", { id: examLocationId });
        const location = examLocation.postalAddress;
        await bookExam({ ...rest, examType, location, studentRsvp });
        notify(
          studentRsvp === "pending" ? "Einladung erfolgreich versandt" : "Prüfungstermin erfolgreich eingetragen",
          { type: "success" },
        );
      } else {
        const { startingPointId, ...rest } = params;
        const { data: startingPoint } = await startingPointsProvider.getOne("startingPoints", { id: startingPointId });
        const location = startingPoint.postalAddress;
        await bookDrivingLesson({ ...rest, location, studentRsvp });
        notify(studentRsvp === "pending" ? "Einladung erfolgreich versandt" : "Fahrstunde erfolgreich eingetragen", {
          type: "success",
        });
      }
      props.onClose();
      await queryClient.invalidateQueries(["recommendations", "student"]);
      await queryClient.invalidateQueries(["calendarEvents"]);
      await queryClient.invalidateQueries(["calendarEventHistory"]);
      refresh();
    } catch (error) {
      if (!(await handleBookingError(error, formValues.student, notify))) {
        console.error("Failed to invite student", error);
        notify("Fehler beim Versenden der Einladung", { type: "error" });
      }
    }
  };

  const drivingLessonForm = (
    <WhenFormIsInitialized>
      <Stack gap="1em">
        <DateTimeInput source="dateTime" sx={{ mt: "5px" /* <-- prevents that the input label is truncated */ }} />
        <AppointmentDurationInput source="durationInMinutes" />
        <DrivingLessonTypeInput source="drivingLessonType" options={_drivingLessonTypeOptions} />
        <StudentInputField instructorId={instructorId} />
        {!student && <_StudentSuggestions />}
        {student && (
          <DrivingLicenseClassInput
            student={student}
            source="drivingLicenseClass"
            label="Ausbildung für Führerscheinklasse"
          />
        )}
        {student && instructor && (
          <VehiclesInput
            student={student}
            instructor={instructor}
            drivingLessonType={drivingLessonType}
            drivingLicenseClass={drivingLicenseClass}
          />
        )}
        {student && <StartingPointInput source="startingPointId" instructor={instructor} student={student} />}
        {/* [UX] Render a non-breaking space if no price is available to prevent the dialog from jumping ... */}
        {!price && <Typography>{"\u00A0"}</Typography>}
        {price && <Typography>Preis: {moneyFormatter.format(price.amount)}</Typography>}
      </Stack>
    </WhenFormIsInitialized>
  );

  const renderingTheoryExamForm = drivingLessonType === "theoretischePruefung";
  const renderingPracticalExamForm = drivingLessonType === "praktischePruefung";

  const examForm = (
    <Stack gap="1em">
      <DateTimeInput
        source="dateTime"
        allowPastDates={grants.includes("addExamInThePast")}
        sx={{ mt: "5px" /* <-- prevents that the input label is truncated */ }}
      />
      <ExamTypeInputField />
      {renderingPracticalExamForm && !props.instructor && (
        <InstructorInput drivingSchoolId={drivingSchoolId} source="instructorId" />
      )}
      {renderingTheoryExamForm && props.instructor && <StudentInputField instructorId={instructorId} />}
      {renderingTheoryExamForm && !props.instructor && <StudentInputField drivingSchoolId={drivingSchoolId} />}
      {renderingPracticalExamForm && instructorId && <StudentInputField instructorId={instructorId} />}
      {renderingPracticalExamForm && student && (
        <DrivingLicenseClassInput source="drivingLicenseClass" student={student} />
      )}
      {renderingPracticalExamForm && student && instructor && (
        <VehiclesInput
          student={student}
          instructor={instructor}
          drivingLessonType={drivingLessonType}
          drivingLicenseClass={drivingLicenseClass}
        />
      )}
      {renderingTheoryExamForm && student && <TheoryExamLocationInputField drivingSchoolId={drivingSchoolId} />}
      {renderingPracticalExamForm && student && <PracticalExamLocationInputField drivingSchoolId={drivingSchoolId} />}
      <Row>
        {/* [UX] Render a non-breaking space if no price is available to prevent the dialog from jumping ... */}
        {!(price || authorityFee) && <Typography>{"\u00A0"}</Typography>}
        {price && <Typography>Preis: {moneyFormatter.format(price.amount)}</Typography>}
        {price && authorityFee && <Typography>{"\u00A0+\u00A0"}</Typography>}
        {authorityFee && <Typography>TÜV-/DEKRA-Gebühr: {moneyFormatter.format(authorityFee.amount)}</Typography>}
      </Row>
    </Stack>
  );

  return (
    <FormProvider {...formProps}>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>{`Neuer ${props.mode === "only exams" ? "Prüfungstermin" : "Termin"}`}</DialogTitle>
        <DialogCloseButton onClose={props.onClose} disabled={formProps.formState.isSubmitting} />
        <form onSubmit={formProps.handleSubmit(onSubmit)}>
          <DialogContent sx={{ minWidth: "400px" }}>
            {props.mode === "only exams" && examForm}
            {props.mode !== "only exams" && (
              <Tabs sx={{ m: 0 }} syncWithLocation={false}>
                <Tab label="Fahrstunde">{drivingLessonForm}</Tab>
                <Tab label="Prüfung">{examForm}</Tab>
              </Tabs>
            )}
          </DialogContent>
          <DialogActions>
            <LoadingButton
              variant="contained"
              loading={formProps.formState.isSubmitting}
              disabled={formProps.formState.isLoading || formProps.formState.isSubmitting}
              onClick={formProps.handleSubmit(onSubmit)}
            >
              {dateTime && dateTime < DateTime.now() ? "Termin eintragen" : "Einladung senden"}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </FormProvider>
  );
}

export const AddAppointmentDialog: AddAppointmentDialogType = _AddAppointmentDialog as any;
AddAppointmentDialog.fullCalendarDateClickHandler =
  (openDialog: () => void) =>
  ({ dateStr, dayEl, jsEvent }) => {
    try {
      let dateTime = DateTime.fromISO(dateStr, { zone: "Europe/Berlin" });
      // We need to adapt dateTime here, because sometimes FullCalendar does not pass quite the correct date ...
      // Furthermore we add 15 minutes if the click was in the lower half of the 30 min time slot ...
      const { top, height } = dayEl.getBoundingClientRect();
      const h = ((24 - 6) / height) * (jsEvent.clientY - top);
      const m = (h - Math.floor(h)) * 60;
      if (dateTime.get("minute") === 0) {
        if (m >= 25) {
          dateTime = dateTime.plus({ minutes: 30 });
        } else if (m >= 10) {
          dateTime = dateTime.plus({ minutes: 15 });
        }
      } else if (dateTime.get("minute") === 30) {
        if (m >= 55) {
          dateTime = dateTime.plus({ minutes: 30 });
        } else if (m >= 40) {
          dateTime = dateTime.plus({ minutes: 15 });
        }
      } else {
        throw new Error(`Unexpected dateTime.get("minute"): ${dateTime.get("minute")}`);
      }
      AddAppointmentDialog.initialDateTime = dateTime;
      openDialog();
    } catch (error) {
      console.error("Failed to handle dateClick", error);
    }
  };

function WhenFormIsInitialized({ children }: { children: ReactNode }) {
  const { formState } = useFormContext();
  return formState.isLoading ? null : children;
}

const _examTypeOptions: Array<[string, string]> = [
  ["theoretischePruefung", "Theorieprüfung"],
  ["praktischePruefung", "Praktische Prüfung"],
];

function ExamTypeInputField() {
  return (
    <SelectInput
      source="drivingLessonType"
      label="Art der Prüfung"
      options={_examTypeOptions}
      validate={(value) => (value ? true : "Bitte wähle die Art der Prüfung aus.")}
    />
  );
}

function StudentInputField(props: { drivingSchoolId?: string; instructorId?: string }) {
  const { control, setValue, watch } = useFormContext<AddAppointmentFormValues>();
  const student = watch("student");
  return (
    <Controller
      name="student"
      control={control}
      render={({ field, formState }) => (
        <StudentSelect
          inputRef={field.ref}
          label="Fahrschüler"
          size="medium"
          disabled={formState.isSubmitting}
          drivingSchoolId={props.drivingSchoolId}
          instructorId={props.instructorId}
          value={student}
          onChange={(value) => {
            field.onChange({ target: { value } });
            if (value) {
              setValue("drivingLicenseClass", value.activeOrMostRecentBookedTraining.drivingLicenseClass);
            }
          }}
          onBlur={field.onBlur}
          error={formState.errors.student?.message}
        />
      )}
      rules={{
        validate: (value?: Student) => {
          return value ? true : "Bitte wähle den Fahrschüler aus.";
        },
      }}
    />
  );
}

function TheoryExamLocationInputField(props: { drivingSchoolId: string }) {
  const { data: examLocations } = useGetManyReference<ExamLocation>("examLocations", {
    target: "drivingSchoolId",
    id: props.drivingSchoolId,
    filter: { types: "theoryExam" satisfies ExamType },
    sort: { field: "name", order: "ASC" },
    pagination: { page: 1, perPage: 999 },
  });
  return (
    <SelectInput
      source="theoryExamLocationId"
      label="Ort"
      options={examLocations?.map((it) => [
        it.id,
        `${it.name} (${renderAddress(it.postalAddress, { oneLine: true })})`,
      ])}
      validate={(value) => (value ? true : "Bitte wähle den Ort aus.")}
    />
  );
}

function PracticalExamLocationInputField(props: { drivingSchoolId: string }) {
  const { data: examLocations } = useGetManyReference<ExamLocation>("examLocations", {
    target: "drivingSchoolId",
    id: props.drivingSchoolId,
    filter: { types: "practicalExam" satisfies ExamType },
    sort: { field: "name", order: "ASC" },
    pagination: { page: 1, perPage: 999 },
  });
  return (
    <SelectInput
      source="practicalExamLocationId"
      label="Startpunkt"
      options={examLocations?.map((it) => [
        it.id,
        `${it.name} (${renderAddress(it.postalAddress, { oneLine: true })})`,
      ])}
      validate={(value) => (value ? true : "Bitte wähle den Startpunkt aus.")}
    />
  );
}

function _StudentSuggestions() {
  const [{ drivingSchoolId }] = useAutovioContext();
  const dateTime = useWatch({ name: "dateTime" });
  const instructorId = useWatch({ name: "instructorId" });
  if (!drivingSchoolId || !dateTime) {
    return null;
  }
  return <__StudentSuggestions drivingSchoolId={drivingSchoolId} dateTime={dateTime} instructorId={instructorId} />;
}

function __StudentSuggestions({
  drivingSchoolId,
  dateTime,
  instructorId,
}: {
  drivingSchoolId: string;
  dateTime: DateTime;
  instructorId?: string;
}) {
  const drivingLessons: Array<DrivingLesson> = [];
  let isLoading = false;
  for (const daysAgo of [7, 14, 21, 28]) {
    // A general rule is: Hooks should not be called in if statements or loops.
    // But this is one exception to this rule, which is safe, because the number
    // of iterations is always the same.
    const { data } = useGetManyReference("drivingLessons", {
      target: "drivingSchoolId",
      id: drivingSchoolId,
      filter: {
        ...(instructorId ? { instructorId } : {}),
        dateRange: Interval.fromDateTimes(
          dateTime.minus({ days: daysAgo, minutes: 90 }),
          dateTime.minus({ days: daysAgo }).plus({ minute: 90 }),
        ),
      },
      pagination: { page: 1, perPage: 999 },
      sort: { field: "start", order: "ASC" },
    });
    if (data) {
      drivingLessons.push(...data);
    } else {
      isLoading = true;
    }
  }
  const drivingLessonsByStudentUid: { [studentUid: string]: Array<DrivingLesson> } = {};
  for (const drivingLesson of drivingLessons) {
    const a = drivingLessonsByStudentUid[drivingLesson.student.uid];
    if (a) {
      a.push(drivingLesson);
    } else {
      drivingLessonsByStudentUid[drivingLesson.student.uid] = [drivingLesson];
    }
  }
  return (
    <div>
      <h4 style={{ color: autovioColors.grey }}>Vorschläge</h4>
      <div>
        {isLoading && <LinearProgress />}
        {!isLoading && drivingLessons.length === 0 && (
          <>
            {"Keine Fahrstunden in den letzten 4 Wochen"}
            <br />
            {`um ${formatTime(dateTime)} Uhr ±90 Minuten gefunden.`}
          </>
        )}
        {Object.entries(drivingLessonsByStudentUid).map(([studentUid, drivingLessons]) => (
          <_StudentSuggestion
            key={studentUid}
            dateTime={dateTime}
            studentUid={studentUid}
            drivingLessons={drivingLessons}
          />
        ))}
      </div>
    </div>
  );
}

function _StudentSuggestion({
  dateTime,
  studentUid,
  drivingLessons,
}: {
  dateTime: DateTime;
  studentUid: string;
  drivingLessons: Array<DrivingLesson>;
}) {
  const { data: student } = useGetOne<Student>("students", { id: studentUid });
  const { setValue } = useFormContext();
  if (!student || student.status !== "active") {
    return null;
  }
  const drivingLesson = drivingLessons[0];
  const weeksAgo = Math.floor(dateTime.diff(drivingLesson.start, "weeks").weeks);
  return (
    <Paper
      sx={{
        border: "0.8px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "20px",
        padding: "15px",
        cursor: "pointer",
        ":hover": {
          backgroundColor: autovioColors.greyUltraLight,
        },
      }}
      onClick={() => {
        setValue("student", student);
        setValue("drivingLicenseClass", student.activeOrMostRecentBookedTraining.drivingLicenseClass);
      }}
    >
      <Row alignItems="center" spacing={1}>
        <Avatar src={student?.avatarOverrideUrl ?? student?.avatarUrl} />
        <Column sx={{ flex: 1 }}>
          <Typography variant="body2" fontWeight="bold">
            {student?.name}
          </Typography>
          <Typography variant="body2">
            {`Fahrstunde vor ${weeksAgo} ${weeksAgo === 1 ? "Woche" : "Wochen"} um ${formatTime(
              drivingLesson.start,
            )} Uhr`}
          </Typography>
        </Column>
        <IconButton
          onClick={(event) => {
            window.open(`/students/${studentUid}`, "_blank");
            event.stopPropagation();
            return false;
          }}
        >
          <OpenInNewIcon htmlColor={autovioColors.green} />
        </IconButton>
      </Row>
    </Paper>
  );
}
