import { Box } from "@mui/material";
import { overviewCardStyle } from "../../../misc/backofficeTheme";
import { NotesAndDocuments } from "../../../lists/NotesAndDocuments";

export type NotesAndDocumentsSource = "student" | "drivingSchool";

export function NotesAndDocumentsCard({ source }: { source: NotesAndDocumentsSource }) {
  return (
    <Box sx={overviewCardStyle}>
      <NotesAndDocuments source={source} />
    </Box>
  );
}
