import { List, SelectInput, useRecordContext } from "react-admin";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";
import { StudentsDataGrid, StudentsDataGridLegend } from "../../lists/StudentsDataGrid";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import { PageTitle } from "../../misc/PageTitle";
import { DrivingLicenseClassEnum } from "../../model/DrivingLicenseClass";

export function DrivingSchoolStudentsList() {
  const drivingSchool = useRecordContext<DrivingSchool>();

  if (!drivingSchool) {
    return (
      <>
        <PageTitle>Fahrschüler</PageTitle>
        <LoadingIndicator />
      </>
    );
  }

  return (
    <div style={{ position: "relative" /* ... for positioning the <StudentsDataGridLegend /> */ }}>
      <StudentsDataGridLegend />
      <List
        resource="students"
        title={<PageTitle>Fahrschüler</PageTitle>}
        filter={{ drivingSchoolId: drivingSchool.id }}
        exporter={false}
        filters={[
          <SelectInput
            label="Filiale"
            source="branchId"
            choices={drivingSchool.branches.map(({ uid, name }) => ({ id: uid, name }))}
            alwaysOn
          />,
          <SelectInput
            label="Status"
            source="status"
            choices={[
              { id: "active", name: "Aktiv" },
              { id: "cancelled", name: "Abgebrochen" },
              { id: "inactive", name: "Inaktiv" },
              { id: "onHold", name: "Pausiert" },
              { id: "outstandingPayments", name: "Offene Zahlungen" },
              { id: "completed", name: "Fertig" },
            ]}
            alwaysOn
          />,
          <SelectInput
            label="Führerscheinklasse"
            source="drivingLicenseClasses"
            choices={DrivingLicenseClassEnum.options.map((val) => ({ id: val, name: val }))}
            alwaysOn
          />,
          <SelectInput
            label="Prüfauftrag"
            source="drivingLicenseApplicationStatus"
            choices={[
              { id: "pending", name: "Ausstehend" },
              { id: "approved", name: "Genehmigt" },
              { id: "notApproved", name: "Abgelehnt" },
            ]}
            alwaysOn
          />,
          <SelectInput
            label="Fahrstunden/Woche"
            source="guaranteedDrivingLessonsPerWeek"
            choices={[
              { id: 2, name: "2 UE/W" },
              { id: 4, name: "4 UE/W" },
              { id: 8, name: "8 UE/W" },
            ]}
            alwaysOn
          />,
        ]}
      >
        <StudentsDataGrid drivingSchool={drivingSchool} />
      </List>
    </div>
  );
}
