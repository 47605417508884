export function KPICreditCardIcon() {
  return (
    <svg width="19" height="19" viewBox="0.5 0.5 19.5 19.5" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.97 6.97h-12v-1.5h12m0 9h-12v-4.5h12m0-6h-12c-.832 0-1.5.668-1.5 1.5v9a1.5 1.5 0 0 0 1.5 1.5h12a1.5 1.5 0 0 0 1.5-1.5v-9a1.5 1.5 0 0 0-1.5-1.5z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}
