import { useRef, useEffect } from "react";

export const useDebouncedEffect = (callback: () => void, delay: number, deps: any[]) => {
  const lastCall = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (lastCall.current) clearTimeout(lastCall.current);

    lastCall.current = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      if (lastCall.current) clearTimeout(lastCall.current);
    };
  }, [...deps, delay]);
};
