export function KPIEuroIcon() {
  return (
    <svg width="19" height="19" viewBox="0.5 0.5 19.5 19.5" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.22 16.87c-1.487 0-2.809-.424-3.965-1.274a6.951 6.951 0 0 1-2.466-3.263H3.221v-1.575h2.212a1.819 1.819 0 0 1-.037-.356v-.704c0-.106.012-.227.037-.365H3.221V7.758h2.568a6.951 6.951 0 0 1 2.466-3.262c1.156-.85 2.478-1.275 3.966-1.275.875 0 1.706.156 2.493.468a6.818 6.818 0 0 1 2.12 1.332l-1.407 1.406a5.192 5.192 0 0 0-1.491-.872 4.799 4.799 0 0 0-1.715-.31c-.913 0-1.74.232-2.484.694A4.74 4.74 0 0 0 8.02 7.76h4.2v1.574H7.496a2.58 2.58 0 0 0-.048.356 6.701 6.701 0 0 0 0 .713c.007.112.023.231.048.356h4.725v1.575h-4.2c.4.75.972 1.356 1.716 1.819a4.608 4.608 0 0 0 2.484.694c.6 0 1.178-.104 1.734-.31a4.413 4.413 0 0 0 1.453-.872l1.425 1.407a6.818 6.818 0 0 1-2.119 1.33 6.699 6.699 0 0 1-2.493.47z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}
