/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { CompanyEndpoint } from './services/CompanyEndpoint';
import { CreditNoteEndpoint } from './services/CreditNoteEndpoint';
import { DunningProcessEndpoint } from './services/DunningProcessEndpoint';
import { FinanceReportEndpoint } from './services/FinanceReportEndpoint';
import { FirebaseEndpoint } from './services/FirebaseEndpoint';
import { InvoiceEndpoint } from './services/InvoiceEndpoint';
import { InvoiceNoAuthEndpoint } from './services/InvoiceNoAuthEndpoint';
import { OrderEndpoint } from './services/OrderEndpoint';
import { PaymentEndpoint } from './services/PaymentEndpoint';
import { PaymentOptionsEndpoint } from './services/PaymentOptionsEndpoint';
import { PayoutEndpoint } from './services/PayoutEndpoint';
import { PayoutItemsEndpoint } from './services/PayoutItemsEndpoint';
import { PerformanceOverviewEntryEndpoint } from './services/PerformanceOverviewEntryEndpoint';
import { PerformanceOverviewReportEndpoint } from './services/PerformanceOverviewReportEndpoint';
import { PricingEndpoint } from './services/PricingEndpoint';
import { StripeEndpoint } from './services/StripeEndpoint';
import { TaxConsultantExportsEndpoint } from './services/TaxConsultantExportsEndpoint';
import { UserEndpoint } from './services/UserEndpoint';
import { WebAppEndpoint } from './services/WebAppEndpoint';
import { ZendeskEndpoint } from './services/ZendeskEndpoint';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class BackendClient {

  public readonly company: CompanyEndpoint;
  public readonly creditNote: CreditNoteEndpoint;
  public readonly dunningProcess: DunningProcessEndpoint;
  public readonly financeReport: FinanceReportEndpoint;
  public readonly firebase: FirebaseEndpoint;
  public readonly invoice: InvoiceEndpoint;
  public readonly invoiceNoAuth: InvoiceNoAuthEndpoint;
  public readonly order: OrderEndpoint;
  public readonly payment: PaymentEndpoint;
  public readonly paymentOptions: PaymentOptionsEndpoint;
  public readonly payout: PayoutEndpoint;
  public readonly payoutItems: PayoutItemsEndpoint;
  public readonly performanceOverviewEntry: PerformanceOverviewEntryEndpoint;
  public readonly performanceOverviewReport: PerformanceOverviewReportEndpoint;
  public readonly pricing: PricingEndpoint;
  public readonly stripe: StripeEndpoint;
  public readonly taxConsultantExports: TaxConsultantExportsEndpoint;
  public readonly user: UserEndpoint;
  public readonly webApp: WebAppEndpoint;
  public readonly zendesk: ZendeskEndpoint;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '0.0.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.company = new CompanyEndpoint(this.request);
    this.creditNote = new CreditNoteEndpoint(this.request);
    this.dunningProcess = new DunningProcessEndpoint(this.request);
    this.financeReport = new FinanceReportEndpoint(this.request);
    this.firebase = new FirebaseEndpoint(this.request);
    this.invoice = new InvoiceEndpoint(this.request);
    this.invoiceNoAuth = new InvoiceNoAuthEndpoint(this.request);
    this.order = new OrderEndpoint(this.request);
    this.payment = new PaymentEndpoint(this.request);
    this.paymentOptions = new PaymentOptionsEndpoint(this.request);
    this.payout = new PayoutEndpoint(this.request);
    this.payoutItems = new PayoutItemsEndpoint(this.request);
    this.performanceOverviewEntry = new PerformanceOverviewEntryEndpoint(this.request);
    this.performanceOverviewReport = new PerformanceOverviewReportEndpoint(this.request);
    this.pricing = new PricingEndpoint(this.request);
    this.stripe = new StripeEndpoint(this.request);
    this.taxConsultantExports = new TaxConsultantExportsEndpoint(this.request);
    this.user = new UserEndpoint(this.request);
    this.webApp = new WebAppEndpoint(this.request);
    this.zendesk = new ZendeskEndpoint(this.request);
  }
}

