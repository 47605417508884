import { ReactNode } from "react";
import { Typography } from "@mui/material";
import { autovioColors } from "./backofficeTheme";
import { Row } from "./Row";
import { Column } from "./Column";

export function HeaderField({ label, children }: { label: string; children: ReactNode }) {
  return (
    <Column>
      <Typography color={autovioColors.grey} variant="caption">
        {label}
      </Typography>
      <Row
        sx={{ minHeight: "30px", display: "flex", paddingY: "5px", gap: "5px", alignItems: "flex-start" }}
        alignItems="center"
      >
        {children}
      </Row>
    </Column>
  );
}
