import { GetOneParams, GetOneResult } from "react-admin";
import { AbstractProvider } from "./abstractProvider";
import { instructorsProvider } from "./instructorsProvider";
import { studentsProvider } from "./studentsProvider";
import { getAuthenticatedServerClient, ServerClient } from "../api/server.api";

export interface User {
  id: string;
  name: string;
  avatarUrl?: string;
}

type UserDto = Awaited<ReturnType<ServerClient["getUser"]>>;

class UsersProvider extends AbstractProvider<User> {
  private _getUserPromisesCache = new Map<string, Promise<UserDto>>();

  async getOne(_resource: string, { id }: GetOneParams): Promise<GetOneResult<User>> {
    if (id === "backend") {
      return { data: { id, name: "AUTOVIO" } };
    }
    const cachedUser = instructorsProvider.getOneFromCache(id) || studentsProvider.getOneFromCache(id);
    if (cachedUser) {
      return { data: { id, name: cachedUser.name, avatarUrl: cachedUser.avatarUrl } };
    }
    const cachedPromise = this._getUserPromisesCache.get(id);
    if (cachedPromise) {
      return { data: _userDtoToUser(await cachedPromise) };
    }
    const serverClient = await getAuthenticatedServerClient();
    const getUserPromise = serverClient.getUser({ params: { id } });
    this._getUserPromisesCache.set(id, getUserPromise);
    try {
      return { data: _userDtoToUser(await getUserPromise) };
    } catch (error) {
      this._getUserPromisesCache.delete(id);
      throw error;
    }
  }
}

function _userDtoToUser(userDto: UserDto): User {
  return {
    id: userDto.id,
    name: `${userDto.firstName} ${userDto.lastName}`.trim(),
    avatarUrl: userDto.avatarUrl,
  };
}

export const usersProvider = new UsersProvider();
