import { Box, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import { Link, PaginationPayload, useGetList } from "react-admin";
import { Student } from "../../../providers/studentsProvider";
import { autovioColors, overviewCardStyle } from "../../../misc/backofficeTheme";
import { INVOICE_STATUS_LABEL_AND_COLOR } from "../../../backoffice.utils";
import { InvoiceIcon } from "../../../icons/InvoiceIcon";
import { Spinner } from "../../../misc/Spinner";

type InvoiceStateKey = keyof typeof INVOICE_STATUS_LABEL_AND_COLOR;

export function LatestInvoicesCard({ student }: { student: Student }) {
  const { data } = useGetList("b2cInvoices", {
    filter: { studentId: student.id },
    pagination: { perPage: 5 } as PaginationPayload,
    sort: { field: "name", order: "ASC" },
  });

  return (
    <Box
      sx={{
        ...overviewCardStyle,
        maxHeight: 380,
        padding: "20px 30px",
        position: "relative",
      }}
    >
      <Typography sx={{ fontWeight: "bold", fontSize: 15, mb: "15px" }}>Letzte Rechnungen</Typography>
      {!data && (
        <div className="centered">
          <Spinner style={{ margin: "20px" }} />
        </div>
      )}
      {data &&
        data?.map((invoice) => (
          <Box key={invoice.id} sx={{ display: "flex", alignItems: "center", gap: 2, marginBottom: 2 }}>
            <IconButton sx={{ background: "#f2f2f2" }}>
              <InvoiceIcon />
            </IconButton>
            <Box sx={{ flex: 3 }}>
              <Typography sx={{ fontSize: 14 }}>{invoice.nr}</Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  color: autovioColors.grey,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: 170,
                }}
              >
                {invoice.payload.positions[0]?.description}
              </Typography>
            </Box>
            <Box>
              <Tooltip
                title={INVOICE_STATUS_LABEL_AND_COLOR[invoice.status as InvoiceStateKey]?.label}
                placement="bottom-end"
              >
                <Chip
                  sx={{
                    background: INVOICE_STATUS_LABEL_AND_COLOR[invoice.status as InvoiceStateKey]?.bgColor,
                    color: INVOICE_STATUS_LABEL_AND_COLOR[invoice.status as InvoiceStateKey]?.color,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: 65,
                    height: 20,
                    fontSize: 9,
                    fontWeight: "600",
                  }}
                  label={INVOICE_STATUS_LABEL_AND_COLOR[invoice.status as InvoiceStateKey]?.label?.toUpperCase()}
                />
              </Tooltip>
            </Box>
          </Box>
        ))}
      {data && data.length === 0 && (
        <Typography sx={{ color: autovioColors.grey, marginTop: -1, fontSize: 14 }}>Noch keine Rechnung</Typography>
      )}
      {data && data.length > 0 && (
        <Link to={`/students/${student.id}/invoices`}>
          <Typography sx={{ fontSize: 14 }}>Alle Rechnungen</Typography>
        </Link>
      )}
    </Box>
  );
}
