export function InstructorSearchIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.433 12.375c.727 0 1.317-.604 1.317-1.35V3.6c0-.746-.59-1.35-1.317-1.35H7.492c.23.412.356.877.356 1.35h6.585v7.425H8.506v1.35m2.634-6.75v1.35H7.19v8.775H5.872V11.7H4.555v4.05H3.238v-5.4H2.25V6.975c0-.746.59-1.35 1.317-1.35h7.573M6.53 3.6c0 .746-.59 1.35-1.317 1.35S3.896 4.346 3.896 3.6c0-.746.59-1.35 1.317-1.35.728 0 1.317.604 1.317 1.35z"
        fill="#888"
        fillRule="evenodd"
      />
    </svg>
  );
}
