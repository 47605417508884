import Box from "@mui/material/Box";
import { useGetList, SaveButton, Button, useNotify } from "react-admin";
import { useGetOne } from "react-admin";
import { DateTime } from "luxon";
import { useForm, FormProvider } from "react-hook-form";
import { useQueryClient } from "react-query";
import { AutovioCalendarEvent } from "../../model/autovioCalendarEvents";
import { Instructor } from "../../providers/instructorsProvider";
import { Row } from "../Row";
import { InstructorInput } from "../../inputs/InstructorInput";
import { DateTimeInput } from "../../inputs/DateTimeInput";
import { AppointmentDurationInput } from "../../inputs/AppointmentDurationInput";
import { DrivingLessonTypeInput } from "../../inputs/DrivingLessonTypeInput";
import { DrivingLicenseClassInput } from "../../inputs/DrivingLicenseClassInput";
import { VehiclesInput, OWN_MOTORCYCLE_PSEUDO_ID } from "../../inputs/VehiclesInput";
import { DrivingLicenseClass, isForMotorcycle, isForTrailer } from "../../model/DrivingLicenseClass";
import { Student } from "../../providers/studentsProvider";
import { Column } from "../Column";
import { editDrivingLesson } from "../../api/backoffice.api";

interface EditDrivingLessonFormProps {
  event: AutovioCalendarEvent;
  closeEditMode: () => void;
}

type DrivingLessonType = "normal" | "autobahnfahrt" | "nachtfahrt" | "ueberlandfahrt" | "praktischePruefung";

interface EditDrivingLessonFormValues {
  instructorId: string;
  studentId: string;
  dateTime: DateTime;
  drivingLessonType: DrivingLessonType;
  durationInMinutes: number;
  drivingLicenseClass?: DrivingLicenseClass;
  carId?: string;
  motorcycleId?: string;
  secondMotorcycleOrCarId?: string;
  trailerId?: string;
}

interface EditDrivingLessonParams {
  drivingLessonType: DrivingLessonType;
  instructorId: string;
  bookedTrainingId: string;
  dateTime: DateTime;
  duration: { minutes: number };
  resourceIds: Array<string>;
}

function validate(formValues: EditDrivingLessonFormValues & { student: Student }): EditDrivingLessonParams {
  const {
    instructorId,
    student,
    dateTime,
    durationInMinutes,
    drivingLessonType,
    drivingLicenseClass,
    carId,
    motorcycleId,
    secondMotorcycleOrCarId,
    trailerId,
  } = formValues;
  if (!dateTime) {
    throw new Error(`Invalid dateTime: ${dateTime}`);
  }
  if (!drivingLessonType) {
    throw new Error(`Invalid drivingLessonType: ${drivingLessonType}`);
  }
  if (!student) {
    throw new Error(`Invalid student: ${student}`);
  }

  if (!instructorId) {
    throw new Error(`Invalid instructorId: ${instructorId}`);
  }
  if (!drivingLicenseClass) {
    throw new Error(`Invalid drivingLicenseClass: ${drivingLicenseClass}`);
  }
  let resourceIds: Array<string>;
  if (isForMotorcycle(drivingLicenseClass)) {
    if (!motorcycleId) {
      throw new Error(`Invalid motorcycleId: ${motorcycleId}`);
    }
    const studentUsesOwnMotorcycle = motorcycleId === OWN_MOTORCYCLE_PSEUDO_ID;
    if (drivingLessonType === "praktischePruefung") {
      if (!carId) {
        throw new Error(`Invalid carId: ${carId}`);
      }
      resourceIds = studentUsesOwnMotorcycle ? [carId] : [motorcycleId, carId];
    } else {
      if (!secondMotorcycleOrCarId) {
        throw new Error(`Invalid secondMotorcycleOrCarId: ${secondMotorcycleOrCarId}`);
      }
      if (secondMotorcycleOrCarId === motorcycleId) {
        throw new Error(`secondMotorcycleOrCarId must not be equal to motorcycleId`);
      }
      resourceIds = studentUsesOwnMotorcycle ? [secondMotorcycleOrCarId] : [motorcycleId, secondMotorcycleOrCarId];
    }
  } else if (isForTrailer(drivingLicenseClass)) {
    if (!carId) {
      throw new Error(`Invalid carId: ${carId}`);
    }
    if (!trailerId) {
      throw new Error(`Invalid trailerId: ${motorcycleId}`);
    }
    resourceIds = [carId, trailerId];
  } else {
    if (!carId) {
      throw new Error(`Invalid carId: ${carId}`);
    }
    resourceIds = [carId];
  }
  const bookedTraining = student.bookedTrainings.find((it) => it.drivingLicenseClass === drivingLicenseClass);
  if (!bookedTraining) {
    throw new Error(`Student ${student.id} has no booked training for driving license class ${drivingLicenseClass}`);
  }
  if (!_drivingLessonTypeOptions.map((optionSet) => optionSet[0]).includes(drivingLessonType)) {
    throw new Error(`Invalid drivingLessonType: ${drivingLessonType}`);
  }
  if (!durationInMinutes) {
    throw new Error(`Invalid durationInMinutes: ${durationInMinutes}`);
  }
  return {
    drivingLessonType,
    instructorId,
    bookedTrainingId: bookedTraining.id,
    dateTime,
    duration: { minutes: durationInMinutes },
    resourceIds,
  } satisfies EditDrivingLessonParams;
}

const _drivingLessonTypeOptions: Array<[string, string]> = [
  ["normal", "normale Übungsstunde"],
  ["autobahnfahrt", "Autobahnfahrt"],
  ["nachtfahrt", "Nachtfahrt"],
  ["ueberlandfahrt", "Überlandfahrt"],
  ["praktischePruefung", "Praktische Prüfung"],
];

const EditDrivingLessonForm = ({ event, closeEditMode }: EditDrivingLessonFormProps) => {
  const {
    drivingSchoolId,
    instructorId,
    student: eventStuden,
    start,
    end,
    vehicleId,
    vehicleId2,
    drivingLessonType: eventDrivingLessonType,
  } = event;
  const studentId = eventStuden.uid;

  const { data: instructors, isLoading: loadingInstructors } = useGetList<Instructor>("instructors", {
    filter: { drivingSchoolId: drivingSchoolId },
    pagination: { page: 1, perPage: 100 },
  });

  const instructor = !loadingInstructors ? instructors?.find((item) => item.id === instructorId) ?? null : null;
  const { data: student } = useGetOne<Student>("students", { id: studentId }, { enabled: !!studentId });

  const bookedTraining = student?.bookedTrainings.find(({ id }) => id === eventStuden.bookedTrainingId);

  const getDefaultVehicles = () => {
    if (!bookedTraining) return {};

    if (isForMotorcycle(bookedTraining.drivingLicenseClass)) {
      if (eventDrivingLessonType === "praktischePruefung") {
        if (vehicleId2 === undefined) {
          return { motorcycleId: OWN_MOTORCYCLE_PSEUDO_ID, carId: vehicleId };
        }
        return { motorcycleId: vehicleId, carId: vehicleId2 };
      } else {
        if (vehicleId2 === undefined) {
          return { motorcycleId: OWN_MOTORCYCLE_PSEUDO_ID, secondMotorcycleOrCarId: vehicleId };
        }
        return { motorcycleId: vehicleId, secondMotorcycleOrCarId: vehicleId2 };
      }
    }

    if (isForTrailer(bookedTraining.drivingLicenseClass)) {
      return { carId: vehicleId, trailerId: vehicleId2 };
    }

    return { carId: vehicleId };
  };

  const lessonDuration = end.diff(start, "minutes").minutes;
  const defaultValues = {
    studentId,
    instructorId,
    dateTime: start,
    drivingLessonType: eventDrivingLessonType,
    durationInMinutes: lessonDuration,
    ...(bookedTraining && { drivingLicenseClass: bookedTraining.drivingLicenseClass }),
    ...getDefaultVehicles(),
  } satisfies EditDrivingLessonFormValues;

  const formProps = useForm<EditDrivingLessonFormValues>({
    defaultValues,
  });

  const notify = useNotify();
  const queryClient = useQueryClient();

  if (!student || !instructor) {
    return null;
  }

  const onSubmit = async (formValues: EditDrivingLessonFormValues) => {
    try {
      const { instructorId, dateTime, duration, drivingLessonType, bookedTrainingId, resourceIds } = validate({
        ...formValues,
        student,
      });
      const payload = {
        eventId: event.id,
        instructorId,
        start: dateTime,
        end: dateTime.plus(duration),
        drivingLessonType,
        bookedTrainingId,
        resourceIds,
      };
      await editDrivingLesson(payload);
      notify("Fahrstunde erfolgreich aktualisiert", { type: "success" });
      await queryClient.invalidateQueries(["recommendations", "student"]);
      await queryClient.invalidateQueries(["calendarEvents"]);
      await queryClient.invalidateQueries(["calendarEventHistory"]);
      closeEditMode();
    } catch (error) {
      console.error("Failed to edit driving lesson", error);
      notify("Fehler beim Bearbeiten der Fahrstunde", { type: "error" });
    }
  };

  const { watch } = formProps;
  const drivingLessonType = watch("drivingLessonType") ?? "";
  const drivingLicenseClass = watch("drivingLicenseClass") ?? "";

  return (
    <Box sx={{ p: "24px", minWidth: "450px" }}>
      <FormProvider {...formProps}>
        <form onSubmit={formProps.handleSubmit(onSubmit)}>
          <Column sx={{ gap: "20px" }}>
            <InstructorInput drivingSchoolId={drivingSchoolId} source="instructorId" />
            <DateTimeInput source="dateTime" sx={{ mt: "5px" }} />
            {drivingLessonType !== "praktischePruefung" && <AppointmentDurationInput source="durationInMinutes" />}
            <DrivingLessonTypeInput source="drivingLessonType" options={_drivingLessonTypeOptions} />
            <DrivingLicenseClassInput
              student={student}
              source="drivingLicenseClass"
              label="Ausbildung für Führerscheinklasse"
            />
            <VehiclesInput
              student={student}
              instructor={instructor}
              drivingLessonType={drivingLessonType}
              drivingLicenseClass={drivingLicenseClass}
            />
          </Column>
          <Row sx={{ justifyContent: "space-between", mt: "20px" }}>
            <Button onClick={closeEditMode} variant="outlined" label="Zurück" size="medium" />
            <SaveButton variant="outlined" />
          </Row>
        </form>
      </FormProvider>
    </Box>
  );
};

export default EditDrivingLessonForm;
