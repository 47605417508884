export function SmartphoneIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7,23 C6.45,23 5.97933333,22.8043333 5.588,22.413 C5.196,22.021 5,21.55 5,21 L5,3 C5,2.45 5.196,1.979 5.588,1.587 C5.97933333,1.19566667 6.45,1 7,1 L17,1 C17.55,1 18.021,1.19566667 18.413,1.587 C18.8043333,1.979 19,2.45 19,3 L19,21 C19,21.55 18.8043333,22.021 18.413,22.413 C18.021,22.8043333 17.55,23 17,23 L7,23 Z M7,20 L7,21 L17,21 L17,20 L7,20 Z M7,18 L17,18 L17,6 L7,6 L7,18 Z M7,4 L17,4 L17,3 L7,3 L7,4 Z M7,4 L7,3 L7,4 Z M7,20 L7,21 L7,20 Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
