import { BackofficeListTitle } from "../../misc/BackofficeListTitle";
import { Datagrid, List, NumberField, TextField } from "react-admin";
import { MoneyField } from "../../fields/MoneyField";
import { EmptyState } from "../../misc/EmptyState";
import { MostRecentLessonField } from "../../fields/MostRecentLessonField";
import { Typography } from "@mui/material";
import { ReferenceDrivingSchoolField } from "../../fields/ReferenceDrivingSchoolField";
import { Money } from "../../misc/Money";
import { autovioColors } from "../../misc/backofficeTheme";
import { DateField } from "../../fields/DateField";
import { GlobalDrivingSchoolFilter } from "../../misc/GlobalDrivingSchoolFilter";
import { useBalanceSum } from "../../hooks/useBalanceSum";
import { useRecoilValue } from "recoil";
import { persistentGlobalDrivingSchoolFilterState } from "../../persistent_state";

export function StudentsHandedOverToPairFinance() {
  const listDescription = "Liste aller Fahrschüler, die an PAIR Finance übergeben wurden";
  const sum = useBalanceSum("studentsHandedOverToPairFinance");
  const drivingSchoolId = useRecoilValue(persistentGlobalDrivingSchoolFilterState);
  return (
    <>
      {sum ? (
        <Typography>
          {`${listDescription} • Summe ausstehende Zahlungen: `}
          <Money cents={sum} style={{ color: autovioColors.red }} />
        </Typography>
      ) : (
        <Typography>{listDescription}</Typography>
      )}
      <List
        resource="studentsHandedOverToPairFinance"
        title={<BackofficeListTitle />}
        filters={[<GlobalDrivingSchoolFilter source="drivingSchoolId" alwaysOn />]}
        sort={{ field: "dunningProcess.outstandingAmount", order: "DESC" }}
        actions={false}
        exporter={false}
        empty={false}
      >
        <Datagrid
          rowClick={(studentId) => `/students/${studentId}/invoices`}
          bulkActionButtons={false}
          empty={<EmptyState label="Keine passenden Fahrschüler gefunden." />}
        >
          <TextField label="Name" source="name" />
          {!drivingSchoolId && <ReferenceDrivingSchoolField label="Fahrschule" />}
          <MoneyField label="Saldo" source="balance" redForNegativeValues />
          <TextField label="Status" source="verboseStatus" />
          <NumberField
            label="genommene Fahrstunden"
            source="kpis.finishedDrivingLessonsCount"
            options={{ maximumFractionDigits: 1 }}
          />
          <MostRecentLessonField label="letzte genommene Stunde" />
          <DateField label="Inkasso gestartet" source="dunningProcess.handedOverToPairFinanceAt" showDate="with diff" />
        </Datagrid>
      </List>
    </>
  );
}
