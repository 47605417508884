import { simpleRestProvider } from "./simpleRestProvider";
import {
  CreateParams,
  DataProvider,
  DeleteManyParams,
  DeleteParams,
  fetchUtils,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateManyParams,
  UpdateParams,
} from "react-admin";
import { auth } from "../firebase";
import cloneDeep from "lodash/cloneDeep";
import { applyFilter } from "../backoffice.utils";
import { DateTime } from "luxon";

const hostname = location.hostname;

const resourceBaseUrl =
  hostname === "localhost" || hostname.indexOf("dev") > 0
    ? "https://resource.autovio.dev"
    : `https://resource.autovio.de`;

interface ResourceDto {
  id: string;
  name: string;
  description: string;
  isShared: boolean;
  entitledUsers: Array<string>;
  entitledDrivingSchools: Array<string>;
  deletedAt?: string;
}

interface CarDto extends ResourceDto {
  type: "car";
  car: {
    gearType: "manual" | "automatic" | "electric";
    plateNumber: string;
    advancedDriverAssistanceSystems?: Array<AdasDto>;
  };
  motorcycle: never;
  trailer: never;
}

export interface Car {
  type: "car";
  id: string;
  name: string;
  description: string;
  isShared: boolean;
  car: {
    gearType: "manual" | "automatic" | "electric";
    plateNumber: string;
    advancedDriverAssistanceSystems: Array<AdasDto>;
    advancedDriverAssistanceSystemsIds?: Array<string>;
  };
  motorcycle: never;
  trailer: never;
  deletedAt?: DateTime;
}

interface MotorcycleDto extends ResourceDto {
  type: "motorcycle";
  car: never;
  motorcycle: {
    plateNumber: string;
    advancedDriverAssistanceSystems?: Array<AdasDto>;
  };
  trailer: never;
}

export interface Motorcycle extends Omit<ResourceDto, "deletedAt"> {
  type: "motorcycle";
  car: never;
  motorcycle: {
    plateNumber: string;
    advancedDriverAssistanceSystems: Array<AdasDto>;
    advancedDriverAssistanceSystemsIds?: Array<string>;
  };
  trailer: never;
  deletedAt?: DateTime;
}

interface TrailerDto extends ResourceDto {
  type: "trailer";
  car: never;
  motorcycle: never;
  trailer: {
    plateNumber?: string;
  };
}

export interface Trailer extends Omit<TrailerDto, "deletedAt"> {
  deletedAt?: DateTime;
}

export type Vehicle = Car | Motorcycle | Trailer;

interface AdasDto {
  id: string;
  name: string;
  description: string;
}

export type Adas = TrailerDto;

class ResourceProvider {
  constructor(private resourceType: "vehicle" | "car" | "motorcycle" | "trailer" | "adas") {
    this.resourcePath = resourceType === "adas" ? "adas" : "resource";
  }

  private readonly resourcePath;

  private dtoToRaRecord(_resource: string, dto: any) {
    const record = cloneDeep(dto);
    if (dto.type === "car") {
      const a = (dto as CarDto).car.advancedDriverAssistanceSystems;
      if (a) {
        record.car.advancedDriverAssistanceSystemsIds = a.map((it) => it.id);
      } else {
        record.car.advancedDriverAssistanceSystems = [];
        record.car.advancedDriverAssistanceSystemsIds = [];
      }
    } else if (dto.type === "motorcycle") {
      const a = (dto as MotorcycleDto).motorcycle.advancedDriverAssistanceSystems;
      if (a) {
        record.motorcycle.advancedDriverAssistanceSystemsIds = a.map((it) => it.id);
      } else {
        record.motorcycle.advancedDriverAssistanceSystems = [];
        record.motorcycle.advancedDriverAssistanceSystemsIds = [];
      }
    }
    if (dto.deletedAt) {
      record.deletedAt = DateTime.fromISO(dto.deletedAt);
    }
    return record;
  }

  private raRecordToDto(_resource: string, record: any) {
    const dto = cloneDeep(record);
    if (!dto.description) {
      dto.description = "";
    }
    if (record.type === "car") {
      const adasIds = (record as Car).car.advancedDriverAssistanceSystemsIds;
      delete dto.car.advancedDriverAssistanceSystemsIds;
      if (adasIds && adasIds.length > 0) {
        dto.car.advancedDriverAssistanceSystems = adasIds.map((id) => ({ id }));
      } else {
        delete dto.car.advancedDriverAssistanceSystems;
      }
    } else if (record.type === "motorcycle") {
      const adasIds = (record as Motorcycle).motorcycle.advancedDriverAssistanceSystemsIds;
      delete dto.motorcycle.advancedDriverAssistanceSystemsIds;
      if (adasIds && adasIds.length > 0) {
        dto.motorcycle.advancedDriverAssistanceSystems = adasIds.map((id) => ({ id }));
      } else {
        delete dto.motorcycle.advancedDriverAssistanceSystems;
      }
    }
    return dto;
  }

  private restProvider = simpleRestProvider(resourceBaseUrl, async (url, options) => {
    const jwt = await auth.currentUser?.getIdToken();
    if (!options) {
      options = { headers: new Headers({ Accept: "application/json" }) };
    } else if (!options.headers) {
      options.headers = new Headers({ Accept: "application/json" });
    }
    (options?.headers as Headers).set("Authorization", `Bearer ${jwt}`);
    return fetchUtils.fetchJson(url, options);
  });

  async getList(resource: string, params: GetListParams) {
    const { q, ...filter } = params.filter || {};
    if (this.resourceType !== "vehicle") {
      filter.type = this.resourceType;
    }
    const { data } = (await this.restProvider.getList(this.resourcePath, params)) as any;
    const records = applyFilter(
      data.map((dto: any) => this.dtoToRaRecord(resource, dto)),
      { q },
    );
    return { data: records, total: records.length };
  }

  async getOne(resource: string, { id }: GetOneParams) {
    const result = (await this.restProvider.getOne(this.resourcePath, { id })) as any;
    result.data = this.dtoToRaRecord(resource, result.data);
    return result;
  }

  async getMany(resource: string, { ids }: GetManyParams) {
    const result = (await this.restProvider.getMany(this.resourcePath, { ids })) as any;
    result.data = result.data.map((dto: any) => this.dtoToRaRecord(resource, dto));
    return result;
  }

  async getManyReference(resource: string, params: GetManyReferenceParams) {
    if (this.resourceType !== "vehicle") {
      if (params.filter) {
        params.filter.type = this.resourceType;
      } else {
        params.filter = { type: this.resourceType };
      }
    }
    const result = (await this.restProvider.getManyReference(this.resourcePath, params)) as any;
    result.data = result.data.map((dto: any) => this.dtoToRaRecord(resource, dto));
    return result;
  }

  async create(resource: string, { data }: CreateParams) {
    return this.restProvider.create(this.resourcePath, {
      data: this.raRecordToDto(resource, data),
    });
  }

  async update(resource: string, { id, data }: UpdateParams) {
    return this.restProvider.update(this.resourcePath, {
      id,
      data: this.raRecordToDto(resource, data),
    } as any);
  }

  async updateMany(resource: string, { ids, data }: UpdateManyParams) {
    return this.restProvider.updateMany(this.resourcePath, {
      ids,
      data: this.raRecordToDto(resource, data),
    } as any);
  }

  async delete(resource: string, { id }: DeleteParams) {
    return this.restProvider.delete(this.resourcePath, { id });
  }

  async deleteMany(resource: string, { ids }: DeleteManyParams) {
    return this.restProvider.deleteMany(this.resourcePath, { ids });
  }
}

export async function recoverResource({ id }: DeleteParams) {
  const recoverURL = `${resourceBaseUrl}/resource/${id}`;
  const jwt = await auth.currentUser?.getIdToken();
  return fetch(recoverURL, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
}

export const vehiclesProvider = new ResourceProvider("vehicle") as DataProvider;
export const carsProvider = new ResourceProvider("car") as DataProvider;
export const motorcyclesProvider = new ResourceProvider("motorcycle") as DataProvider;
export const trailersProvider = new ResourceProvider("trailer") as DataProvider;
export const adasProvider = new ResourceProvider("adas") as DataProvider;
