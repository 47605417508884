import { Box, LinearProgress, Typography } from "@mui/material";
import { useListContext, useRecordContext } from "react-admin";
import { PerformanceOverview, type PerformanceOverviewResources } from "../../PerformanceOverview.js";
import { grants } from "../../backoffice.access_control";
import { Column } from "../../misc/Column.js";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import { Money } from "../../misc/Money";
import { Row } from "../../misc/Row.js";
import { autovioColors } from "../../misc/backofficeTheme";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";
import { PerformanceOverviewStats } from "../../providers/performanceOverviewProvider";
import { PageTitle } from "../../misc/PageTitle";
import { AccessDenied } from "../../misc/AccessDenied";

export function DrivingSchoolPerformanceOverview({
  title,
  resource,
  className,
}: {
  title: string;
  resource: PerformanceOverviewResources;
  className?: string;
}) {
  const drivingSchool = useRecordContext<DrivingSchool>();

  if (!grants.includes("viewPerformanceOverview")) {
    return (
      <>
        <PageTitle>{title}</PageTitle>
        <AccessDenied />
      </>
    );
  }

  if (!drivingSchool) {
    return (
      <>
        <PageTitle>{title}</PageTitle>
        <LoadingIndicator />
      </>
    );
  }

  return (
    <>
      <PageTitle>{title}</PageTitle>
      <PerformanceOverview
        storeKey={`DrivingSchoolPerformanceOverview_${resource}_v1`}
        resource={resource}
        className={className}
        context={drivingSchool}
        listFilter={{ drivingSchoolId: drivingSchool.id }}
        overviewBox={resource === "performanceOverview" ? <_OverviewBox /> : undefined}
      />
    </>
  );
}

function _OverviewBox() {
  const { hasNextPage } = useListContext();
  // Hack: hasNextPage is not a boolean, but a PerformanceOverviewStats object -- see performanceOverviewProvider.ts
  const stats = hasNextPage as any as PerformanceOverviewStats | undefined;

  return (
    <Box
      sx={{
        m: "16px",
        borderRadius: "8px",
        p: "20px",
        bgcolor: autovioColors.greenUltraLight,
      }}
    >
      <Row justifyContent={"space-around"}>
        <Column>
          <Box>
            {stats?.turnoverGross ? (
              <Money value={stats.turnoverGross} style={{ fontWeight: "bold" }} />
            ) : (
              <LinearProgress
                style={{ margin: "8px 0", width: "3em", display: "inline-block", verticalAlign: "bottom" }}
              />
            )}
            <Typography>Umsatz (brutto)</Typography>
          </Box>
        </Column>
        <Column>
          <Box>
            {stats?.turnoverNet ? (
              <Money value={stats.turnoverNet} style={{ fontWeight: "bold" }} />
            ) : (
              <LinearProgress
                style={{ margin: "8px 0", width: "3em", display: "inline-block", verticalAlign: "bottom" }}
              />
            )}
            <Typography>Umsatz (netto)</Typography>
          </Box>
        </Column>
        <Column>
          <Box>
            {stats?.partnerTurnoverGross ? (
              <Money value={stats.partnerTurnoverGross} style={{ fontWeight: "bold" }} />
            ) : (
              <LinearProgress
                style={{ margin: "8px 0", width: "3em", display: "inline-block", verticalAlign: "bottom" }}
              />
            )}
            <Typography>Partner-Umsatzanteil (brutto)</Typography>
          </Box>
        </Column>
        <Column>
          <Box>
            {stats?.partnerTurnoverNet ? (
              <Money value={stats.partnerTurnoverNet} style={{ fontWeight: "bold" }} />
            ) : (
              <LinearProgress
                style={{ margin: "8px 0", width: "3em", display: "inline-block", verticalAlign: "bottom" }}
              />
            )}
            <Typography>Partner-Umsatzanteil (netto)</Typography>
          </Box>
        </Column>
      </Row>
    </Box>
  );
}
