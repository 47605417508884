import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useState } from "react";
import { CheckboxGroupInput, EditBase, Form, SaveButton, useGetList, useNotify, useRecordContext } from "react-admin";
import { reportError } from "../backoffice.utils";
import { useDialog } from "../hooks/useDialog";
import { Instructor } from "../providers/instructorsProvider";
import { Student, studentsProvider } from "../providers/studentsProvider";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import EditIcon from "@mui/icons-material/EditOutlined";
import { autovioColors } from "../misc/backofficeTheme";
import { useQueryClient } from "react-query";
import { FieldValues } from "react-hook-form";

export function ChangeStudentInstructorButton() {
  const { dialogProps, openDialog } = useDialog("ChangeStudentInstructorDialog");
  return (
    <>
      <IconButton
        sx={{ background: "transparent", color: autovioColors.green, width: 20, height: 20 }}
        onClick={openDialog}
      >
        <EditIcon style={{ fontSize: 20 }} />
      </IconButton>
      <ChangeStudentInstructorDialog {...dialogProps} />
    </>
  );
}

function ChangeStudentInstructorDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const student = useRecordContext<Student>();
  const [saving, setSaving] = useState(false);
  const { data: instructors } = useGetList<Instructor>("instructors", {
    filter: { drivingSchoolId: student.drivingSchoolId },
    pagination: { page: 1, perPage: 1000 },
  });
  const notify = useNotify();
  const queryClient = useQueryClient();

  const validateSelection = (data: FieldValues) => {
    const { instructorIds } = data as { instructorIds: Array<string> };
    const errors: { [field: string]: any } = {};
    if (instructorIds.length === 0) {
      errors.instructorIds = "Bitte mindestens einen Fahrleher auswählen.";
    }
    return errors;
  };

  const onSubmit = async (data: FieldValues) => {
    if (!student) {
      throw new Error("Invalid state: !student");
    }
    const { instructorIds } = data as { instructorIds: Array<string> };
    setSaving(true);
    try {
      await studentsProvider.assignInstructors(student, instructorIds);
      await queryClient.invalidateQueries(["students", "getOne", { id: student.id }]);
      notify(`Fahrlehrer von ${student.name} erfolgreich geändert.`, { type: "success" });
    } catch (error) {
      reportError(`Failed to change instructors of student ${student.id}`, error);
      const errorMessage =
        instructorIds.length === 1
          ? student
            ? `Fehler beim Ändern des Fahrlehrers von ${student.name}.`
            : `Fehler beim Ändern des Fahrlehrers.`
          : student
          ? `Fehler beim Ändern der Fahrlehrer von ${student.name}.`
          : `Fehler beim Ändern der Fahrlehrer.`;
      notify(errorMessage, { type: "error" });
    } finally {
      setSaving(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Fahrlehrer von {student.name} ändern</DialogTitle>
      <DialogCloseButton onClose={onClose} />
      <EditBase>
        <Form onSubmit={onSubmit} validate={validateSelection} warnWhenUnsavedChanges>
          <DialogContent>
            <CheckboxGroupInput
              source="instructorIds"
              row={false}
              disabled={saving}
              choices={(instructors ?? [])
                .map((instructor) => ({
                  id: instructor.id,
                  name: instructor.name,
                }))
                .sort((a, b) => a.name.localeCompare(b.name))}
            />
          </DialogContent>
          <DialogActions>
            <SaveButton />
          </DialogActions>
        </Form>
      </EditBase>
    </Dialog>
  );
}
