/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyApplicationFeePercentage } from '../models/CompanyApplicationFeePercentage';
import type { PaginatedCompanyApplicationFeePercentageList } from '../models/PaginatedCompanyApplicationFeePercentageList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PricingEndpoint {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns PaginatedCompanyApplicationFeePercentageList
   * @throws ApiError
   */
  public pricingApplicationFeesList({
    companyDrivingSchoolId,
    limit,
    offset,
    ordering,
  }: {
    companyDrivingSchoolId?: string,
    /**
     * Number of results to return per page.
     */
    limit?: number,
    /**
     * The initial index from which to return the results.
     */
    offset?: number,
    /**
     * Which field to use when ordering the results.
     */
    ordering?: string,
  }): CancelablePromise<PaginatedCompanyApplicationFeePercentageList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/pricing/application-fees/',
      query: {
        'company__driving_school_id': companyDrivingSchoolId,
        'limit': limit,
        'offset': offset,
        'ordering': ordering,
      },
    });
  }

  /**
   * @returns CompanyApplicationFeePercentage
   * @throws ApiError
   */
  public pricingApplicationFeesCreate({
    requestBody,
  }: {
    requestBody: CompanyApplicationFeePercentage,
  }): CancelablePromise<CompanyApplicationFeePercentage> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/pricing/application-fees/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns CompanyApplicationFeePercentage
   * @throws ApiError
   */
  public pricingApplicationFeesRetrieve({
    id,
  }: {
    /**
     * A UUID string identifying this company application fee percentage.
     */
    id: string,
  }): CancelablePromise<CompanyApplicationFeePercentage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/pricing/application-fees/{id}/',
      path: {
        'id': id,
      },
    });
  }

}
