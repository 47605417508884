import { Student } from "../../providers/studentsProvider";
import { LatestActivitiesCard } from "./cards/LatestActivitiesCard";
import { LatestInvoicesCard } from "./cards/LatestInvoicesCard";
import { TrainingsCard } from "./cards/TrainingsCard";
import { StudentTab } from "./StudentTab";

export function StudentOverviewTab({ student }: { student: Student }) {
  return (
    <StudentTab>
      <TrainingsCard student={student} />
      <>
        <LatestActivitiesCard student={student} />
        <div style={{ height: "30px" }} />
        <LatestInvoicesCard student={student} />
      </>
    </StudentTab>
  );
}
