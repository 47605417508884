import { doc, getDocFromServer, onSnapshot, Unsubscribe } from "firebase/firestore";
import {
  CreateParams,
  CreateResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import type { z } from "zod";
import { getAuthenticatedServerClient, schemas } from "../api/server.api";
import { firestore } from "../firebase";
import { ThreadSchema, type Post, type Thread } from "../model/Thread.js";
import { AbstractProvider } from "./abstractProvider";

type CreatePostDto = z.infer<typeof schemas.CreatePostDto>;
type UpdatePostDto = z.infer<typeof schemas.UpdatePostDto>;

class ThreadsProvider extends AbstractProvider<Thread | Post> {
  private _snapshotPromiseCache: Map<Identifier, Promise<Thread> | undefined> = new Map();
  private _unsubscribe: Unsubscribe | undefined;
  private readonly _onUpdateListeners: Array<() => void> = [];

  constructor() {
    super();
  }

  async getOne(_: string, { id }: GetOneParams<Thread>): Promise<GetOneResult<Thread>> {
    return { data: await this.fetchIfNeeded(id) };
  }

  /**
   * SPECIAL: This is actually updating a Post in a Thread. Hence the return type is a Post.
   */
  async update(_: string, { data }: UpdateParams<Post>): Promise<UpdateResult<Post>> {
    const { id: postId, threadId, body } = data;
    if (!postId || !threadId) {
      throw new Error("Property postId and threadId must be provided.");
    }
    let unsubscribe: () => void = () => {
      throw new Error("unsubscribe has not been set");
    };
    let postInThread: Post | undefined;
    const threadUpdated = new Promise<void>((resolve, reject) => {
      unsubscribe = this.onUpdate(async () => {
        try {
          const threads = await this.fetch(threadId);
          const posts = threads.posts ?? [];
          postInThread = Object.values(posts).find((it) => it.id === postId && it.body === body);
          if (postInThread) {
            resolve();
          }
        } catch (error) {
          reject(error);
        }
      });
    });
    await this.updatePost({ id: postId, body });
    await threadUpdated;
    unsubscribe();
    if (!postInThread) {
      throw new Error(`Could not find post ${JSON.stringify(postId)} in thread ${threadId}`);
    }
    const updatedThread = await this.fetch(threadId);
    return { data: updatedThread.posts[postId] };
  }

  /**
   * SPECIAL: This is actually creating a Post in a Thread. Hence the return type is a Thread.
   */
  async create(_: string, { data }: CreateParams<CreatePostDto>): Promise<CreateResult<Thread>> {
    const { body, threadId } = data;
    if (!threadId) throw new Error("Property threadId must be provided.");
    if (typeof body !== "string") throw new Error("Property body must be provided.");
    const thread = await this.fetchIfNeeded(threadId);
    const knownPostIds = new Set(thread.sortedPosts.map((post) => post.id));
    let unsubscribe: () => void = () => {
      throw new Error("unsubscribe has not been set");
    };
    let post: Post | undefined;
    const postCreated = new Promise<void>((resolve, reject) => {
      unsubscribe = this.onUpdate(async () => {
        try {
          const thread = await this.fetch(threadId);
          const posts = thread.posts;
          post = Object.values(posts).find((it) => it.body === body && it);
          if (post && post.body === body && !knownPostIds.has(post.id)) {
            resolve();
          }
        } catch (error) {
          reject(error);
        }
      });
    });
    await this.createPost({ threadId, body });
    await postCreated;
    unsubscribe();
    const updatedThread = await this.fetch(threadId);
    return { data: updatedThread };
  }

  fetchIfNeeded(recordId: Identifier): Promise<Thread> {
    if (this._snapshotPromiseCache.has(recordId)) {
      return this._snapshotPromiseCache.get(recordId)!;
    }
    return this.fetch(recordId);
  }

  fetch(threadId: Identifier): Promise<Thread> {
    const document = doc(firestore, `threads/${threadId}`);
    this._unsubscribe?.();
    this._unsubscribe = undefined;
    const fetchThreadPromise = getDocFromServer(document).then((snapshot) => {
      snapshot.data();
      this._unsubscribe = onSnapshot(document, async (snapshot) => {
        const updatedThread = ThreadSchema.parse(snapshot.data());
        this._snapshotPromiseCache.set(threadId, Promise.resolve(updatedThread));
        console.info(`Retrieved new snapshot on thread ${updatedThread.id} with ${updatedThread.posts} post(s)`);
        for (const listener of this._onUpdateListeners) {
          try {
            listener();
          } catch (error) {
            console.error("listener failed", error);
          }
        }
      });
      return ThreadSchema.parse(snapshot.data());
    });
    this._snapshotPromiseCache.set(threadId, fetchThreadPromise);
    return fetchThreadPromise;
  }

  onUpdate(listener: () => any): () => void {
    if (this._onUpdateListeners.indexOf(listener) >= 0) {
      throw new Error("listener already registered");
    }
    this._onUpdateListeners.push(listener);
    return () => {
      const i = this._onUpdateListeners.indexOf(listener);
      if (i < 0) {
        throw new Error("listener already unregistered");
      }
      this._onUpdateListeners.splice(i, 1);
    };
  }

  private async updatePost(data: UpdatePostDto) {
    const client = await getAuthenticatedServerClient();
    return await client.updatePost(data);
  }

  private async createPost(data: CreatePostDto) {
    const client = await getAuthenticatedServerClient();
    return await client.createPost(data);
  }
}

export const threadsProvider = new ThreadsProvider();
