import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { Functions, getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { Firestore, getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { FirebaseStorage, getStorage } from "firebase/storage";
import { Auth, getAuth } from "firebase/auth";

export let firebaseApp: FirebaseApp;
export let firestore: Firestore;
export let functions: Functions;
export let storage: FirebaseStorage;
export let auth: Auth;

const FIREBASE_FUNCTIONS_EMULATOR = !!parseInt(import.meta.env.VITE_USE_FIREBASE_FUNCTIONS_EMULATOR);
const FIREBASE_STORAGE_EMULATOR = !!parseInt(import.meta.env.VITE_USE_FIREBASE_STORAGE_EMULATOR);
const FORCE_FIREBASE_PRODUCTION_ENV = !!parseInt(import.meta.env.VITE_FORCE_FIREBASE_PRODUCTION_ENV);

export async function initializeFirebase(): Promise<void> {
  if (firebaseApp) {
    return;
  }

  const { hostname } = location;
  if (hostname === "127.0.0.1") {
    location.replace(location.href.replace("127.0.0.1", "localhost"));
    return;
  }

  const firebaseConfig: FirebaseOptions =
    hostname === "localhost"
      ? FORCE_FIREBASE_PRODUCTION_ENV
        ? {
            apiKey: "AIzaSyDGeMeOi5ozEy2sGlmYXUYhQLShN0-LlyQ",
            authDomain: "backoffice.autovio.de",
            databaseURL: "",
            messagingSenderId: "869539799414",
            projectId: "autovio-prod",
            storageBucket: "autovio-prod.appspot.com",
          }
        : {
            apiKey: "AIzaSyDWiFnsFBLSenDjj2u5c3kKmZt733GvWk8",
            appId: "1:755520140538:web:a4a6e80c6682c67d0cc543",
            authDomain: "backoffice.autovio.dev",
            databaseURL: "",
            measurementId: "G-TST65J2RKX",
            messagingSenderId: "755520140538",
            projectId: "autovio-dev",
            storageBucket: "autovio-dev.appspot.com",
          }
      : await fetch("/__/firebase/init.json").then((response) => response.json());

  if (hostname === "backoffice.autovio.dev" || hostname === "backoffice.autovio.de") {
    // See https://firebase.google.com/docs/auth/web/redirect-best-practices#update-authdomain ...
    firebaseConfig.authDomain = hostname;
  }

  firebaseApp = initializeApp(firebaseConfig);
  firestore = getFirestore(firebaseApp);
  functions = getFunctions(firebaseApp, "europe-west3");
  storage = getStorage(firebaseApp);
  auth = getAuth(firebaseApp);

  if (FIREBASE_STORAGE_EMULATOR) {
    connectFirestoreEmulator(firestore, "localhost", 8080);
  }
  if (FIREBASE_FUNCTIONS_EMULATOR) {
    console.info("Using Firebase Functions emulator");
    connectFunctionsEmulator(functions, "localhost", 5001);
  }

  // Ease development and debugging ...
  Object.assign(window as any, { auth, firestore, functions, storage });
}

export function openInFirestoreConsole(path: string) {
  const { projectId } = firebaseApp.options;
  const url = `https://console.firebase.google.com/project/${projectId}/firestore/data/${path.replaceAll("/", "~2F")}`;
  window.open(url, "_blank");
}
