import { collection, doc, getDocsFromServer, onSnapshot, query, type Unsubscribe, where } from "firebase/firestore";
import capitalize from "lodash/capitalize";
import { CreateParams, CreateResult, GetManyReferenceParams, GetManyReferenceResult, Identifier } from "react-admin";
import { createNote } from "../api/backoffice.api";
import { getAuthenticatedServerClient } from "../api/server.api.js";
import { authProvider } from "../backoffice.access_control";
import { applyFilter, applyPagination, applySort, escapeHtml, reportError } from "../backoffice.utils";
import { firestore } from "../firebase";
import { AttachmentSchema } from "../model/Attachment";
import { Document } from "../model/Document";
import { SignatureRequestSchema } from "../model/SignatureRequest";
import { t } from "../model/types";
import { LocalizedError } from "../utils/LocalizedError";
import { gcs } from "../utils/storage";
import { AbstractProvider } from "./abstractProvider";
import { drivingSchoolNotesProvider, studentNotesProvider } from "./notesProvider";
import { studentsProvider } from "./studentsProvider";
import { threadsProvider } from "./threadsProvider.js";

abstract class AbstractDocumentsProvider extends AbstractProvider<Document> {
  private _snapshotPromiseRecordId: Identifier | undefined;
  private _snapshotPromise: Promise<Array<Document>> | undefined;

  constructor(private readonly resource: DocumentsSource) {
    super();
  }

  async getManyReference(
    _: string,
    { target, id, filter, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<Document>> {
    if (this.resource === "studentDocuments" && target !== "studentUid") {
      throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "studentUid"`);
    }
    const documents = await this.fetchIfNeeded(id);
    return applyPagination(applySort(applyFilter(documents, filter), sort), pagination);
  }

  async create(
    _: string,
    {
      data,
    }: CreateParams<{
      recordId: string;
      files: Array<{ rawFile: File }>;
    }>,
  ): Promise<CreateResult<Document>> {
    const { recordId, files } = data;
    if (!recordId) {
      if (this.resource === "studentDocuments") {
        throw new LocalizedError(`Kein Fahrschüler ausgewählt.`);
      }
      if (this.resource === "threadDocuments") {
        throw new LocalizedError(`Kein Thread ausgewählt.`);
      }
      throw new LocalizedError(`Keine Fahrschule ausgewählt.`);
    }
    if (!files || files.length === 0) {
      throw new LocalizedError("Keine Datei ausgewählt.");
    }
    const emptyFile = files.find((it) => it.rawFile.size === 0);
    if (emptyFile) {
      throw new LocalizedError(`Die Datei ${emptyFile.rawFile.name} ist leer.`);
    }
    const knownIds = new Set((await this.fetchIfNeeded(recordId)).map((document) => document.id));
    // Upload files to Google Cloud Storage ...
    const attachments = await gcs.uploadFiles(files);
    // Create note ...
    const { fullName } = (await authProvider.getIdentity?.()) ?? {};
    let noteBody: string;
    if (attachments.length === 1) {
      if (fullName) {
        noteBody = `${escapeHtml(fullName)} hat das Dokument ${escapeHtml(attachments[0].name)} hinzugefügt.`;
      } else {
        noteBody = `Das Dokument ${escapeHtml(attachments[0].name)} wurde hinzugefügt.`;
      }
    } else {
      if (fullName) {
        noteBody = `${escapeHtml(fullName)} hat folgende Dokumente hinzugefügt:<ul>`;
      } else {
        noteBody = "Folgende Dokumente wurden hinzugefügt:";
      }
      noteBody += "<ul>" + attachments.map((it) => `<li>${escapeHtml(it.name)}</li>`).join("") + "</ul>";
    }
    if (this.resource === "studentDocuments") {
      await createNote({ studentUid: recordId, body: noteBody, attachments });
      await studentNotesProvider.fetch(recordId);
    } else if (this.resource === "drivingSchoolDocuments") {
      const client = await getAuthenticatedServerClient();
      const newNote = await client.createNote({
        type: "DRIVING_SCHOOL",
        drivingSchoolId: recordId,
        body: noteBody,
        attachments,
      });
      if (!newNote.attachments || newNote.attachments.length === 0) {
        throw new Error("No attachments returned from server.");
      }
      const attachment = newNote.attachments[0];
      let document: Document | undefined;
      let unsubscribe: Unsubscribe | undefined;
      const documentUpdated = new Promise<void>((resolve, reject) => {
        unsubscribe = onSnapshot(collection(firestore, `driving_schools/${recordId}/notes`), async () => {
          try {
            const documents = await this.fetch(recordId);
            document = documents.find((it) => it.id === attachment.id);
            if (document) {
              resolve();
            }
          } catch (error) {
            reject(error);
          }
        });
      });
      await documentUpdated;
      unsubscribe?.();
    } else if (this.resource === "threadDocuments") {
      const client = await getAuthenticatedServerClient();
      const newPost = await client.createPost({
        threadId: recordId,
        body: noteBody,
        attachments,
      });
      if (!newPost.attachments || newPost.attachments.length === 0) {
        throw new Error("No attachments returned from server.");
      }
      const attachment = newPost.attachments[0];
      let document: Document | undefined;
      let unsubscribe: Unsubscribe | undefined;
      const documentUpdated = new Promise<void>((resolve, reject) => {
        unsubscribe = onSnapshot(doc(firestore, `threads/${recordId}`), async () => {
          try {
            const documents = await this.fetch(recordId);
            document = documents.find((it) => it.id === attachment.id);
            if (document) {
              resolve();
            }
          } catch (error) {
            reject(error);
          }
        });
      });
      await documentUpdated;
      unsubscribe?.();
    } else {
      throw new Error(`Unexpected resource: ${this.resource}`);
    }

    const documents = await this.fetch(recordId);
    const newDocument = documents.find((it) => !knownIds.has(it.id) && it.fileName === attachments[0].name)!;
    return { data: newDocument };
  }

  fetchIfNeeded(recordId: Identifier): Promise<Array<Document>> {
    if (this._snapshotPromiseRecordId === recordId) {
      return this._snapshotPromise!;
    }
    return this.fetch(recordId);
  }

  fetch(recordId: Identifier): Promise<Array<Document>> {
    this._snapshotPromiseRecordId = recordId;
    this._snapshotPromise = this._fetch(recordId);
    return this._snapshotPromise;
  }

  private async _fetch(recordId: Identifier): Promise<Array<Document>> {
    const documents = (
      await Promise.all([
        this.retrieveAttachments(recordId),
        this.retrieveDocuments(recordId),
        this.retrieveSignedDocuments(recordId),
      ])
    ).flat();
    console.info(`Retrieved ${documents.length} document(s) for ${this.resourceName} ${recordId}`);
    return documents;
  }

  private get resourceName() {
    switch (this.resource) {
      case "drivingSchoolDocuments":
        return "DrivingSchool";
      case "studentDocuments":
        return "Student";
      case "threadDocuments":
        return "Thread";
    }
  }

  private async retrieveAttachments(recordId: Identifier): Promise<Array<Document>> {
    if (this.resource === "drivingSchoolDocuments") {
      const notes = await drivingSchoolNotesProvider.fetchIfNeeded(recordId);
      const attachments = notes.flatMap((note) => {
        const attachments = note.attachments ?? [];
        return attachments.map((attachment) => {
          return {
            id: attachment.id,
            path: attachment.path,
            fileName: attachment.name,
            contentType: attachment.mimeType,
            createdAt: attachment.createdAt,
            getDownloadUrl: () => gcs.getDownloadUrl(attachment.path),
          };
        });
      });
      return attachments;
    } else if (this.resource === "threadDocuments") {
      const thread = await threadsProvider.fetchIfNeeded(recordId);
      const attachments = thread.sortedPosts.flatMap((post) => {
        const attachments = post.attachments ?? [];
        return attachments.map((attachment) => {
          return {
            id: attachment.id,
            path: attachment.path,
            fileName: attachment.name,
            contentType: attachment.mimeType,
            createdAt: attachment.createdAt,
            getDownloadUrl: () => gcs.getDownloadUrl(attachment.path),
          };
        });
      });
      return attachments;
    } else if (this.resource === "studentDocuments") {
      const path =
        this.resource === "studentDocuments"
          ? `/users/${recordId}/attachments`
          : `/driving_schools/${recordId}/attachments`;
      try {
        const snapshot = await getDocsFromServer(collection(firestore, path));
        const attachments = snapshot.docs.map((doc) => AttachmentSchema.parse(doc.data()));
        return attachments.map((attachment) => ({
          id: attachment.path,
          fileName: attachment.name,
          contentType: attachment.mimeType,
          createdAt: attachment.lastChanged,
          getDownloadUrl: () => gcs.getDownloadUrl(attachment.path),
        }));
      } catch (error) {
        reportError(`retrieveAttachments("${recordId}") failed`, error);
        return [];
      }
    }
    throw new Error(`Unexpected resource: ${this.resource}`);
  }

  private async retrieveDocuments(recordId: Identifier): Promise<Array<Document>> {
    if (this.resource === "drivingSchoolDocuments" || this.resource === "threadDocuments") {
      return [];
    }
    try {
      const { data: student } = await studentsProvider.getOne("students", { id: recordId });
      const files = await gcs.listFiles(`/backend/documents/${student.autovioUserId}/${student.drivingSchoolId}`);
      return files
        .filter((it) => it.name.endsWith(".pdf"))
        .map((file) => ({
          id: file.fullPath,
          fileName: file.name,
          contentType: "application/pdf",
          createdAt: t.dateTime().parse(file.timeCreated),
          getDownloadUrl: () => gcs.getDownloadUrl(file),
        }));
    } catch (error) {
      reportError(`retrieveDocuments("${recordId}") failed`, error);
      return [];
    }
  }

  private async retrieveSignedDocuments(studentUid: Identifier): Promise<Array<Document>> {
    if (this.resource === "drivingSchoolDocuments" || this.resource === "threadDocuments") {
      return [];
    }
    try {
      const snapshot = await getDocsFromServer(
        query(collection(firestore, "/signature_requests"), where("studentUid", "==", studentUid)),
      );
      const signatureRequest = snapshot.docs.map((doc) => SignatureRequestSchema.parse({ uid: doc.id, ...doc.data() }));
      const completedSignatureRequests = signatureRequest.filter((it) => it.state === "completed");
      return completedSignatureRequests.map((it) => ({
        id: it.signedDocumentStorageRef!,
        fileName: `${capitalize(it.type)}.pdf`,
        contentType: "application/pdf",
        createdAt: it.studentSignedAt!,
        getDownloadUrl: () => gcs.getDownloadUrl(it.signedDocumentStorageRef!),
      }));
    } catch (error) {
      reportError(`retrieveSignedDocuments("${studentUid}") failed`, error);
      return [];
    }
  }
}

class StudentDocumentsProvider extends AbstractDocumentsProvider {}
class DrivingSchoolDocumentsProvider extends AbstractDocumentsProvider {}
class ThreadDocumentsProvider extends AbstractDocumentsProvider {}

export const studentDocumentsProvider = new StudentDocumentsProvider("studentDocuments");
export const drivingSchoolDocumentsProvider = new DrivingSchoolDocumentsProvider("drivingSchoolDocuments");
export const threadDocumentsProvider = new ThreadDocumentsProvider("threadDocuments");
export type DocumentsSource = "studentDocuments" | "drivingSchoolDocuments" | "threadDocuments";
