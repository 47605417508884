import { DashboardCard } from "../../../misc/DashboardCard";
import { DrivingSchoolMap } from "../../../misc/maps";

export function MapCard() {
  return (
    <DashboardCard title="Umkreise & Orte">
      <DrivingSchoolMap />
    </DashboardCard>
  );
}
