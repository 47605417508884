import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";

export class AbstractProvider<T extends RaRecord> {
  async getList(_resource: string, _params: GetListParams): Promise<GetListResult<T>> {
    throw new Error("Not implemented");
  }

  async getOne(_resource: string, _params: GetOneParams<T>): Promise<GetOneResult<T>> {
    throw new Error("Not implemented");
  }

  async getMany(_resource: string, _params: GetManyParams): Promise<GetManyResult<T>> {
    throw new Error("Not implemented");
  }

  async getManyReference(_resource: string, _params: GetManyReferenceParams): Promise<GetManyReferenceResult<T>> {
    throw new Error("Not implemented");
  }

  async create(_resource: string, _params: CreateParams<any>): Promise<CreateResult<T>> {
    throw new Error("Not implemented");
  }

  async update(_resource: string, _params: UpdateParams<T>): Promise<UpdateResult<T>> {
    throw new Error("Not implemented");
  }

  async updateMany(_resource: string, _params: UpdateManyParams<any>): Promise<UpdateManyResult> {
    throw new Error("Not implemented");
  }

  async delete(_resource: string, _params: DeleteParams): Promise<DeleteResult<T>> {
    throw new Error("Not implemented");
  }

  async deleteMany(_resource: string, _params: DeleteManyParams): Promise<DeleteManyResult> {
    throw new Error("Not implemented");
  }
}
