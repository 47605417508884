import { useMediaQuery } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ExpandableNotesAndDocumentsBox } from "../../misc/ExpandableNotesAndDocumentsBox";
import { NotesAndDocumentsCard } from "./cards/NotesAndDocumentsCard";
import React, { ReactElement } from "react";

export function StudentTab(props: { children: ReactElement | Array<ReactElement> }) {
  const isSmallScreen = useMediaQuery("(max-width: 1400px)");
  const children = React.Children.toArray(props.children);

  if (children.length < 1 || children.length > 2) {
    throw new Error(`Unexpect children.length: ${children.length} -- expected: 1 or 2`);
  }

  const leftColumn = (
    <div style={{ flex: 2, paddingRight: isSmallScreen ? 0 : "15px" }}>
      {children.length === 1 && children[0]}
      {children.length === 2 && (
        <Grid2 container spacing="30px" /* see https://mui.com/material-ui/react-grid2 */>
          {children.map((child, index) => (
            <Grid2 flex={1} key={index}>
              {child}
            </Grid2>
          ))}
        </Grid2>
      )}
    </div>
  );

  return (
    <>
      {isSmallScreen && leftColumn}
      {!isSmallScreen && (
        <div style={{ display: "flex" }}>
          {leftColumn}
          <div style={{ flex: 1, paddingLeft: "15px" }}>
            <NotesAndDocumentsCard source="student" />
          </div>
        </div>
      )}
      {isSmallScreen && <ExpandableNotesAndDocumentsBox source="student" />}
    </>
  );
}
