import {
  BooleanField,
  Datagrid,
  FunctionField,
  NumberField,
  TextField,
  useGetOne,
  useListContext,
  useRecordContext,
} from "react-admin";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";
import { ShowButton } from "../buttons/ShowButton";
import { StudentBranchField } from "../fields/StudentBranchField";
import { DrivingSchool } from "../providers/drivingSchoolsProvider";
import { MoneyField } from "../fields/MoneyField";
import { LoadingIndicator } from "../misc/LoadingIndicator";
import { DateField } from "../fields/DateField";
import { Avatar, Box, Typography } from "@mui/material";
import { Student } from "../providers/studentsProvider";
import { dotStyle, studentStatusColors } from "../misc/backofficeTheme";
import TimelineDot from "@mui/lab/TimelineDot";
import { TheorieunterrichtIcon } from "../icons/TheorieunterrichtIcon";
import { CarIcon } from "../icons/CarIcon";
import { AutobahnIcon } from "../icons/AutobahnIcon";
import { ManualGearIcon } from "../icons/ManualGearIcon";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { ReactElement, useEffect } from "react";

export function StudentsDataGrid(props: { drivingSchool?: DrivingSchool }) {
  const { isLoading: isLoading1 } = useListContext();
  const { data, isLoading: isLoading2 } = useGetOne<DrivingSchool>(
    "drivingSchools",
    { id: restrictAccessToDrivingSchoolIds?.[0] ?? "" },
    { enabled: !props.drivingSchool && restrictAccessToDrivingSchoolIds?.length === 1 },
  );

  const isLoading = isLoading1 || isLoading2;
  const drivingSchool = props.drivingSchool || data;

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Datagrid rowClick="edit" bulkActionButtons={false} className="students-list">
      <FunctionField label="Name" source="name" render={_renderAvatarAndName} />
      {drivingSchool && drivingSchool.branches.length > 1 && (
        <StudentBranchField label="Filiale" drivingSchoolId={drivingSchool.id} />
      )}
      <BooleanField label="Wechsel" source="isSchoolChanger" FalseIcon={null} />
      <TextField label="Ausbildung" source="bookedTrainingsSummary" />
      <DateField label="Anmeldung" source="startDate" />
      <NumberField label={<_IconLabel icon={<TheorieunterrichtIcon />} />} source="kpis.finishedTheoryLessonsCount" />
      <NumberField
        label={<_IconLabel icon={<CarIcon />} />}
        source="kpis.finishedNormalDrivingLessonsCount"
        options={{ maximumFractionDigits: 2 }}
      />
      <NumberField
        label={<_IconLabel icon={<AutobahnIcon />} />}
        source="kpis.finishedSpecialDrivingLessonsCount"
        options={{ maximumFractionDigits: 2 }}
      />
      <NumberField
        label={<_IconLabel icon={<ManualGearIcon />} />}
        source={"kpis.finishedManualGearLessonsCount"}
        options={{ maximumFractionDigits: 2 }}
      />
      <_StatusField label="Status" source="verboseStatus" />
      <MoneyField label="Saldo" source="balance" redForNegativeValues />
      <ShowButton />
    </Datagrid>
  );
}

function _IconLabel({ icon }: { icon: ReactElement }) {
  const setIsLegendVisible = useSetRecoilState(_isLegendVisibleAtom);
  useEffect(() => {
    setIsLegendVisible("hidden");
    return () => setIsLegendVisible("invisible");
  }, []);
  return (
    <div onMouseEnter={() => setIsLegendVisible("shown")} onMouseLeave={() => setIsLegendVisible("hidden")}>
      {icon}
    </div>
  );
}

const _isLegendVisibleAtom = atom<"invisible" | "hidden" | "shown">({
  key: "isStudentsDataGridLegendVisible",
  default: "invisible",
});

export function StudentsDataGridLegend() {
  const { isLoading } = useListContext();
  const isLegendVisible = useRecoilValue(_isLegendVisibleAtom);

  if (isLoading) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        right: 0,
        top: isLegendVisible === "shown" ? "-60px" : "80px",
        boxShadow: "rgba(0, 0, 0, 0.06) 0 2px 5px",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        padding: "5px 10px 20px",
        backgroundColor: "white",
        color: "#9b9b9b",
        fontSize: "14px",
        transition: "top .5s ease-out",
        visibility: isLegendVisible === "invisible" ? "hidden" : "visible",
      }}
    >
      <TheorieunterrichtIcon sx={{ mt: "8px", mb: "-8px" }} /> Theoriestunden
      <br />
      <CarIcon sx={{ mt: "8px", mb: "-8px" }} /> Übungsstunden
      <br />
      <AutobahnIcon sx={{ mt: "8px", mb: "-8px" }} /> Sonderstunden (Überland, Autobahn, Dämmerung)
      <br />
      <ManualGearIcon sx={{ mt: "8px", mb: "-8px" }} /> Schaltwagenstunden
    </div>
  );
}

export function _StatusField({ label, source }: { label: string; source: string }) {
  const student = useRecordContext<Student>();
  return (
    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
      <TimelineDot sx={{ ...dotStyle, backgroundColor: studentStatusColors[student.verboseStatus] }} />
      <TextField label={label} source={source} />
    </Box>
  );
}

function _renderAvatarAndName(student: Student) {
  return (
    <Box sx={{ display: "flex", gap: "15px", alignItems: "center", marginLeft: "16px" }}>
      <Avatar src={student?.avatarOverrideUrl ?? student?.avatarUrl} />
      <Typography sx={{ fontSize: 14 }}>{student.name}</Typography>
    </Box>
  );
}
