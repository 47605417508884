import { useMemo } from "react";
import { FieldValues, Path } from "react-hook-form";
import { DrivingLicenseClass } from "../model/DrivingLicenseClass";
import { SelectInput } from "./SelectInput";
import { Student } from "../providers/studentsProvider";

interface DrivingLicenseClassInputProps {
  source: Path<FieldValues>;
  student: Student;
  label?: string;
}
export const DrivingLicenseClassInput = ({
  source,
  student,
  label = "Führerscheinklasse",
}: DrivingLicenseClassInputProps) => {
  const drivingLicenseClassOptions = useMemo(() => {
    const unfinishedBookedTrainings = student.bookedTrainings.filter(
      (it) => !Object.values(it.kpis?.practicalExams ?? {}).some((it) => it.result === "passed"),
    );
    return unfinishedBookedTrainings.map((it) => [it.drivingLicenseClass, it.drivingLicenseClass]);
  }, [student]) as Array<[DrivingLicenseClass, string]>;
  if (drivingLicenseClassOptions.length <= 1) {
    return null;
  }
  return (
    <SelectInput
      source={source}
      label={label}
      options={drivingLicenseClassOptions}
      validate={(value) => (value ? true : "Bitte wähle die Führerscheinklasse aus.")}
    />
  );
};
