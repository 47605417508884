import { Datagrid, FieldProps, List, SearchInput, TextField, useGetRecordId, useRecordContext } from "react-admin";
import { AvatarField } from "../../fields/AvatarField";
import { DateField } from "../../fields/DateField";
import { Student } from "../../providers/studentsProvider";
import { Typography } from "@mui/material";
import { DateTime } from "luxon";
import { QuickFilter } from "../../misc/QuickFilter";
import { isNonEmptyString } from "../../utils/filters";
import { EmptyState } from "../../misc/EmptyState";
import { PageTitle } from "../../misc/PageTitle";

const filters = [
  <SearchInput source="q" alwaysOn />,
  <QuickFilter label="gültige Prüfaufträge" source="__valid_examination_assignments__" defaultValue={true} />,
  <QuickFilter label="abgelaufene Prüfaufträge" source="__expired_examination_assignments__" defaultValue={true} />,
];

export function DrivingSchoolExaminationAssignmentsList({ title }: { title?: string }) {
  const drivingSchoolId = useGetRecordId();

  return (
    <>
      {title && <h2 style={{ margin: "20px 0 0", fontSize: "18px" }}>{title}</h2>}
      <List
        resource="examinationAssignments"
        title={<PageTitle>Prüfaufträge</PageTitle>}
        filter={{
          drivingSchoolId,
          examinationAssignmentExpiresAt: isNonEmptyString,
          status: ["active", "onHold", "outstandingPayments"],
        }}
        filters={filters}
        sort={/* this is just the default sort order: */ { field: "examinationAssignmentExpiresAt", order: "ASC" }}
        exporter={false}
        empty={<EmptyState label="Noch keine Prüfaufträge vorhanden." />}
      >
        <Datagrid rowClick={(id) => `/students/${id}`} bulkActionButtons={false}>
          <AvatarField label="" source="avatarUrl" />
          <TextField label="Fahrschüler" source="name" />
          <TextField label="Status des Fahrschülers" source="verboseStatus" />
          <DateField label="Anmeldung" source="startDate" />
          <DateField label="Eingangsdatum" source="examinationAssignmentReceivedAt" />
          <DateField label="Ablaufdatum" source="examinationAssignmentExpiresAt" showDate="with diff" />
          <ExamPlannedField label="Prüfungstermin organisiert?" />
        </Datagrid>
      </List>
    </>
  );
}

function ExamPlannedField(_: FieldProps) {
  const student = useRecordContext<Student>();
  let displayedValue: string;
  let nextExam:
    | { date: DateTime; rsvp?: "pending" | "accepted" | "declined" | "rejected" | "canceled" | "no-show" }
    | undefined;
  for (const bookedTraining of student.bookedTrainings) {
    if (!bookedTraining.isFinished) {
      const practicalExams = Object.values(bookedTraining.kpis?.practicalExams ?? {});
      nextExam = practicalExams.find((exam) => !exam.result && (exam.rsvp === "pending" || exam.rsvp === "accepted"));
      if (nextExam) {
        break;
      }
    }
  }
  if (!nextExam) {
    const theoryExams = Object.values(student.kpis.theoryExams);
    nextExam = theoryExams.find((exam) => !exam.result && (exam.rsvp === "pending" || exam.rsvp === "accepted"));
  }
  if (nextExam) {
    displayedValue = `Ja, am ${nextExam.date.toLocaleString(undefined, { locale: "de" })}`;
    const now = DateTime.now();
    const today = DateTime.fromObject({ year: now.year, month: now.month, day: now.day });
    const days = Math.round(today.diff(nextExam.date, "days").days);
    if (days < -1) {
      displayedValue += ` (in ${-days} Tagen)`;
    } else if (days === -1) {
      displayedValue = "Ja, morgen";
    } else if (days === 0) {
      displayedValue = "Ja, heute";
    } else if (days === 1) {
      displayedValue = "Ja, gestern";
    } else if (days > 1) {
      displayedValue += ` (vor ${days} Tagen)`;
    }
    if (nextExam.rsvp === "pending") {
      displayedValue += days < 0 ? " • noch nicht bestätigt!" : " • nicht bestätigt";
    }
  } else {
    displayedValue = "Nein";
  }
  return (
    <Typography component="span" variant="body2">
      {displayedValue}
    </Typography>
  );
}
