import { useGetList } from "react-admin";
import { FieldValues, Path } from "react-hook-form";
import { Instructor } from "../providers/instructorsProvider";
import { Student } from "../providers/studentsProvider";
import { StartingPoint } from "../providers/startingPointsProvider";
import { SelectInput } from "./SelectInput";
import { renderAddress } from "../misc/AddressDisplay";

interface StartingPointInputProps {
  source: Path<FieldValues>;
  instructor: Instructor;
  student: Student;
}
export const StartingPointInput = ({ source, instructor, student }: StartingPointInputProps) => {
  const { data: startingPoints } = useGetList<StartingPoint>("startingPoints", {
    sort: { field: "__branches__student_address__favorite_locations__", order: "ASC" },
    pagination: { page: 1, perPage: 999 },
    meta: { instructor, student },
  });
  return (
    <SelectInput
      source={source}
      label="Startpunkt"
      options={startingPoints?.map((it) => [
        it.id,
        `${it.name} (${renderAddress(it.postalAddress, { oneLine: true })})`,
      ])}
      validate={(value) => (value ? true : "Bitte wähle den Startpunkt aus.")}
    />
  );
};
