import { Card, useMediaQuery } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ReactElement } from "react";
import { RecordContextProvider, useShowController } from "react-admin";
import { restrictAccessToDrivingSchoolIds } from "../../backoffice.access_control";
import { useAutovioContext } from "../../hooks/useAutovioContext";
import { AutovioMap } from "../../misc/maps";
import { PageTitle } from "../../misc/PageTitle";
import { LatestDrivingStudentsCard } from "./cards/LatestDrivingStudentsCard";
import { MapCard } from "./cards/MapCard";
import { NotesAndDocumentsCard } from "./cards/NotesAndDocumentsCard";
import { ExpandableNotesAndDocumentsBox } from "../../misc/ExpandableNotesAndDocumentsBox";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import { Column } from "../../misc/Column";
import { Row } from "../../misc/Row";

export function DrivingSchoolDashboard(): ReactElement | null {
  const [{ drivingSchoolId }] = useAutovioContext();
  const { record: drivingSchool } = useShowController({
    resource: "drivingSchools",
    id: drivingSchoolId,
    queryOptions: { enabled: !!drivingSchoolId },
  });
  const isSmallScreen = useMediaQuery("(max-width: 1400px)");

  if (!drivingSchoolId) {
    if (restrictAccessToDrivingSchoolIds) {
      return (
        <div className="centered">
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            Bitte wähle eine Fahrschule aus.
          </div>
        </div>
      );
    }

    return (
      <>
        <PageTitle>AUTOVIO Fahrschulen</PageTitle>
        <Card sx={{ mt: "10px", height: "calc(100% - 66px)" }}>
          <AutovioMap />
        </Card>
      </>
    );
  }

  if (!drivingSchool) {
    return <LoadingIndicator />;
  }

  return (
    <RecordContextProvider value={drivingSchool}>
      {/* See https://mui.com/material-ui/react-grid2/ */}
      <Column>
        <Row>
          <PageTitle>{drivingSchool.name}</PageTitle>
        </Row>
        <Grid2
          container
          columns={isSmallScreen ? 2 : 3}
          spacing="30px" /* see https://mui.com/material-ui/react-grid2 */
        >
          <Grid2 flex={1}>
            <MapCard />
          </Grid2>
          <Grid2 flex={1}>
            <LatestDrivingStudentsCard />
          </Grid2>
          {!isSmallScreen && (
            <Grid2 flex={1}>
              <NotesAndDocumentsCard />
            </Grid2>
          )}
        </Grid2>
        {isSmallScreen && <ExpandableNotesAndDocumentsBox source="drivingSchool" />}
      </Column>
    </RecordContextProvider>
  );
}
