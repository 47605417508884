import {
  and,
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  or,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { DateTime } from "luxon";
import {
  DataProvider,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { applyFilter, applySort } from "../backoffice.utils";
import { firestore } from "../firebase";
import { AutovioCalendarEvent } from "../model/autovioCalendarEvents";
import { autovioCalendarEventConverter } from "./converter/autovioCalendarEventConverter";
import { QueryFilterConstraint } from "@firebase/firestore";
import { TrackRunningQueriesWrapper } from "./trackRunningQueriesWrapper";
import { AbstractProvider } from "./abstractProvider";

class CalendarEventsProvider extends AbstractProvider<AutovioCalendarEvent> {
  async getOne(_: string, { id }: GetOneParams): Promise<GetOneResult<AutovioCalendarEvent>> {
    const snapshot = await getDoc(doc(firestore, `calendar_events/${id}`));
    const event = autovioCalendarEventConverter.fromFirestore(snapshot);
    return { data: event };
  }

  async getManyReference(
    _: string,
    { target, id, filter, sort, meta }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<AutovioCalendarEvent>> {
    if (target !== "drivingSchoolId") {
      throw new Error(`Invalid target: ${JSON.stringify(target)} -- expected: "drivingSchoolId"`);
    }
    const { dateRange, instructorId, type, ...otherFilters } = filter;
    const { includeDeleted, withLessonsCanceledAtShortNotice } = meta ?? {};
    if (!dateRange) {
      throw new Error("filter.dateRange is required");
    }
    const queryFilterConstraints: Array<QueryFilterConstraint> = [
      where("drivingSchoolUid", "==", id),
      where("start", ">=", Timestamp.fromDate(DateTime.fromISO(dateRange.from, { zone: "Europe/Berlin" }).toJSDate())),
      where("start", "<=", Timestamp.fromDate(DateTime.fromISO(dateRange.to, { zone: "Europe/Berlin" }).toJSDate())),
    ];
    if (!includeDeleted) {
      queryFilterConstraints.push(where("deleted", "==", false));
    }
    if (instructorId) {
      if (typeof instructorId !== "string") {
        throw new Error(`Invalid filter.instructorId: ${JSON.stringify(type)} -- must be a string`);
      }
      queryFilterConstraints.push(where("derived.instructorUids", "array-contains", instructorId));
    }
    if (type) {
      const validTypes = ["TheoryLesson", "TheoryExam", "PracticalExam"];
      let types: Array<string>;
      if (typeof type === "string") {
        if (!validTypes.includes(type)) {
          throw new Error(
            `Invalid filter.type: ${JSON.stringify(type)} -- valid types: ${validTypes
              .map((it) => JSON.stringify(it))
              .join(", ")}`,
          );
        }
        types = [type];
      } else if (Array.isArray(type)) {
        if (type.length === 0) {
          console.warn(`Unexpected filter.type: ${JSON.stringify(type)} -- returning empty result`);
          return { data: [], total: 0 };
        }
        if (!type.every((it) => validTypes.includes(it))) {
          throw new Error(
            `Invalid filter.type: ${JSON.stringify(type)} -- valid types: ${validTypes
              .map((it) => JSON.stringify(it))
              .join(", ")}`,
          );
        }
        types = type;
      } else {
        throw new Error(
          `Invalid filter.type: ${JSON.stringify(type)} -- must be either a string or an array of strings`,
        );
      }
      const typeConstraints = types.map((it): QueryFilterConstraint => {
        if (it === "TheoryLesson") {
          return where("type", "==", "TheoryLesson");
        } else if (it === "TheoryExam") {
          return and(where("type", "==", "DrivingLesson"), where("drivingLessonType", "==", "theoretischePruefung"));
        } else if (it === "PracticalExam") {
          return and(where("type", "==", "DrivingLesson"), where("drivingLessonType", "==", "praktischePruefung"));
        } else {
          throw new Error(`Not implemented: Convert type ${JSON.stringify(it)} to query constraint`);
        }
      });
      if (types.length === 1) {
        queryFilterConstraints.push(typeConstraints[0]);
      } else {
        queryFilterConstraints.push(or(...typeConstraints));
      }
    }
    const calendarEventsRef = collection(firestore, "calendar_events");
    let docs = (await getDocs(query(calendarEventsRef, and(...queryFilterConstraints)))).docs;
    if (includeDeleted) {
      docs = docs.filter((doc) => !doc.data().replacedBy);
    }
    const eventsFromServer = docs.map((doc) => autovioCalendarEventConverter.fromFirestore(doc));
    let events = eventsFromServer;
    if (!includeDeleted) {
      events = events.filter((event) => {
        if (withLessonsCanceledAtShortNotice && event.student?.canceledAtShortNotice) {
          return true;
        }
        return !["rejected", "declined", "canceled"].includes(event.student?.rsvp);
      });
    }
    events = applyFilter(events, otherFilters);
    console.info(`Retrieved ${events.length} event(s)`, { [target]: id, ...filter });
    if (events.length < eventsFromServer.length) {
      console.info(`Over-Fetched ${eventsFromServer.length - events.length} event(s)`);
    }
    return { data: applySort(events, sort), total: events.length };
  }

  async update(
    resource: string,
    { id, data }: UpdateParams<AutovioCalendarEvent>,
  ): Promise<UpdateResult<AutovioCalendarEvent>> {
    const keys = Object.keys(data);
    if (keys.length !== 1 || (keys[0] !== "theoryExamResult" && keys[0] !== "practicalExamResult")) {
      throw new Error(
        `Unexpected data: ${JSON.stringify(data)} -- expected: {theoryExamResult: ...} or {practicalExamResult: ...}`,
      );
    }
    const key = keys[0];
    const value = data[key];
    if (value && value !== "passed" && value !== "failed") {
      throw new Error(`Unexpected ${key}: ${JSON.stringify(value)} -- expected: "passed" or "failed"`);
    }
    const updateData = { [key]: value || deleteField() };
    console.info(`Updating calendar_events/${id} ...`, updateData);
    await setDoc(doc(firestore, `calendar_events/${id}`), updateData, { merge: true });
    return this.getOne(resource, { id });
  }
}

export const calendarEventsProvider = new TrackRunningQueriesWrapper(new CalendarEventsProvider() as DataProvider);
