import { ShowBase } from "../misc/ShowBase";
import { Route, Routes } from "react-router-dom";
import { DrivingSchoolCalendar } from "./drivingSchool/DrivingSchoolCalendar";
import { DrivingSchoolFleet } from "./drivingSchool/DrivingSchoolFleet";
import { DrivingSchoolInstructorsList } from "./drivingSchool/DrivingSchoolInstructorsList";
import { DrivingSchoolPracticalExamWorkflowsBoard } from "./drivingSchool/DrivingSchoolPracticalExamWorkflowsBoard";
import { DrivingSchoolStudentsList } from "./drivingSchool/DrivingSchoolStudentsList";
import { DrivingSchoolTheoryExamWorkflowsBoard } from "./drivingSchool/DrivingSchoolTheoryExamWorkflowsBoard";
import { DrivingSchoolExaminationAssignmentsList } from "./drivingSchool/DrivingSchoolExaminationAssignmentsList";
import { DrivingSchoolPerformanceOverview } from "./drivingSchool/DrivingSchoolPerformanceOverview";
import { PayoutOverview } from "./drivingSchool/DrivingSchoolPayouts.js";
import { TaxConsultantExports } from "./drivingSchool/DrivingSchoolTaxConsultantsDownloads.js";
import { BusinessIntelligencePage } from "../misc/BusinessIntelligencePage";
import { DrivingSchoolDashboard } from "./drivingSchool/DrivingSchoolDashboard";
import { DrivingSchoolAdminForm } from "./drivingSchool/DrivingSchoolAdminForm";
import { DrivingSchoolCourses } from "./drivingSchool/DrivingSchoolCourses";

export function DrivingSchoolRoutes() {
  return (
    <ShowBase>
      <Routes>
        <Route path="/" element={<DrivingSchoolDashboard />} />
        <Route path="calendar" element={<DrivingSchoolCalendar />} />
        <Route path="instructors" element={<DrivingSchoolInstructorsList />} />
        <Route path="students" element={<DrivingSchoolStudentsList />} />
        <Route path="examinationAssignments" element={<DrivingSchoolExaminationAssignmentsList />} />
        <Route path="theoryExams" element={<DrivingSchoolTheoryExamWorkflowsBoard />} />
        <Route path="practicalExams" element={<DrivingSchoolPracticalExamWorkflowsBoard />} />
        <Route
          path="performanceOverview"
          element={<DrivingSchoolPerformanceOverview title="Leistungsübersicht" resource="performanceOverview" />}
        />
        <Route
          path="advancePayments"
          element={<DrivingSchoolPerformanceOverview title="Vorauszahlungen" resource="advancePayments" />}
        />
        <Route
          path="openInvoices"
          element={<DrivingSchoolPerformanceOverview title="Offene Posten" resource="openInvoices" />}
        />
        <Route path="payouts" element={<PayoutOverview />} />
        <Route path="tax" element={<TaxConsultantExports />} />
        <Route path="bi" element={<BusinessIntelligencePage />} />
        <Route path="fleet" element={<DrivingSchoolFleet />} />
        <Route path="courses" element={<DrivingSchoolCourses />} />
        <Route path="admin" element={<DrivingSchoolAdminForm />} />
      </Routes>
    </ShowBase>
  );
}
