import AttachFileIcon from "@mui/icons-material/AttachFile";
import EditIcon from "@mui/icons-material/EditOutlined";
import { Avatar, Box, Button, Card, Chip, IconButton, List, ListItem, Typography } from "@mui/material";
import { CSSProperties, forwardRef, ReactNode, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  RecordContextProvider,
  SaveButton,
  SimpleForm,
  TextInput,
  useGetOne,
  useNotify,
  useRecordContext,
} from "react-admin";
import { useWatch, type FieldValues } from "react-hook-form";
import { useQueryClient } from "react-query";
import { reportError } from "../backoffice.utils";
import { DateField } from "../fields/DateField";
import { useThreadId } from "../hooks/useThreadId.js";
import { autovioColors } from "../misc/backofficeTheme";
import { Spinner } from "../misc/Spinner";
import { CreatePostSchema, PostSchema, type CreatePostDto, type Post, type Thread } from "../model/Thread.js";
import { threadsProvider } from "../providers/threadsProvider.js";
import { User } from "../providers/usersProvider";
import { gcs } from "../utils/storage";

export const PostsList = forwardRef(({ title, style }: { title?: ReactNode; style?: CSSProperties }, ref) => {
  const threadId = useThreadId();
  return (
    <div style={{ position: "relative", ...(style ?? {}) }}>
      <_PostsList threadId={threadId} title={title} ref={ref} />
    </div>
  );
});

const _PostsList = forwardRef(({ threadId, title }: { threadId: string; title?: ReactNode }, ref) => {
  const { data: thread, isLoading } = useGetOne<Thread>("threads", { id: threadId });
  const data = thread?.sortedPosts;

  const [state, setState] = useState<"show button" | "show form">("show button");

  useImperativeHandle(ref, function () {
    return {
      showForm: () => setState("show form"),
    };
  });

  const titleAndButton = state === "show button" && (
    <div style={{ display: "flex", justifyContent: "space-between" }}>{title ?? <div />}</div>
  );
  const titleAndForm = state === "show form" && (
    <>
      {title}
      <_AddPostForm threadId={threadId} close={() => setState("show button")} />
    </>
  );

  if (isLoading) {
    return (
      <>
        {title}
        <div style={{ flex: 1 }}>
          <Spinner style={{ margin: "20px" }} />
        </div>
      </>
    );
  }

  if (!data || data.length === 0) {
    return (
      titleAndForm || (
        <>
          {titleAndButton}
          <Typography
            variant="body2"
            style={{
              marginTop: "20px",
              height: "40px",
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            Keine Notizen vorhanden.
          </Typography>
        </>
      )
    );
  }
  return (
    <>
      {titleAndButton || titleAndForm}
      <List sx={{ marginTop: "15px", paddingTop: 0 }}>
        {data.map((post) => (
          <RecordContextProvider key={post.id} value={post}>
            <_PostListItem />
          </RecordContextProvider>
        ))}
      </List>
    </>
  );
});

function getUserFromPost(post: Post, options?: { useUpdatedBy?: boolean }): User | undefined {
  const id = options?.useUpdatedBy ? post.updatedById : post.createdById;
  const { data: user } = useGetOne<User>("users", { id: id ?? "" }, { enabled: !!id });
  return user;
}

function _Avatar({ post }: { post: Post }) {
  const user = getUserFromPost(post);
  return <Avatar src={user?.avatarUrl} sx={{ width: 20, height: 20, marginTop: 0.5 }} />;
}

function _PostListItem() {
  const post = useRecordContext<Post>();
  const user = getUserFromPost(post);
  const updatedByUser = getUserFromPost(post, { useUpdatedBy: true });
  const updatedByName = updatedByUser?.name;
  const notify = useNotify();
  const [showEditButton, setShowEditButton] = useState(false);
  const [mode, setMode] = useState<"show" | "edit">("show");

  if (mode === "edit") {
    return <_EditPostForm post={post} close={() => setMode("show")} />;
  }

  return (
    <div
      style={{ position: "relative", marginBottom: 30 }}
      onMouseEnter={() => setShowEditButton(true)}
      onMouseLeave={() => setShowEditButton(false)}
    >
      <ListItem disablePadding>
        <Box sx={{ display: "flex", gap: 1 }}>
          <_Avatar post={post} />
          <Box>
            <>
              <DateField
                className="RaLabeled-label"
                source="createdAt"
                showTime
                sx={{ fontWeight: "bold", fontSize: "14px !important" }}
              />
              {user?.name && (
                <span className="RaLabeled-label" style={{ color: "#9b9b9b", fontSize: 14 }}>{` ${user?.name}`}</span>
              )}
              {post.updatedAt && post.updatedAt > post.createdAt && (
                <>
                  <span className="RaLabeled-label" style={{ color: "#9b9b9b", fontSize: 14 }}>
                    {" (geändert: "}
                  </span>
                  <DateField
                    className="RaLabeled-label"
                    source="updatedAt"
                    showTime
                    sx={{ color: "#9b9b9b", fontSize: "14px !important" }}
                  />
                  <span className="RaLabeled-label" style={{ color: "#9b9b9b", fontSize: 14 }}>
                    {updatedByName ? `, ${updatedByName})` : ")"}
                  </span>
                </>
              )}
            </>
            <_NoteText>{post.body ?? ""}</_NoteText>
          </Box>
        </Box>
      </ListItem>
      {showEditButton && (
        <IconButton
          sx={{ position: "absolute", top: "3px", right: "3px", width: 40, height: 40 }}
          onClick={() => setMode("edit")}
        >
          <EditIcon style={{ fill: autovioColors.green }} />
        </IconButton>
      )}

      <div style={{ display: "flex", gap: 5, marginLeft: 36, marginTop: 6 }}>
        {(post.attachments ?? []).map((it) => (
          <Chip
            sx={{
              height: 24,
              fontSize: 9,
              width: 90,
              fontWeight: 600,
              color: "#888",
              background: autovioColors.greyUltraLight,
            }}
            key={it.id}
            onClick={async () => {
              try {
                const downloadUrl = await gcs.getDownloadUrl(it.path);
                window.open(downloadUrl, "_blank");
              } catch (error) {
                notify("Fehler beim Öffnen der angehängten Datei.", { type: "error" });
                reportError(`Failed to get download URL for attachment ${it.id}`, error);
              }
            }}
            icon={<AttachFileIcon style={{ fontSize: 14, color: "#888" }} />}
            label="DOKUMENT"
            title={it.name}
          />
        ))}
      </div>
    </div>
  );
}

function _NoteText({ children: text, maxLines = 5 }: { children: string; maxLines?: number }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const { green } = autovioColors;

  useEffect(() => {
    const checkIfTruncated = () => {
      const textElement = textRef.current;
      if (textElement) {
        const lineHeight = parseFloat(window.getComputedStyle(textElement).lineHeight);
        const maxHeight = lineHeight * maxLines + 1;
        setIsTruncated(textElement.scrollHeight > maxHeight);
      }
    };

    checkIfTruncated();

    window.addEventListener("resize", checkIfTruncated);
    return () => window.removeEventListener("resize", checkIfTruncated);
  }, [text, maxLines]);

  return (
    <>
      <Typography
        ref={textRef}
        sx={{
          maxHeight: isExpanded ? "none" : `${maxLines * 1.2}em`,
          overflow: "hidden",
          lineHeight: "1.2em",
          textOverflow: "ellipsis",
          whiteSpace: "pre-wrap",
          wordWrap: "break-word",
          wordBreak: "break-word",
          fontSize: 14,
          padding: 0,
        }}
      >
        {text}
      </Typography>
      {isTruncated && !isExpanded && (
        <span style={{ color: green, cursor: "pointer", fontSize: 14 }} onClick={() => setIsExpanded(true)}>
          mehr anzeigen
        </span>
      )}
    </>
  );
}

function _AddPostForm({ threadId, close }: { threadId: string; close: () => void }) {
  return (
    <Card
      sx={{
        marginTop: 0,
        marginBottom: "8px",
        border: "1px solid #D8D8D8",
      }}
    >
      <_PostForm close={close} mode="create" post={{ threadId }} />
    </Card>
  );
}

function _EditPostForm({ post, close }: { post: Post; close: () => void }) {
  return (
    <Card
      sx={{
        marginTop: 0,
        marginBottom: "8px",
        border: "1px solid #D8D8D8",
      }}
    >
      <_PostForm close={close} post={post} mode="edit" />
    </Card>
  );
}

function _PostForm({ close, post, mode }: { close: () => void; post: Post | CreatePostDto; mode: "edit" | "create" }) {
  const validate = (values: Record<string, any>) => {
    const errors: { [field: string]: string } = {};
    if (!values.body || values.body.trim().length === 0) {
      errors.body = "Eine Nachricht darf nicht leer sein.";
    }
    return errors;
  };

  const queryClient = useQueryClient();
  const notify = useNotify();

  const handleSubmit = async (data: FieldValues) => {
    if (mode === "create") {
      try {
        const post = CreatePostSchema.parse(data);
        await threadsProvider.create("threads", { data: post });
        await queryClient.invalidateQueries(["threads"]);
        notify("Nachricht erfolgreich hinzugefügt.", { type: "success" });
        close();
      } catch (error) {
        reportError("Failed to update post", error);
        notify("Fehler beim Erstellen der Nachricht. Bitte versuche es später nochmal.", { type: "error" });
      }
    } else if (mode === "edit") {
      try {
        const post = PostSchema.parse(data);
        await threadsProvider.update("threads", { data: post, id: post.id, previousData: post });
        await queryClient.invalidateQueries(["threads"]);
        notify("Nachricht erfolgreich aktualisiert.", { type: "success" });
        close();
      } catch (error) {
        reportError("Failed to update post", error);
        notify("Fehler beim Bearbeiten der Nachricht. Bitte versuche es später nochmal.", { type: "error" });
      }
    }
  };

  return (
    <SimpleForm
      record={post}
      sx={{ padding: "0px", height: "100px" }}
      onSubmit={handleSubmit}
      validate={validate}
      toolbar={<_PostActions close={close} />}
    >
      <TextInput
        source="body"
        label={false}
        rows={4}
        fullWidth
        multiline
        sx={{
          ".MuiOutlinedInput-notchedOutline": { borderColor: "transparent !important" },
        }}
      />
    </SimpleForm>
  );
}

function _PostActions({ close }: { close: () => void }) {
  const bodyText = useWatch({ name: "body" });
  return (
    <div style={{ padding: "0 6px 6px 6px", display: "flex", justifyContent: "flex-end" }}>
      <Button variant="outlined" size="small" onClick={close} style={{ marginRight: "6px" }}>
        Abbrechen
      </Button>
      <SaveButton disabled={!bodyText || bodyText.trim().length === 0} />
    </div>
  );
}
