import { CSSProperties, forwardRef, ReactNode, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ReferenceManyField } from "../fields/ReferenceManyField";
import {
  CreateBase,
  EditBase,
  Identifier,
  RecordContextProvider,
  SaveButton,
  SimpleForm,
  TextInput,
  useGetRecordId,
  useInput,
  useListContext,
  useNotify,
  useRecordContext,
} from "react-admin";
import { DateField } from "../fields/DateField";
import { type DrivingSchoolNote, isDrivingSchoolNote, isStudentNote, StudentNote } from "../model/Note";
import { Avatar, Box, Button, Chip, IconButton, List, ListItem, Tooltip, Typography } from "@mui/material";
import { Spinner } from "../misc/Spinner";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { gcs } from "../utils/storage";
import { reportError } from "../backoffice.utils";
import { useWatch } from "react-hook-form";
import { useQueryClient } from "react-query";
import EditIcon from "@mui/icons-material/EditOutlined";
import { autovioColors } from "../misc/backofficeTheme";
import { User, usersProvider } from "../providers/usersProvider";
import type { Note, NotesSource } from "../providers/notesProvider.js";
import { useDropzone } from "react-dropzone";
import { Row } from "../misc/Row";
import { Column } from "../misc/Column";
import { grants } from "../backoffice.access_control";
import FirestoreIcon from "@mui/icons-material/LocalFireDepartment";
import { openInFirestoreConsole } from "../firebase";

export const NotesList = forwardRef(
  ({ title, style, resource }: { title?: ReactNode; style?: CSSProperties; resource: NotesSource }, ref) => {
    const recordId = useGetRecordId();
    return (
      <div style={{ position: "relative", ...(style ?? {}) }}>
        <ReferenceManyField
          label=""
          resource={resource}
          reference={resource}
          target={resource === "studentNotes" ? "studentUid" : "drivingSchoolUid"}
          sort={{ field: "createdAt", order: "DESC" }}
          // Filter out notes without a body ...
          filter={{ body: (it: StudentNote | DrivingSchoolNote) => !!it }}
        >
          <_NotesList resource={resource} recordId={recordId} title={title} ref={ref} />
        </ReferenceManyField>
      </div>
    );
  },
);

const _NotesList = forwardRef(
  ({ recordId, title, resource }: { recordId: Identifier; title?: ReactNode; resource: NotesSource }, ref) => {
    const { data, isLoading, total } = useListContext<StudentNote | DrivingSchoolNote>();
    const [state, setState] = useState<"show button" | "show form">("show button");

    useImperativeHandle(ref, function () {
      return {
        showForm: () => setState("show form"),
      };
    });

    const titleAndButton = state === "show button" && (
      <div style={{ display: "flex", justifyContent: "space-between" }}>{title ?? <div />}</div>
    );
    const titleAndForm = state === "show form" && (
      <>
        {title}
        <_AddNoteForm resource={resource} recordId={recordId} close={() => setState("show button")} />
      </>
    );

    if (isLoading) {
      return (
        <>
          {title}
          <div style={{ flex: 1 }}>
            <Spinner style={{ margin: "20px" }} />
          </div>
        </>
      );
    }

    if (!data || data.length === 0 || total === 0) {
      return (
        titleAndForm || (
          <>
            {titleAndButton}
            <Typography
              variant="body2"
              style={{
                marginTop: "20px",
                height: "40px",
                marginBottom: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              Keine Notizen vorhanden.
            </Typography>
          </>
        )
      );
    }
    return (
      <>
        {titleAndButton || titleAndForm}
        <List sx={{ marginTop: "15px", paddingTop: 0 }}>
          {data.map((note) => (
            <RecordContextProvider key={note.id} value={note}>
              <_NotesListItem resource={resource} />
            </RecordContextProvider>
          ))}
        </List>
      </>
    );
  },
);

function useCreatedBy(note: StudentNote | DrivingSchoolNote): Omit<User, "id"> {
  const [createdBy, setCreatedBy] = useState<Omit<User, "id">>({
    name: (isStudentNote(note) && note.createdByName) || "",
  });
  useEffect(() => {
    void (async () => {
      if (isDrivingSchoolNote(note)) {
        const { data: author } = await dataProvider.getOne("users", { id: note.createdById });
        setCreatedBy(author);
      } else if (isStudentNote(note) && note.createdByUid) {
        const { data: author } = await dataProvider.getOne("users", { id: note.createdByUid });
        setCreatedBy(author);
      }
    })();
  }, [isDrivingSchoolNote(note) ? note.createdById : note.createdByUid]);
  return createdBy;
}

function useUpdatedBy(note: StudentNote | DrivingSchoolNote): Omit<User, "id"> {
  const [updatedBy, setUpdatedBy] = useState<Omit<User, "id">>({
    name: (isStudentNote(note) && note.updatedByName) || "",
  });
  useEffect(() => {
    if (note.updatedAt && note.updatedAt > note.createdAt) {
      void (async () => {
        if (isDrivingSchoolNote(note) && note.updatedById) {
          const { data: editor } = await usersProvider.getOne("users", { id: note.updatedById });
          setUpdatedBy(editor);
        } else if (isStudentNote(note) && note.createdByUid) {
          const { data: editor } = await usersProvider.getOne("users", { id: note.updatedByUid });
          setUpdatedBy(editor);
        }
      })();
    }
  }, [note.createdAt, note.updatedAt, isDrivingSchoolNote(note) ? note.updatedById : note.updatedByUid]);
  return updatedBy;
}

function _NotesListItem({ resource }: { resource: NotesSource }) {
  const note = useRecordContext<Note>();
  const author = useCreatedBy(note);
  const editor = useUpdatedBy(note);
  const notify = useNotify();
  const [showEditButton, setShowEditButton] = useState(false);
  const [mode, setMode] = useState<"show" | "edit">("show");

  if (mode === "edit") {
    return <_EditNoteForm resource={resource} note={note} close={() => setMode("show")} />;
  }

  return (
    <div
      style={{ position: "relative", marginBottom: 30 }}
      onMouseEnter={() => setShowEditButton(true)}
      onMouseLeave={() => setShowEditButton(false)}
    >
      <ListItem disablePadding>
        <Row spacing={1}>
          <Avatar src={author.avatarUrl} sx={{ width: 20, height: 20, marginTop: 0.5 }} />
          <Column>
            <Box>
              <DateField
                className="RaLabeled-label"
                source="createdAt"
                showTime
                sx={{ fontWeight: "bold", fontSize: "14px !important" }}
              />
              {author.name && (
                <span className="RaLabeled-label" style={{ color: "#9b9b9b", fontSize: 14 }}>{` ${author.name}`}</span>
              )}
              {note.updatedAt && note.updatedAt > note.createdAt && (
                <>
                  <span className="RaLabeled-label" style={{ color: "#9b9b9b", fontSize: 14 }}>
                    {" (geändert: "}
                  </span>
                  <DateField
                    className="RaLabeled-label"
                    source="updatedAt"
                    showTime
                    sx={{ color: "#9b9b9b", fontSize: "14px !important" }}
                  />
                  <span className="RaLabeled-label" style={{ color: "#9b9b9b", fontSize: 14 }}>
                    {editor.name ? `, ${editor.name})` : ")"}
                  </span>
                </>
              )}
            </Box>
            <_NoteText>{note.body ?? ""}</_NoteText>
            <Box sx={{ display: "flexWrap" }}>
              {(note.attachments ?? []).map((it) => (
                <Chip
                  key={it.id}
                  sx={{
                    marginTop: "3px",
                    marginRight: "3px",
                    height: 24,
                    fontSize: 9,
                    fontWeight: 600,
                    color: "#888",
                    background: autovioColors.greyUltraLight,
                  }}
                  onClick={async () => {
                    try {
                      const downloadUrl = await gcs.getDownloadUrl(it.path);
                      window.open(downloadUrl, "_blank");
                    } catch (error) {
                      notify("Fehler beim Öffnen der angehängten Datei.", { type: "error" });
                      reportError(`Failed to get download URL for attachment ${it.id}`, error);
                    }
                  }}
                  icon={<AttachFileIcon style={{ fontSize: 14, color: "#888" }} />}
                  label={it.name}
                />
              ))}
            </Box>
          </Column>
        </Row>
      </ListItem>
      {showEditButton && (
        <>
          <IconButton
            sx={{ position: "absolute", top: "3px", right: "3px", width: 40, height: 40 }}
            onClick={() => setMode("edit")}
          >
            <EditIcon style={{ fill: autovioColors.green }} />
          </IconButton>
          {grants.includes("viewFirestore") && (
            <IconButton
              sx={{ position: "absolute", top: "3px", right: "46px", width: 40, height: 40 }}
              onClick={() =>
                openInFirestoreConsole(
                  isDrivingSchoolNote(note)
                    ? `/driving_schools/${note.drivingSchoolId}/notes/${note.id}`
                    : `/users/${note.studentUid}/notes/${note.id}`,
                )
              }
            >
              <FirestoreIcon style={{ fill: autovioColors.green }} />
            </IconButton>
          )}
        </>
      )}
    </div>
  );
}

function _NoteText({ children: text, maxLines = 5 }: { children: string; maxLines?: number }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const { green } = autovioColors;

  useEffect(() => {
    const checkIfTruncated = () => {
      const textElement = textRef.current;
      if (textElement) {
        const lineHeight = parseFloat(window.getComputedStyle(textElement).lineHeight);
        const maxHeight = lineHeight * maxLines + 1;
        setIsTruncated(textElement.scrollHeight > maxHeight);
      }
    };

    checkIfTruncated();

    window.addEventListener("resize", checkIfTruncated);
    return () => window.removeEventListener("resize", checkIfTruncated);
  }, [text, maxLines]);

  return (
    <>
      <Typography
        ref={textRef}
        sx={{
          maxHeight: isExpanded ? "none" : `${maxLines * 1.2}em`,
          overflow: "hidden",
          lineHeight: "1.2em",
          textOverflow: "ellipsis",
          whiteSpace: "pre-wrap",
          wordWrap: "break-word",
          wordBreak: "break-word",
          fontSize: 14,
          padding: 0,
        }}
      >
        {text}
      </Typography>
      {isTruncated && !isExpanded && (
        <span style={{ color: green, cursor: "pointer", fontSize: 14 }} onClick={() => setIsExpanded(true)}>
          mehr anzeigen
        </span>
      )}
    </>
  );
}

function _AddNoteForm({
  recordId,
  close,
  resource,
}: {
  recordId: Identifier;
  close: () => void;
  resource: NotesSource;
}) {
  const queryClient = useQueryClient();

  return (
    <CreateBase
      record={{ recordId }}
      mutationOptions={{
        onSuccess: async () => {
          await queryClient.invalidateQueries([resource]);
          close();
        },
      }}
    >
      <_NoteForm mode="create" close={close} />
    </CreateBase>
  );
}

function _EditNoteForm({
  note,
  close,
  resource,
}: {
  note: StudentNote | DrivingSchoolNote;
  close: () => void;
  resource: NotesSource;
}) {
  const queryClient = useQueryClient();
  return (
    <EditBase
      resource={resource}
      id={note.id}
      redirect={false}
      className="edit-note"
      mutationMode="pessimistic"
      sx={{
        marginTop: 0,
        marginBottom: "8px",
        border: "1px solid #D8D8D8",
      }}
      mutationOptions={{
        onSuccess: async () => {
          await queryClient.invalidateQueries([resource]);
          close();
        },
      }}
    >
      <_NoteForm mode="edit" close={close} />
    </EditBase>
  );
}

function _NoteForm({ mode, close }: { mode: "create" | "edit"; close: () => void }) {
  const ref = useRef<{ openSelectFilesDialog: () => void }>();

  const validate = (values: Record<string, any>) => {
    const errors: { [field: string]: string } = {};
    if (!values.body || values.body.trim().length === 0) {
      errors.body = "Eine Notiz darf nicht leer sein.";
    }
    return errors;
  };

  return (
    <SimpleForm
      sx={{ paddingLeft: 0, paddingRight: 0 }}
      validate={validate}
      toolbar={
        <_NoteActions
          openSelectFilesDialog={mode === "create" && (() => ref.current!.openSelectFilesDialog())}
          close={close}
        />
      }
    >
      <_NoteFormFields ref={ref} />
    </SimpleForm>
  );
}

const _NoteFormFields = forwardRef(function _NoteFormFields(_, ref) {
  // Copied and adapted from react-admin FileInput.tsx ...

  const {
    id,
    field: { onChange, onBlur, value },
  } = useInput({ source: "files" });
  const files = value ? (Array.isArray(value) ? value : [value]) : [];

  const onDrop = (newFiles: any) => {
    onChange([...files, ...newFiles]);
    onBlur();
  };

  const {
    getRootProps,
    getInputProps,
    open: openSelectFilesDialog,
  } = useDropzone({
    multiple: true,
    noClick: true,
    onDrop,
  });

  useImperativeHandle(ref, () => ({ openSelectFilesDialog }), []);

  return (
    <div {...getRootProps()} style={{ width: "100%" }}>
      <input id={id} name={id} {...getInputProps()} />
      <TextInput
        source="body"
        label={false}
        rows={4}
        fullWidth
        multiline
        sx={{
          border: "1px solid #D8D8D8",
          ".MuiOutlinedInput-notchedOutline": { borderColor: "transparent !important" },
        }}
      />
      {files.map((file: File) => (
        <div key={file.name} style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
          <AttachFileIcon style={{ fontSize: 14 }} />
          <Typography
            variant="body2"
            style={{ marginLeft: "6px", fontSize: 12, overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {file.name}
          </Typography>
        </div>
      ))}
    </div>
  );
});

function _NoteActions({
  openSelectFilesDialog,
  close,
}: {
  openSelectFilesDialog: false | (() => void);
  close: () => void;
}) {
  const bodyText = useWatch({ name: "body" });
  return (
    <Row>
      {openSelectFilesDialog && (
        <Tooltip title="Dokument(e) anhängen" placement="top">
          <IconButton
            size="small"
            color="primary"
            onClick={openSelectFilesDialog}
            sx={{ border: `1px solid ${autovioColors.green}` }}
          >
            <AttachFileIcon />
          </IconButton>
        </Tooltip>
      )}
      <div style={{ flex: 1 }} />
      <Button variant="outlined" size="small" onClick={close} style={{ marginRight: "6px" }}>
        Abbrechen
      </Button>
      <SaveButton disabled={!bodyText || bodyText.trim().length === 0} />
    </Row>
  );
}
