import { BackofficeListTitle } from "../../misc/BackofficeListTitle";
import { Datagrid, List, NumberField, TextField } from "react-admin";
import { MoneyField } from "../../fields/MoneyField";
import { EmptyState } from "../../misc/EmptyState";
import { MostRecentLessonField } from "../../fields/MostRecentLessonField";
import { Typography } from "@mui/material";
import { ReferenceDrivingSchoolField } from "../../fields/ReferenceDrivingSchoolField";
import { Money } from "../../misc/Money";
import { autovioColors } from "../../misc/backofficeTheme";
import { DateField } from "../../fields/DateField";
import { HandoverToPairFinanceButton } from "../../buttons/HandoverToPairFinanceButton";
import { usePreventRowClick } from "../../hooks/usePreventRowClick";
import { GlobalDrivingSchoolFilter } from "../../misc/GlobalDrivingSchoolFilter";
import { useBalanceSum } from "../../hooks/useBalanceSum";
import { useRecoilValue } from "recoil";
import { persistentGlobalDrivingSchoolFilterState } from "../../persistent_state";
import { Row } from "../../misc/Row";
import { ParkOutButton } from "../../buttons/ParkOutButton";
import { ParkButton } from "../../buttons/ParkButton";

export function CandidatesForHandoverToPairFinanceList() {
  const listDescription = "Liste aller Fahrschüler, die bereits zweimal erfolglos angemahnt wurden";
  const sum = useBalanceSum("candidatesForHandoverToPairFinance", "candidatesForHandoverToPairFinanceParkingLot");
  return (
    <>
      {sum ? (
        <Typography>
          {`${listDescription} • Summe ausstehende Zahlungen: `}
          <Money cents={sum} style={{ color: autovioColors.red }} />
        </Typography>
      ) : (
        <Typography>{listDescription}</Typography>
      )}
      <_List />
      <Row sx={{ mb: "20px", alignItems: "center" }} spacing={1}>
        <img src="/parking_lot_sign.svg" alt="" width="40" height="40" />
        <Typography>Parkplatz</Typography>
      </Row>
      <_List parkingLot />
    </>
  );
}

function _List({ parkingLot }: { parkingLot?: boolean }) {
  const drivingSchoolId = useRecoilValue(persistentGlobalDrivingSchoolFilterState);
  // I have no idea why, but without this hack, react-admin will navigate to the student details page
  // when the SendDunningNoticeDialog is closed!
  const { rowClickWrapper, preventRowClick } = usePreventRowClick();
  return (
    <List
      className={parkingLot ? "parking-lot" : undefined}
      storeKey={parkingLot ? false : undefined}
      resource={parkingLot ? "candidatesForHandoverToPairFinanceParkingLot" : "candidatesForHandoverToPairFinance"}
      title={parkingLot ? " " /* <-- prevent that the default list title is rendered */ : <BackofficeListTitle />}
      filter={parkingLot ? { ...(drivingSchoolId ? { drivingSchoolId } : {}) } : undefined}
      filters={parkingLot ? undefined : [<GlobalDrivingSchoolFilter source="drivingSchoolId" alwaysOn />]}
      sort={{ field: "balance", order: "ASC" }}
      actions={false}
      exporter={false}
      empty={false}
      pagination={parkingLot ? false : undefined}
      perPage={parkingLot ? 9999 : undefined}
      disableSyncWithLocation={parkingLot}
    >
      <Datagrid
        rowClick={rowClickWrapper((studentId) => `/students/${studentId}/invoices`)}
        bulkActionButtons={false}
        empty={parkingLot ? <_EmptyParkingLot /> : <EmptyState label="Keine passenden Fahrschüler gefunden." />}
      >
        <TextField label="Name" source="name" />
        {!drivingSchoolId && <ReferenceDrivingSchoolField label="Fahrschule" />}
        <MoneyField label="Saldo" source="balance" redForNegativeValues />
        <TextField label="Status" source="verboseStatus" />
        <NumberField
          label="genommene Theoriestunden"
          source="kpis.finishedTheoryLessonsCount"
          options={{ maximumFractionDigits: 1 }}
        />
        <NumberField
          label="genommene Fahrstunden"
          source="kpis.finishedDrivingLessonsCount"
          options={{ maximumFractionDigits: 1 }}
        />
        <MostRecentLessonField label="letzte genommene Stunde" />
        <DateField label="2. Mahnung" source="dunningProcess.secondDunningNoticeSentAt" showDate="with diff" />
        <HandoverToPairFinanceButton preventRowClick={preventRowClick} />
        {parkingLot ? <ParkOutButton /> : <ParkButton />}
      </Datagrid>
    </List>
  );
}

function _EmptyParkingLot() {
  return <div />;
}
