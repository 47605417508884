import { useEffect } from "react";

/**
 * Side effect: Updates the document title (i.e. the title of the browser tab).
 */
export function NameHeader({ children }: { children: string }) {
  useEffect(() => {
    document.title = children;
  }, []);
  return <h2 style={{ margin: 0, fontWeight: 600, lineHeight: 1.08 }}>{children}</h2>;
}
