import { FieldValues, Path } from "react-hook-form";
import { SelectInput } from "./SelectInput";

const _durationInMinutesOptions: Array<[number, string]> = [
  [15, "15 Minuten"],
  [30, "30 Minuten"],
  [45, "45 Minuten"],
  [60, "60 Minuten"],
  [75, "75 Minuten"],
  [90, "90 Minuten"],
  [135, "135 Minuten"],
  [180, "180 Minuten"],
  [225, "225 Minuten"],
];

export const AppointmentDurationInput = ({ source }: { source: Path<FieldValues> }) => {
  return (
    <SelectInput
      source={source}
      label="Dauer"
      options={_durationInMinutesOptions}
      validate={(value) => (value ? true : "Bitte wähle die Dauer der Fahrstunde aus.")}
    />
  );
};
