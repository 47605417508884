import { Datagrid, List, TextField, useGetRecordId } from "react-admin";
import * as React from "react";
import { EmptyState } from "../misc/EmptyState";
import { ChipsField } from "../fields/ChipsField";
import { ChangeBundleButton } from "../buttons/ChangeBundleButton";
import { ShowPricesButton } from "../buttons/ShowPricesButton";
import { grants } from "../backoffice.access_control";
import { ChangeDrivingLicenseClassButton } from "../buttons/ChangeDrivingLicenseClassButton";

export function StudentBookedQuotesList() {
  const studentId = useGetRecordId();
  return (
    <List
      resource="bookedQuotes"
      filter={{ studentId }}
      sort={{ field: "name", order: "ASC" }}
      exporter={false}
      pagination={false}
    >
      <Datagrid bulkActionButtons={false} empty={<EmptyState label="Noch keine Pakete gebucht." />}>
        <TextField label="Name" source="bundleName" />
        <ChipsField label="Führerscheinklasse(n)" source="drivingLicenseClasses" />
        <ShowPricesButton />
        {grants.includes("changeBundle") ? <ChangeBundleButton /> : <ChangeDrivingLicenseClassButton />}
      </Datagrid>
    </List>
  );
}
