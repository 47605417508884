import { t } from "./types";
import { AttachmentSchema } from "./Attachment";
import { z } from "zod";
import { htmlToPlainText } from "../backoffice.utils";
import { schemas } from "../generated/serverClient";

export const StudentNoteSchema = t
  .object({
    studentUid: t.uid(),
    uid: t.string().nonempty(),
    hubspotId: t.string().nonempty(),
    createdAt: t.dateTime(),
    createdByUid: t.string().optional(),
    updatedAt: t.dateTime(),
    updatedByUid: t.string().optional(),
    body: t.string().nullish(), // <-- might be empty (there are notes with empty body and only attachments)
    attachments: t.optional(t.array(AttachmentSchema)).transform((val) => (val?.length ? val : undefined)),
    createdByName: t.optional(t.string()),
    updatedByName: t.optional(t.string()),
  })
  .transform((val) => {
    const { uid: id, body, ...rest } = val;
    return { id, body: body ? htmlToPlainText(body) : body, ...rest };
  });

export const ServerAttachmentSchema = schemas.AttachmentDto.extend({
  createdAt: t.dateTime(),
  updatedAt: t.dateTime(),
});

export const DrivingSchoolNoteSchema = schemas.NoteDto.extend({
  createdAt: t.dateTime(),
  updatedAt: t.dateTime(),
  attachments: z.array(ServerAttachmentSchema).optional(),
}).transform((val) => {
  const { body, ...rest } = val;
  return { body: body ? htmlToPlainText(body) : body, ...rest };
});
export type DrivingSchoolNote = z.infer<typeof DrivingSchoolNoteSchema>;
export type StudentNote = z.infer<typeof StudentNoteSchema>;

export function isStudentNote(note: any): note is StudentNote {
  if (typeof note.studentUid === "string") {
    return true;
  }
  return false;
}

export function isDrivingSchoolNote(note: any): note is DrivingSchoolNote {
  if (typeof note.type === "string" && note.type === "DRIVING_SCHOOL") {
    return true;
  }
  return false;
}
