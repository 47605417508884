import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { DrivingLesson } from "../../model/autovioCalendarEvents";
import { autovioCalendarEventConverter } from "./autovioCalendarEventConverter";

export const drivingLessonConverter = {
  toFirestore: (_: DrivingLesson): DocumentData => {
    throw new Error("Not implemented");
  },

  fromFirestore: (doc: DocumentSnapshot): DrivingLesson => {
    const result = autovioCalendarEventConverter.fromFirestore(doc);
    if (result.type === "DrivingLesson") {
      return result;
    }
    throw Error(`Document ${doc.id} is not a driving lesson`);
  },
};
