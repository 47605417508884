import { AbstractProvider } from "./abstractProvider";
import { GetListParams, GetListResult, SortPayload } from "react-admin";
import { getAuthenticatedBackendClient } from "../api/backend.api";
import { PerformanceOverviewEntry, PerformanceOverviewEntryEndpoint } from "../generated/backendClient";
import { DateTime } from "luxon";
import { Money, MoneySchema } from "../model/Money";
import { z } from "zod";
import {
  type AutovioPayoutItemRecord,
  autovioPayoutItemToAutovioPayoutItemRecord,
} from "./autovioPayoutItemProvider.js";

const PaymentStatusEnum = z.enum([
  "UNPAID",
  "AWAITING_PAYMENT",
  "PAID",
  "PAYMENT_REVOKED",
  "PARTIALLY_REFUNDED",
  "REFUNDED",
  "UNKNOWN",
  "VOIDED",
]);

export type PaymentStatus = z.infer<typeof PaymentStatusEnum>;

export interface PerformanceOverviewRecord {
  id: string;
  studentId: string;
  instructorId: string;
  drivingSchoolId: string;
  mainItemQuantity: number;
  mainItemSinglePriceGross: Money;
  mainItemTotalPriceNet: Money;
  mainItemTotalPriceGross: Money;
  totalCreditsAmountUsed: Money;
  prepaidCreditsAmountUsed: Money;
  invoicePaidAmount: Money;
  invoiceId: string;
  invoiceNumber: string;
  creditNoteNumber: string | null;
  creditNoteId: string | null | undefined;
  b2bInvoiceNumber: string | null;
  b2bInvoiceId: string | null | undefined;
  b2bCreditNoteNumber: string | null;
  b2bCreditNoteId: string | null | undefined;
  description: string;
  serviceAt: DateTime;
  invoiceTotalGross: Money;
  invoiceTotalNet: Money;
  valueAddedTax?: number;
  partnerTurnoverAmountGross?: Money;
  partnerTurnoverAmountNet?: Money;
  compensationGross?: Money;
  compensationNet?: Money;
  autovioTakeRate?: number;
  applicationFeeAmountGross?: Money;
  applicationFeeAmountNet?: Money;
  paymentStatus: PaymentStatus;
  rawEntry: PerformanceOverviewEntry;
  autovioPayoutIds: Array<string>;
  autovioPayoutItems: AutovioPayoutItemRecord[];
}

export interface PerformanceOverviewStats {
  turnoverGross?: Money;
  turnoverNet?: Money;
  partnerTurnoverGross?: Money;
  partnerTurnoverNet?: Money;
}

class PerformanceOverviewProvider extends AbstractProvider<PerformanceOverviewRecord> {
  async getList(
    resource: "performanceOverview" | "advancePayments" | "openInvoices",
    { filter, sort, pagination }: GetListParams,
  ): Promise<GetListResult<PerformanceOverviewRecord>> {
    const { drivingSchoolId, instructorId, studentId, month, payout, paymentStatus, ...restFilter } = filter;
    const ordering = _sortToOrdering(sort);
    const { page, perPage } = pagination;
    if (!drivingSchoolId) {
      throw new Error(`Unexpected filter: ${JSON.stringify(filter)} -- expected: {"drivingSchoolId": ..., ...}`);
    }
    const unexpectedFilterKeys = Object.keys(restFilter);
    if (unexpectedFilterKeys.length === 1) {
      throw new Error(`Unexpected filter property: ${unexpectedFilterKeys[0]}`);
    } else if (unexpectedFilterKeys.length > 1) {
      throw new Error(`Unexpected filter properties: ${unexpectedFilterKeys.join(", ")}`);
    }
    if (!(month || studentId || resource === "openInvoices")) {
      // Neither month nor studentId filter is set. This happens when the filter is reset
      // on the DrivingSchoolPerformanceOverview. The `_EmptyState` component defined in
      // PerformanceOverview.tsx will set the month filter to the current month
      // in an useEffect callback, which will trigger a new request to the backend. We return
      // an empty list here, so nothing is displayed while the month filter is unspecified.
      return { data: [], total: 0 };
    }
    const backendClient = await getAuthenticatedBackendClient();
    type performanceOverviewListParams = Parameters<
      PerformanceOverviewEntryEndpoint["performanceOverviewEntryList"]
    >[0];
    const options: performanceOverviewListParams = {
      companyDrivingSchoolId: drivingSchoolId,
      ...(instructorId ? { instructorIdentifier: instructorId } : {}),
      ...(month ? { serviceTime: month } : {}),
      ...(studentId ? { studentIdentifier: studentId } : {}),
      ...(payout ? { autovioPayoutsId: payout } : {}),
      ...(paymentStatus ? { paymentState: paymentStatus.join(",") } : {}),
      ...(resource === "performanceOverview" ? { tableType: "services" } : {}),
      ...(resource === "advancePayments" ? { tableType: "prepaid" } : {}),
      ...(resource === "openInvoices" ? { tableType: "unpaid" } : {}),
      ordering,
      offset: (page - 1) * perPage,
      limit: perPage,
    };
    // If we have a student or payout filter set, we remove the month filter
    if (studentId || payout) delete options["serviceTime"];
    // If we are looking at the performanceOverview, but we also have a payout filter, we do want to see all entries
    if (payout && resource === "performanceOverview") delete options["tableType"];
    const paginatedList = await backendClient.performanceOverviewEntry.performanceOverviewEntryList(options);
    const data = paginatedList.results.map(_performanceOverviewEntryToPerformanceOverviewRecord);
    const total = paginatedList.count;
    const stats: PerformanceOverviewStats = {
      turnoverGross: _safeParseMoney(paginatedList.stats?.invoice_total),
      turnoverNet: _safeParseMoney(paginatedList.stats?.invoice_total_net),
      partnerTurnoverGross: _safeParseMoney(paginatedList.stats?.partner_turnover_amount),
      partnerTurnoverNet: _safeParseMoney(paginatedList.stats?.partner_turnover_amount_net),
    };
    // Hack: we save the stats in pageInfo.hasNextPage -- we can not add it to the data array,
    // because data is cloned by react-query and during that cloning data.stats would be lost.
    return { data, total, pageInfo: { hasPreviousPage: page > 1, hasNextPage: stats } as any };
  }
}

export const performanceOverviewProvider = new PerformanceOverviewProvider();

function _performanceOverviewEntryToPerformanceOverviewRecord(
  entry: PerformanceOverviewEntry,
): PerformanceOverviewRecord {
  if (!entry.id) {
    throw new Error("entry has no id");
  }
  const paymentStatusSafeParseResult = PaymentStatusEnum.safeParse(entry.payment_state);
  const paymentStatus = paymentStatusSafeParseResult.success ? paymentStatusSafeParseResult.data : "UNKNOWN";
  let description = entry.main_item_description;
  // Fix bad backend data ...
  if (description === "Fahrprüfung" && _safeParseFloat(entry.main_item_vat_percentage) === 0) {
    description = "Prüfungsgebühr (TÜV/DEKRA)";
  }
  return {
    id: entry.id,
    studentId: entry.student_identifier ?? "",
    instructorId: entry.instructor_identifier ?? "",
    drivingSchoolId: entry.driving_school_identifier ?? "",
    invoiceId: entry.invoice!,
    invoiceNumber: entry.invoice_number,
    creditNoteNumber: entry.credit_note_number,
    creditNoteId: entry.credit_note,
    b2bInvoiceNumber: entry.b2b_invoice_number,
    b2bInvoiceId: entry.invoice_b2b,
    b2bCreditNoteNumber: entry.b2b_credit_note_number,
    b2bCreditNoteId: entry.credit_note_b2b,
    description,
    serviceAt: DateTime.fromISO(entry.service_at),
    invoiceTotalGross: MoneySchema.parse(entry.invoice_total),
    invoiceTotalNet: MoneySchema.parse(entry.invoice_total_net),
    paymentStatus,
    valueAddedTax: _safeParseFloat(entry.main_item_vat_percentage),
    partnerTurnoverAmountGross: _safeParseMoney(entry.partner_turnover_amount),
    partnerTurnoverAmountNet: _safeParseMoney(entry.partner_turnover_amount_net),
    compensationGross: _safeParseMoney(entry.compensation),
    compensationNet: _safeParseMoney(entry.compensation_net),
    autovioTakeRate: _safeParseFloat(entry.applied_application_fee_percentage),
    applicationFeeAmountGross: _safeParseMoney(entry.applied_application_fee_amount),
    applicationFeeAmountNet: _safeParseMoney(entry.applied_application_fee_amount_net),
    rawEntry: entry,
    mainItemQuantity: entry.main_item_quantity,
    mainItemSinglePriceGross: MoneySchema.parse(entry.main_item_single_price_gross),
    mainItemTotalPriceGross: MoneySchema.parse(entry.main_item_total_price_gross),
    mainItemTotalPriceNet: MoneySchema.parse(entry.main_item_total_price_net),
    totalCreditsAmountUsed: MoneySchema.parse(entry.total_credits_amount_used),
    prepaidCreditsAmountUsed: MoneySchema.parse(entry.prepaid_credits_amount_used),
    invoicePaidAmount: MoneySchema.parse(entry.invoice_paid_amount),
    autovioPayoutIds: entry.autovio_payouts ?? [],
    autovioPayoutItems: (entry.payout_items ?? []).map(autovioPayoutItemToAutovioPayoutItemRecord),
  };
}

function _safeParseFloat(x: any): undefined | number {
  try {
    const n = typeof x === "number" ? x : parseFloat(x.toString());
    return isFinite(n) ? n : undefined;
  } catch {
    return undefined;
  }
}

function _safeParseMoney(x: any): undefined | Money {
  try {
    return MoneySchema.parse(x);
  } catch {
    return undefined;
  }
}

type Ordering = Parameters<PerformanceOverviewEntryEndpoint["performanceOverviewEntryList"]>[0]["ordering"];

function _sortToOrdering(sort: SortPayload): Ordering {
  return `${sort.order === "ASC" ? "" : "-"}${_sortFieldToOrdering(sort.field)}` as Ordering;
}

function _sortFieldToOrdering(field: string): Ordering {
  switch (field) {
    case "id":
    case "serviceAt":
      return "service_at";
    case "paymentStatus":
      return "payment_state";
    case "instructorId":
      return "instructor_identifier";
    case "studentId":
      return "student_identifier";
    case "invoiceNumber":
      return "invoice_number";
    case "description":
      return "main_item_description";
    case "mainItemQuantity":
      return "main_item_quantity";
    case "mainItemSinglePriceGross":
      return "main_item_single_price_gross";
    case "mainItemTotalPriceGross":
      return "main_item_total_price_gross";
    case "totalCreditsAmountUsed":
      return "total_credits_amount_used";
    case "prepaidCreditsAmountUsed":
      return "prepaid_credits_amount_used";
    case "invoicePaidAmount":
      return "invoice_paid_amount";
    case "invoiceTotalGross":
      return "invoice_total";
    case "valueAddedTax":
      return "main_item_vat_percentage";
    case "invoiceTotalNet":
      return "invoice_total_net";
    case "applicationFeeAmountNet":
      return "applied_application_fee_amount_net";
    case "applicationFeeAmountGross":
      return "applied_application_fee_amount";
    case "partnerTurnoverAmountNet":
      return "partner_turnover_amount_net";
    case "partnerTurnoverAmountGross":
      return "partner_turnover_amount";
    case "compensationGross":
      return "compensation";
    case "compensationNet":
      return "compensation_net";
    case "autovioTakeRate":
      return "applied_application_fee_percentage";
    default:
      throw new Error(`Unexpected field: ${field}`);
  }
}
