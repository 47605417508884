export function KPIVouchersIcon() {
  return (
    <svg width="15" height="12" viewBox="0 0 15 12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m5.4 9 2.1-1.575L9.563 9l-.788-2.55 2.1-1.65h-2.55L7.5 2.25 6.675 4.8h-2.55l2.063 1.65L5.4 9zm-3.9 3c-.413 0-.766-.147-1.06-.44A1.444 1.444 0 0 1 0 10.5V7.969c0-.138.044-.256.131-.357a.57.57 0 0 1 .338-.187c.3-.1.547-.281.74-.544.194-.262.291-.556.291-.881 0-.325-.097-.619-.29-.881a1.488 1.488 0 0 0-.741-.544.57.57 0 0 1-.338-.188A.523.523 0 0 1 0 4.032V1.5C0 1.087.147.734.44.44.735.148 1.088 0 1.5 0h12c.412 0 .766.147 1.06.44.293.294.44.647.44 1.06v2.531a.523.523 0 0 1-.131.357.57.57 0 0 1-.338.187c-.3.1-.547.281-.74.544-.194.262-.291.556-.291.881 0 .325.097.619.29.881.194.263.441.444.741.544a.57.57 0 0 1 .338.188c.087.1.131.218.131.356V10.5c0 .412-.147.766-.44 1.06-.294.293-.648.44-1.06.44h-12zm0-1.5h12V8.588a3.075 3.075 0 0 1-1.097-1.097A2.885 2.885 0 0 1 12 6c0-.537.134-1.034.403-1.49A3.075 3.075 0 0 1 13.5 3.412V1.5h-12v1.913c.462.275.828.64 1.097 1.096C2.866 4.966 3 5.463 3 6c0 .537-.134 1.034-.403 1.49A3.075 3.075 0 0 1 1.5 8.589V10.5z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </svg>
  );
}
