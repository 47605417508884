import {
  AppBar as MuiAppBar,
  Avatar,
  Box,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  PopoverOrigin,
  TextField,
  Typography,
} from "@mui/material";
import {
  MenuItemLink,
  useCreatePath,
  useGetIdentity,
  useGetList,
  useGetResourceLabel,
  useNotify,
  useRefresh,
  useResourceDefinitions,
  UserMenuContext,
} from "react-admin";
import GlobalSearchBox from "./GlobalSearchBox";
import * as React from "react";
import { MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { autovioColors, backofficeTheme } from "./backofficeTheme";
import { ArrowLeftIcon } from "@mui/x-date-pickers";
import { DrivingSchool } from "../providers/drivingSchoolsProvider";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation, useNavigate } from "react-router-dom";
import { currentUser, grants, restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { DrivingSchoolIcon } from "../icons/DrivingSchoolIcon";
import CopyToClipboardIcon from "@mui/icons-material/ContentCopy";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import { recordDataSnapshot } from "../providers/compositeDataProvider";
import Divider from "@mui/material/Divider";
import { useLogout } from "react-admin";

const { black, grey, borderGrey, red } = autovioColors;

export function BackofficeAppBar() {
  const [autovioContext, setAutovioContext] = useAutovioContext();
  const { pathname } = useLocation();
  const hideInUserMenu = ["drivingSchools", "instructors", "students", "vehicles"];
  if (restrictAccessToDrivingSchoolIds?.length === 1) {
    hideInUserMenu.push("bi");
  }
  // Make sure the drivingSchoolId in the autovioContext is always in sync with the URL ...
  useEffect(() => {
    const match = /\/drivingSchools\/([^/]+)/.exec(pathname);
    if (match) {
      const drivingSchoolId = match[1];
      if (drivingSchoolId !== autovioContext.drivingSchoolId) {
        setAutovioContext({ drivingSchoolId });
      }
    }
  }, [autovioContext.drivingSchoolId, pathname]);
  return (
    <MuiAppBar
      sx={{
        borderBottom: `1px solid ${borderGrey}`,
        height: "100px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#f8f8f8",
        flexDirection: "row",
        paddingLeft: "40px",
        paddingRight: "45px",
      }}
    >
      <DrivingSchoolMenu />
      <div id="global-search-box">
        <GlobalSearchBox />
      </div>
      <UserMenu exclude={hideInUserMenu} />
    </MuiAppBar>
  );
}

const userMenuAnchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

const userMenuTransformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

const UserMenu = (props: { exclude: string[] }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { identity } = useGetIdentity();
  const resources = useResourceDefinitions();
  const getResourceLabel = useGetResourceLabel();
  const createPath = useCreatePath();
  const openMenu: MouseEventHandler<HTMLElement> = (event) => setAnchorEl(event.currentTarget);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const userMenuContext = useMemo(() => ({ onClose: closeMenu }), [closeMenu]);

  return (
    <div id="user-menu">
      <List component="nav" sx={{ background: "transparent" }}>
        <ListItemButton
          id="UserMenuButton"
          sx={{ borderRadius: "8px" }}
          aria-haspopup="listbox"
          aria-controls="UserMenu"
          onClick={openMenu}
        >
          <Avatar sx={{ ml: "4px", width: "44px", height: "44px", mr: "16px" }} src={identity?.avatar ?? ""} alt="" />
          <Typography sx={{ color: black, fontWeight: "600", lineHeight: "normal", fontSize: "15px" }}>
            {identity?.fullName}
          </Typography>
          <ArrowLeftIcon
            sx={{
              transform: anchorEl ? "rotate(90deg)" : "rotate(270deg)",
              fontSize: 20,
              color: black,
              marginLeft: 0.5,
            }}
          />
        </ListItemButton>
      </List>
      <UserMenuContext.Provider value={userMenuContext}>
        <Menu
          id="UserMenu"
          disableScrollLock
          anchorEl={anchorEl}
          anchorOrigin={userMenuAnchorOrigin}
          transformOrigin={userMenuTransformOrigin}
          open={!!anchorEl}
          onClose={closeMenu}
        >
          {Object.keys(resources)
            .filter((name) => resources[name].hasList)
            .filter((name) => !props.exclude.includes(name))
            .map((name) => (
              <MenuItemLink
                key={name}
                to={createPath({ resource: name, type: "list" })}
                primaryText={getResourceLabel(name, 2)}
                sx={{ color: autovioColors.black }}
                onClick={closeMenu}
              />
            ))}
          {grants.includes("createDataSnapshotForVisualTest") && <CreateDataSnapshotMenuItem closeMenu={closeMenu} />}
          {grants.includes("copyIdTokenToClipboard") && <CopyIdTokenMenuItem closeMenu={closeMenu} />}
          <LogoutMenuItem />
        </Menu>
      </UserMenuContext.Provider>
    </div>
  );
};

function _renderSchoolAddress(drivingSchool: DrivingSchool) {
  const { postalAddress: address } = drivingSchool;
  if (!address) return "";
  return `${address.street}, ${address.postalCode} ${address.city}`;
}

const DrivingSchoolMenu = () => {
  const [autovioContext, setAutovioContext] = useAutovioContext();
  const { data: drivingSchools } = useGetList<DrivingSchool>("drivingSchools", {
    pagination: { page: 1, perPage: 999 },
    sort: { field: "name", order: "ASC" },
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filterValue, setFilterValue] = useState<string>("");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const open = !!anchorEl;
  const openMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);
  const handleMenuItemClick = (option?: DrivingSchool) => {
    if (!option) {
      setAutovioContext({ drivingSchoolId: undefined });
      navigate("/");
    } else {
      if (option.id !== autovioContext.drivingSchoolId) {
        setAutovioContext({ drivingSchoolId: option.id });
      }
      const match = /\/drivingSchools\/([^/]+)\/([^/]+)/.exec(pathname);
      if (match) {
        navigate(`/drivingSchools/${option.id}/${match[2]}`);
      } else if (pathname.startsWith("/instructors")) {
        navigate(`/drivingSchools/${option.id}/instructors`);
      } else if (pathname.startsWith("/students")) {
        navigate(`/drivingSchools/${option.id}/students`);
      } else if (pathname.startsWith("/vehicles")) {
        navigate(`/drivingSchools/${option.id}/fleet`);
      } else {
        navigate("/");
      }
    }
    setFilterValue("");
    closeMenu();
  };
  const currentDrivingSchool = useMemo(
    () => drivingSchools?.find(({ id }) => id === autovioContext.drivingSchoolId),
    [drivingSchools, autovioContext.drivingSchoolId],
  );
  const filteredDrivingSchools = useMemo(
    () =>
      drivingSchools?.filter(
        ({ name, postalAddress }) =>
          name?.toLowerCase().includes(filterValue) || postalAddress?.city?.toLowerCase()?.includes(filterValue),
      ),
    [drivingSchools, filterValue],
  );

  return (
    <div id="driving-school-menu">
      <List component="nav" sx={{ background: "transparent" }}>
        <ListItemButton
          id="DrivingSchoolMenuButton"
          sx={{ borderRadius: "8px" }}
          aria-haspopup="listbox"
          aria-controls="DrivingSchoolMenu"
          aria-expanded={open ? "true" : undefined}
          onClick={openMenu}
        >
          {currentDrivingSchool ? (
            <DrivingSchoolLogo size={44} logoUrl={currentDrivingSchool.logoUrl} />
          ) : (
            <img src="/AUTOVIO_logo_green.svg" width="44" height="44" alt="" />
          )}
          <Box>
            <Box sx={{ ml: "10px", display: "flex", alignItems: "center", gap: 0.5, fontWeight: 600 }}>
              <ListItemText
                sx={{ color: black }}
                primaryTypographyProps={{
                  sx: { fontWeight: 600, fontSize: 15 },
                }}
                primary={currentDrivingSchool ? currentDrivingSchool.name : "Alle Fahrschulen"}
              />
              {restrictAccessToDrivingSchoolIds?.length !== 1 ? (
                <ArrowLeftIcon
                  sx={{
                    transform: anchorEl ? "rotate(90deg)" : "rotate(270deg)",
                    fontSize: 20,
                    color: black,
                  }}
                />
              ) : null}
            </Box>
            <Typography sx={{ ml: "10px", color: grey, marginTop: -0.7, fontSize: 14 }}>
              {currentDrivingSchool ? _renderSchoolAddress(currentDrivingSchool) : "Ganz Deutschland"}
            </Typography>
          </Box>
        </ListItemButton>
      </List>
      {restrictAccessToDrivingSchoolIds?.length !== 1 ? (
        <Menu
          id="DrivingSchoolMenu"
          anchorEl={anchorEl}
          open={open}
          onClose={closeMenu}
          MenuListProps={{
            "aria-labelledby": "DrivingSchoolMenuButton",
            role: "listbox",
          }}
          slotProps={{
            paper: {
              sx: { boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.1) !important", minWidth: 380, paddingBottom: "12px" },
            },
          }}
          autoFocus={false}
          disableAutoFocusItem
        >
          <div style={{ padding: "16px" }}>
            <TextField
              sx={{ borderRadius: 8, width: "100%" }}
              size="small"
              placeholder="Suche"
              value={filterValue}
              onChange={(event) => setFilterValue(event.target.value)}
              onKeyDown={(event) => {
                // Only pass through arrow down and arrow up key events to the <Menu> component ...
                if (event.key !== "ArrowDown" && event.key !== "ArrowUp") {
                  event.stopPropagation();
                }
                // If the user presses Enter and there is only one driving school in the search result, select it ...
                if (event.key === "Enter" && filteredDrivingSchools?.length === 1) {
                  handleMenuItemClick(filteredDrivingSchools[0]);
                }
              }}
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end" sx={{ marginLeft: -0.5, paddingRight: 1 }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          {drivingSchools && drivingSchools?.length > 1 && !filterValue ? (
            <div>
              <MenuItem
                selected={!currentDrivingSchool?.id}
                onClick={() => handleMenuItemClick()}
                sx={{
                  ...backofficeTheme.components.MuiMenuItem,
                }}
              >
                <img src="/AUTOVIO_logo_green.svg" width="32" height="32" alt="" />
                <Box sx={{ ml: "10px" }}>
                  <Typography sx={{ fontSize: 14 }}>Alle Fahrschulen</Typography>
                  <Typography sx={{ color: grey, fontSize: 12 }}>Ganz Deutschland</Typography>
                </Box>
              </MenuItem>
              <Divider />
            </div>
          ) : null}

          {(filteredDrivingSchools || []).map((option, index) => {
            const isSelected =
              option.id === currentDrivingSchool?.id ||
              (filteredDrivingSchools?.length === 1 && filteredDrivingSchools[0].id === option.id);
            return (
              <MenuItem
                key={index}
                selected={isSelected}
                onClick={() => handleMenuItemClick(option)}
                sx={backofficeTheme.components.MuiMenuItem}
              >
                <DrivingSchoolLogo logoUrl={option?.logoUrl || ""} size={32} />
                <Box sx={{ ml: "10px" }}>
                  <Typography sx={{ fontSize: 14 }}>{option.name}</Typography>
                  <Typography sx={{ color: grey, fontSize: 12 }}>{_renderSchoolAddress(option)}</Typography>
                </Box>
              </MenuItem>
            );
          })}
        </Menu>
      ) : null}
    </div>
  );
};

function DrivingSchoolLogo({ size = 24, logoUrl }: { size?: 24 | 32 | 44; logoUrl?: string }) {
  if (!logoUrl) {
    return <DrivingSchoolIcon size={size} />;
  }
  return <img src={logoUrl} style={{ width: size, height: size }} alt="" />;
}

function CreateDataSnapshotMenuItem({ closeMenu }: { closeMenu: () => void }) {
  const refresh = useRefresh();

  return (
    <MenuItem
      onClick={async () => {
        closeMenu();
        void (async () => {
          try {
            const snapshot = await recordDataSnapshot(refresh);
            const { serializeDataSnapshot } = await import("../backoffice.test_utils");
            const blob = new Blob([serializeDataSnapshot(snapshot)], { type: "application/json" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute(
              "download",
              new URL(window.location.href).pathname.substring(1).replaceAll("/", "-") + "-data-snapshot.json",
            );
            link.dispatchEvent(new MouseEvent("click"));
            URL.revokeObjectURL(url);
          } catch (error) {
            console.error("Failed to create data snapshot", error);
          }
        })();
      }}
    >
      <ListItemIcon>
        <CameraIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Erstelle Daten-Snapshot für visuellen Test</ListItemText>
    </MenuItem>
  );
}

function CopyIdTokenMenuItem({ closeMenu }: { closeMenu: () => void }) {
  const notify = useNotify();

  return (
    <MenuItem
      onClick={async () => {
        const idToken = await currentUser!.getIdToken();
        const permissionStatus = await navigator.permissions.query({ name: "clipboard-write" as any });
        if (permissionStatus.state === "granted" || permissionStatus.state === "prompt") {
          await navigator.clipboard.writeText(idToken);
          notify("ID-Token in die Zwischenablage kopiert.", { type: "success" });
        } else {
          notify("Kopieren in die Zwischenablage ist nicht erlaubt.", { type: "error" });
        }
        closeMenu();
      }}
    >
      <ListItemIcon>
        <CopyToClipboardIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Kopiere ID-Token in die Zwischenablage</ListItemText>
    </MenuItem>
  );
}

function LogoutMenuItem() {
  const logout = useLogout();
  return (
    <MenuItem onClick={logout}>
      <ListItemText sx={{ color: red }}>Abmelden</ListItemText>
    </MenuItem>
  );
}
