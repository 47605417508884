import { auth } from "../firebase";
import { BackendClient, DunningProcess, EmailDraft, ManualInvoice } from "../generated/backendClient";
import { Student, studentsProvider } from "../providers/studentsProvider";
import { QueryClient } from "react-query";

const hostname = location.hostname;
const useLocalBackend = import.meta.env.VITE_USE_LOCAL_BACKEND || false; // needs a browser with CORS disabled to work

export const BACKEND_BASE_URL = useLocalBackend
  ? "http://localhost:8000"
  : hostname === "localhost" || hostname.indexOf("dev") > 0
  ? "https://backend.autovio.dev"
  : `https://backend.autovio.de`;

export async function getAuthenticatedBackendClient() {
  const jwt = await auth.currentUser?.getIdToken();
  return new BackendClient({ BASE: `${BACKEND_BASE_URL}/api`, TOKEN: jwt });
}

export async function createManualInvoice(data: ManualInvoice): Promise<void> {
  const backendClient = await getAuthenticatedBackendClient();
  await backendClient.order.orderManualInvoiceCreate({ requestBody: data });
}

export async function draftDunningNotice(
  student: Student,
  noticeType: "student_blocked" | "first_dunning_notice",
): Promise<{ dunningProcess: DunningProcess; draft: EmailDraft }>;
export async function draftDunningNotice(
  context: Student | DunningProcess,
  noticeType: "second_dunning_notice" | "inkasso_notice",
): Promise<{ dunningProcess: DunningProcess; draft: EmailDraft }>;
export async function draftDunningNotice(
  context: Student | DunningProcess,
  noticeType: "student_blocked" | "first_dunning_notice" | "second_dunning_notice" | "inkasso_notice",
): Promise<{ dunningProcess: DunningProcess; draft: EmailDraft }> {
  const backendClient = await getAuthenticatedBackendClient();
  let dunningProcess: DunningProcess;
  if (noticeType === "second_dunning_notice" || noticeType === "inkasso_notice") {
    dunningProcess = await backendClient.dunningProcess.dunningProcessRetrieve({
      id: isDunningProcess(context) ? context.id : context.dunningProcess!.id,
    });
  } else {
    dunningProcess = await backendClient.dunningProcess.dunningProcessPrepareForDraftRetrieve({
      studentId: (context as Student).autovioUserId,
    });
  }
  const draft = await backendClient.dunningProcess.dunningProcessDraftRetrieve({ id: dunningProcess.id, noticeType });
  return { dunningProcess, draft };
}

export async function updateDunningProcess(
  student: Student,
  data: {
    paused: boolean;
  },
  queryClient: QueryClient,
): Promise<void> {
  if (!student.dunningProcess) {
    throw new Error("student has no dunning process");
  }
  if (student.dunningProcess.paused === data.paused) {
    throw new Error(`student.dunningProcess.paused is already set to ${data.paused}`);
  }
  const updateReceived = new Promise<void>((resolve) => {
    const unsubscribe = studentsProvider.onUpdate(student, (student) => {
      if (student.dunningProcess?.paused === data.paused) {
        unsubscribe();
        resolve();
      }
    });
  });
  const backendClient = await getAuthenticatedBackendClient();
  await backendClient.dunningProcess.dunningProcessPartialUpdate({ id: student.dunningProcess.id, requestBody: data });
  await updateReceived;
  await queryClient.invalidateQueries(["students"]);
  await queryClient.invalidateQueries(["studentNotes"]);
  await queryClient.invalidateQueries(["studentsWithNegativeBalance"]);
  await queryClient.invalidateQueries(["candidatesForBlocking"]);
  await queryClient.invalidateQueries(["candidatesForFirstDunningNotice"]);
  await queryClient.invalidateQueries(["candidatesForFirstDunningNoticeParkingLot"]);
  await queryClient.invalidateQueries(["candidatesForSecondDunningNotice"]);
  await queryClient.invalidateQueries(["candidatesForSecondDunningNoticeParkingLot"]);
  await queryClient.invalidateQueries(["candidatesForHandoverToPairFinance"]);
  await queryClient.invalidateQueries(["candidatesForHandoverToPairFinanceParkingLot"]);
  await queryClient.invalidateQueries(["studentsHandedOverToPairFinance"]);
  await queryClient.invalidateQueries(["candidatesForReactivation"]);
}

function isDunningProcess(x: any): x is DunningProcess {
  if (x && typeof x === "object") {
    const { id, student, invoices } = x;
    return typeof id === "string" && typeof student === "string" && Array.isArray(invoices);
  }
  return false;
}
