import DunningIcon from "@mui/icons-material/CreditCardOff";
import { Box, Card, Divider, Tab, Tabs } from "@mui/material";
import { Resource, useStore } from "react-admin";
import { ReactNode, useEffect } from "react";
import { CandidatesForBlockingList } from "../lists/dunning/CandidatesForBlockingList";
import { CandidatesForReactivationList } from "../lists/dunning/CandidatesForReactivationList";
import { CandidatesForFirstDunningNoticeList } from "../lists/dunning/CandidatesForFirstDunningNoticeList";
import { useLocation, useNavigate } from "react-router";
import { CandidatesForSecondDunningNoticeList } from "../lists/dunning/CandidatesForSecondDunningNoticeList";
import { CandidatesForHandoverToPairFinanceList } from "../lists/dunning/CandidatesForHandoverToPairFinanceList";
import { StudentsHandedOverToPairFinance } from "../lists/dunning/StudentsHandedOverToPairFinance";

function DunningPage() {
  const [lastActiveTab, setLastActiveTab] = useStore("dunning.activeTab", 0);
  const path = useLocation().pathname;
  const activeTab = (parseInt((/tab([123456])/.exec(path) ?? ["", "0"])[1]) || lastActiveTab || 1) - 1;
  const navigate = useNavigate();
  useEffect(() => {
    if (activeTab + 1 !== lastActiveTab) {
      setLastActiveTab(activeTab + 1);
    }
    if (!path.includes("/tab")) {
      // Ensure that back navigation works correctly ...
      navigate(`/dunning/tab${activeTab + 1}`, { replace: true });
    }
  }, [path, lastActiveTab, activeTab]);
  return (
    <div className="dunning">
      <Card sx={{ marginTop: "20px", paddingTop: "20px", paddingLeft: "20px", paddingBottom: "20px" }}>
        <Tabs
          value={activeTab}
          onChange={(_, selectedTab) => navigate(`/dunning/tab${selectedTab + 1}`, { replace: true })}
          sx={{ "& .MuiTabs-indicator": { display: "none" } }}
        >
          <Tab label="Blockieren?" />
          <Tab label="1. Mahnung?" />
          <Tab label="2. Mahnung?" />
          <Tab label="Inkasso?" />
          <Tab label="Inkasso!" />
          <Tab label="Freischalten?" />
        </Tabs>
        <Divider sx={{ marginLeft: "-20px" }} />
        <TabPanel visible={activeTab === 0}>
          <CandidatesForBlockingList />
        </TabPanel>
        <TabPanel visible={activeTab === 1}>
          <CandidatesForFirstDunningNoticeList />
        </TabPanel>
        <TabPanel visible={activeTab === 2}>
          <CandidatesForSecondDunningNoticeList />
        </TabPanel>
        <TabPanel visible={activeTab === 3}>
          <CandidatesForHandoverToPairFinanceList />
        </TabPanel>
        <TabPanel visible={activeTab === 4}>
          <StudentsHandedOverToPairFinance />
        </TabPanel>
        <TabPanel visible={activeTab === 5}>
          <CandidatesForReactivationList />
        </TabPanel>
      </Card>
    </div>
  );
}

interface TabPanelProps {
  visible: boolean;
  children?: ReactNode;
}

function TabPanel({ visible, children }: TabPanelProps) {
  return (
    <div role="tabpanel" hidden={!visible}>
      {visible && <Box sx={{ padding: "20px 20px 0 0" }}>{children}</Box>}
    </div>
  );
}

export const dunningResource = (
  <Resource key="dunning" name="dunning" icon={DunningIcon} list={DunningPage} options={{ label: "Mahnwesen" }} />
);
