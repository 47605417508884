import type { FullMetadata, UploadResult } from "@firebase/storage";

import { storage } from "../firebase";
import { getDownloadURL, getMetadata, list, ref, uploadBytes } from "firebase/storage";
import { getFileNameExtension } from "./getFileNameExtension";
import { determineMimeType } from "./determineMimeType";
import { LocalizedError } from "./LocalizedError";
import { getFileNameExtensionForMimeType } from "./getFileNameExtensionForMimeType";
import { v4 as uuid } from "uuid";
import { Attachment } from "../model/Attachment";

export type Upload = Omit<Defined<Attachment, "size">, "id" | "lastChanged">;

class GoogleCloudStorage {
  async uploadFiles(files: Array<File | { rawFile: File }>): Promise<Array<Upload>> {
    // 1. Determine and validate MIME types ...
    const mimeTypes: Array<string> = [];
    for (let i = 0; i < files.length; ++i) {
      const file = (files[i] as any).rawFile ?? (files[i] as File);
      const extension = getFileNameExtension(file.name);
      const mimeType = await determineMimeType(file);
      if (extension === "pdf" && mimeType !== "application/pdf") {
        throw new LocalizedError(`Die Datei ${file.name} ist keine gültige PDF-Datei.`);
      }
      mimeTypes[i] = mimeType || file.type || "application/octet-stream";
    }
    // 2. Upload files to Google Cloud Storage ...
    const uploads: Array<Upload> = [];
    for (let i = 0; i < files.length; ++i) {
      const file = (files[i] as any).rawFile ?? (files[i] as File);
      const mimeType = mimeTypes[i];
      let fileName = file.name;
      let extension = getFileNameExtension(file.name);
      // Add extension to file name if file name has no extension ...
      if (!extension) {
        extension = getFileNameExtensionForMimeType(mimeType);
        if (extension) {
          fileName = `${fileName}.${extension}`;
        }
      }
      uploads.push({
        name: fileName,
        path: (await gcs.uploadFile(file, `/uploads/${uuid()}/${fileName}`)).ref.fullPath,
        size: file.size,
        mimeType,
      });
    }
    return uploads;
  }

  async uploadFile(file: File, pathOrPathBuilder: string | ((ext: string) => string)): Promise<UploadResult> {
    let path: string;
    if (typeof pathOrPathBuilder === "string") {
      path = pathOrPathBuilder;
    } else {
      const i = file.name.lastIndexOf(".");
      const ext = i < 1 /* ... not i < 0! -- ext for ".hidden_file" should be: "" */ ? "" : file.name.substring(i + 1);
      path = pathOrPathBuilder(ext);
    }
    const storageRef = ref(storage, path);
    const result = await uploadBytes(storageRef, await file.arrayBuffer(), {
      contentType: file.type,
    });
    if (result.metadata.size !== file.size) {
      throw new Error("Incomplete upload");
    }
    return result;
  }

  async listFiles(path: string): Promise<Array<FullMetadata>> {
    const storageRef = ref(storage, path);
    const { items } = await list(storageRef);
    return await Promise.all(items.map(getMetadata));
  }

  getDownloadUrl(fileOrPath: FullMetadata | string): Promise<string> {
    const storageRef =
      typeof fileOrPath === "string" ? ref(storage, fileOrPath) : fileOrPath.ref ?? ref(storage, fileOrPath.fullPath);
    return getDownloadURL(storageRef);
  }
}

export const gcs = new GoogleCloudStorage();
