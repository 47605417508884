import { BackofficeListTitle } from "../../misc/BackofficeListTitle";
import { Datagrid, List, NumberField, SelectInput, TextField } from "react-admin";
import { MoneyField } from "../../fields/MoneyField";
import { EmptyState } from "../../misc/EmptyState";
import { MostRecentLessonField } from "../../fields/MostRecentLessonField";
import { Typography } from "@mui/material";
import { SendFirstDunningNoticeButton } from "../../buttons/SendFirstDunningNoticeButton";
import { ReferenceDrivingSchoolField } from "../../fields/ReferenceDrivingSchoolField";
import { Money } from "../../misc/Money";
import { autovioColors } from "../../misc/backofficeTheme";
import { usePreventRowClick } from "../../hooks/usePreventRowClick";
import { BookOutButton } from "../../buttons/BookOutButton";
import { DateField } from "../../fields/DateField";
import { GlobalDrivingSchoolFilter } from "../../misc/GlobalDrivingSchoolFilter";
import { useBalanceSum } from "../../hooks/useBalanceSum";
import { useRecoilValue } from "recoil";
import { persistentGlobalDrivingSchoolFilterState } from "../../persistent_state";
import { ParkOutButton } from "../../buttons/ParkOutButton";
import { ParkButton } from "../../buttons/ParkButton";
import { Row } from "../../misc/Row";

export function CandidatesForFirstDunningNoticeList() {
  const listDescription = "Liste aller inaktiven Fahrschüler mit negativen Saldo, die noch nicht angemahnt wurden";
  const sum = useBalanceSum("candidatesForFirstDunningNotice", "candidatesForFirstDunningNoticeParkingLot");
  return (
    <>
      {sum ? (
        <>
          <Typography>
            {`${listDescription} • Summe ausstehende Zahlungen: `}
            <Money cents={sum} style={{ color: autovioColors.red }} />
            <sup style={{ color: autovioColors.red }}>*</sup>
          </Typography>
          <Typography color={autovioColors.grey}>
            <sup>*</sup>Es wurden nur Fahrschüler berücksichtigt, die wenigstens eine Theorie- oder Fahrstunde hatten.
          </Typography>
        </>
      ) : (
        <Typography>{listDescription}</Typography>
      )}
      <_List />
      <Row sx={{ mb: "20px", alignItems: "center" }} spacing={1}>
        <img src="/parking_lot_sign.svg" alt="" width="40" height="40" />
        <Typography>Parkplatz</Typography>
      </Row>
      <_List parkingLot />
    </>
  );
}

function _List({ parkingLot }: { parkingLot?: boolean }) {
  const drivingSchoolId = useRecoilValue(persistentGlobalDrivingSchoolFilterState);
  // I have no idea why, but without this hack, react-admin will navigate to the student details page
  // when the SendDunningNoticeDialog is closed!
  const { rowClickWrapper, preventRowClick } = usePreventRowClick();
  return (
    <List
      className={parkingLot ? "parking-lot" : undefined}
      storeKey={parkingLot ? false : undefined}
      resource={parkingLot ? "candidatesForFirstDunningNoticeParkingLot" : "candidatesForFirstDunningNotice"}
      title={parkingLot ? " " /* <-- prevent that the default list title is rendered */ : <BackofficeListTitle />}
      filter={parkingLot ? { ...(drivingSchoolId ? { drivingSchoolId } : {}) } : undefined}
      filters={
        parkingLot
          ? undefined
          : [
              <GlobalDrivingSchoolFilter source="drivingSchoolId" alwaysOn />,
              <SelectInput
                label="Status"
                source="status"
                choices={[
                  { id: "cancelled", name: "Abgebrochen" },
                  { id: "inactive", name: "Inaktiv" },
                  { id: "onHold", name: "Pausiert" },
                  { id: "outstandingPayments", name: "Offene Zahlungen" },
                  { id: "completed", name: "Fertig" },
                ]}
                alwaysOn
                emptyText={<i>alle</i>}
              />,
            ]
      }
      sort={{ field: "balance", order: "ASC" }}
      actions={false}
      exporter={false}
      empty={false}
      pagination={parkingLot ? false : undefined}
      perPage={parkingLot ? 9999 : undefined}
      disableSyncWithLocation={parkingLot}
    >
      <Datagrid
        rowClick={rowClickWrapper((studentId) => `/students/${studentId}/invoices`)}
        bulkActionButtons={false}
        empty={parkingLot ? <_EmptyParkingLot /> : <EmptyState label="Keine passenden Fahrschüler gefunden." />}
      >
        <TextField label="Name" source="name" />
        {!drivingSchoolId && <ReferenceDrivingSchoolField label="Fahrschule" />}
        <MoneyField label="Saldo" source="balance" redForNegativeValues />
        <DateField label="Anmeldung" source="startDate" showDate="with diff" />
        <TextField label="Status" source="verboseStatusSinceXxxDays" />
        <NumberField
          label="genommene Theoriestunden"
          source="kpis.finishedTheoryLessonsCount"
          options={{ maximumFractionDigits: 1 }}
        />
        <NumberField
          label="genommene Fahrstunden"
          source="kpis.finishedDrivingLessonsCount"
          options={{ maximumFractionDigits: 1 }}
        />
        <MostRecentLessonField label="letzte genommene Stunde" />
        <SendFirstDunningNoticeButton preventRowClick={preventRowClick} />
        <BookOutButton preventRowClick={preventRowClick} />
        {parkingLot ? <ParkOutButton /> : <ParkButton />}
      </Datagrid>
    </List>
  );
}

function _EmptyParkingLot() {
  return <div />;
}
