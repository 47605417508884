import RetryIcon from "@mui/icons-material/Refresh";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, MenuItem, Select, Tooltip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useCallback, useState } from "react";
import { useNotify, useRefresh } from "react-admin";
import { getAuthenticatedBackendClient } from "../api/backend.api.js";
import { grants } from "../backoffice.access_control.js";
import { reportError } from "../backoffice.utils.js";
import { InvoiceStateEnum, type PaymentModeEnum } from "../generated/backendClient";
import { DialogCloseButton } from "../misc/DialogCloseButton.js";
import { MoneyCurrency } from "../misc/Money.js";
import { UserBalanceItem } from "../providers/invoicesProvider.js";
import type { Student } from "../providers/studentsProvider.js";

type _PaymentMode = PaymentModeEnum | "DEFAULT";

export function RetryPaymentButton({
  invoiceState,
  invoiceId,
  paymentAmount,
  description,
  student,
}: {
  invoiceState: InvoiceStateEnum;
  invoiceId: string;
  invoicePaymentMode?: PaymentModeEnum;
  student: Student;
  paymentAmount: UserBalanceItem["amount"];
  description: string;
}) {
  const refresh = useRefresh();
  const [retryDialogOpen, setRetryDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();
  const [paymentMode, setPaymentMode] = useState<_PaymentMode>("DEFAULT");
  const retryPayment = useCallback(async () => {
    try {
      const backendClient = await getAuthenticatedBackendClient();
      setIsLoading(true);
      await backendClient.invoice.invoiceRetryPaymentCreate({
        id: invoiceId,
        requestBody: paymentMode !== "DEFAULT" ? { payment_mode: paymentMode } : {},
      });
      setIsLoading(false);
      notify(`Die Zahlung wurde wiederholt.`, { type: "success" });
      refresh();
    } catch (error) {
      reportError(`Failed to retry payment for invoice ${invoiceId}`, error);
      notify(`Die Zahlung konnte nicht wiederholt werden.`, { type: "error" });
    } finally {
      setIsLoading(false);
      setRetryDialogOpen(false);
    }
  }, [invoiceState, invoiceId, paymentMode]);
  const paymentModeOptions: { label: string; paymentMode: _PaymentMode; selected: boolean }[] = [
    {
      label: "Standard",
      selected: true,
      paymentMode: "DEFAULT",
    },
    {
      label: "Nur mit vorausgezahltem Guthaben",
      paymentMode: "PREPAID_CREDITS_ONLY",
      selected: false,
    },
    ...(student.paymentMethod === "card" || student.paymentMethod === "sepa"
      ? [
          {
            label: "Nur mit Karte",
            paymentMode: "STRIPE_PAYMENT_INTENT" as PaymentModeEnum,
            selected: false,
          },
        ]
      : []),
  ];

  if (!["PAYMENT_REVOKED", "UNPAID", "UNPAID_WITH_PREPAID_CREDITS"].includes(invoiceState)) {
    return null;
  }

  return (
    <>
      <Tooltip title="Wiederholen">
        <Button startIcon={<RetryIcon />} onClick={() => setRetryDialogOpen(true)}></Button>
      </Tooltip>
      <Dialog open={retryDialogOpen} onClose={() => setRetryDialogOpen(false)}>
        <DialogTitle>Zahlung wiederholen?</DialogTitle>
        <DialogCloseButton onClose={() => setRetryDialogOpen(false)} disabled={isLoading} />
        <DialogContent>
          <DialogContentText>
            Die Zahlung in Höhe von{" "}
            <strong>
              <MoneyCurrency {...paymentAmount} />
            </strong>{" "}
            für <em>{description}</em> wiederholen?
          </DialogContentText>
          <DialogContentText>Die Zahlmethode des Fahrschülers wird erneut belastet.</DialogContentText>
        </DialogContent>
        <DialogActions>
          {grants.includes("retryPaymentsUsingPaymentMethod") && (
            <Select<_PaymentMode>
              onChange={({ target: { value } }) => setPaymentMode(value as _PaymentMode)}
              value={paymentMode}
              size="small"
              style={{ marginRight: 10 }}
            >
              {paymentModeOptions.map((action) => (
                <MenuItem value={action.paymentMode}>{action.label}</MenuItem>
              ))}
            </Select>
          )}
          <LoadingButton variant="contained" loading={isLoading} onClick={retryPayment} autoFocus>
            Zahlung wiederholen
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
