import { DrivingLicenseClassEnum, isForMotorcycle, isForTrailer } from "./DrivingLicenseClass";
import { t } from "./types";
import { RsvpEnum } from "./autovioCalendarEvents";
import { type z } from "zod";

const PracticeKPIsSchema = t.object({
  finishedDrivingLessonsCount: t.preprocess(
    (val) => {
      // Prevent ZodError: invalid_enum_value when parsing old data ...
      if (val) {
        const { theoretischePruefung, ...rest } = val as any;
        return rest;
      }
      return val;
    },
    t.record(
      t.enum(["normal", "ueberlandfahrt", "autobahnfahrt", "nachtfahrt", "praktischePruefung", "schaltkompetenz"]),
      t.number().nonnegative(),
    ),
  ),
  finishedManualGearLessonsCount: t.optional(t.number().nonnegative()),
  mostRecentDrivingLessonDate: t.optional(t.dateTime()),
  latestPlannedDrivingLessonDate: t.dateTime().optional(),
  practicalExams: t.optional(
    t.record(
      t.object({
        date: t.dateTime(),
        rsvp: t.optional(RsvpEnum),
        result: t.optional(t.enum(["passed", "failed"])),
      }),
    ),
  ),
  finishedObservationDrive: t.optional(t.boolean()),
});

export const BookedTrainingSchema = t.object({
  id: t.id(),
  asfCourseId: t.optional(t.id()),
  bundleId: t.optional(t.id()),
  bundleName: t.string().nonempty(),
  createdAt: t.optional(t.dateTime()),
  updatedAt: t.optional(t.dateTime()),
  updatedBy: t.optional(t.id()),
  drivingLicenseClass: DrivingLicenseClassEnum,
  guaranteedDrivingLessonsPerWeek: t.number().int().nonnegative(),
  quoteId: t.id(),
  trainingId: t.id(),
  drivingSchoolId: t.id(),
  instructorId: t.id(),
  prices: t.record(/* label */ t.string().nonempty(), /* cents */ t.number().nonnegative().int()),
  kpis: t.optional(PracticeKPIsSchema),
  drivingSchoolChange: t.optional(t.boolean()),
  drivingLicenseClassAdvancement: t.optional(t.boolean()),
  compulsoryTheoryLessons: t.optional(
    t.record(
      t.enum(["Theorieunterricht_Grundstoff", "Theorieunterricht_Zusatzstoff_A", "Theorieunterricht_Zusatzstoff_B"]),
      t.number().int().min(1),
    ),
  ),
  includesTheoryExam: t.optional(t.boolean()),
  includesPracticalExam: t.optional(t.boolean()),
  hasPassedTheoryExam: t.optional(t.boolean()),
  hasPassedPracticalExam: t.optional(t.boolean()),
  isFinished: t.optional(t.boolean()),
});

export type BookedTraining = z.infer<typeof BookedTrainingSchema> & {
  /** not stored in Firestore, but derived from compulsoryTheoryLessons. */
  hasCompulsoryTheoryLessons: boolean;
  /** not stored in Firestore, either the driving license class ("e.g. "B", "B197", "A1", etc) or "ASF-Kurs" */
  shortLabel: string;
  /** not stored in Firestore, but derived from bundle name. */
  isForDrivingLicense?: true;
  /** not stored in Firestore, used to sort the booked trainings of a student. */
  _sortOrder: number;
};

export function bookedTrainingsFromFirestore(bookedTrainingsData: any): Array<BookedTraining> {
  return Object.values(bookedTrainingsData ?? {})
    .map((data) => {
      const it = BookedTrainingSchema.parse(data);
      return {
        ...it,
        hasCompulsoryTheoryLessons: Object.keys(it.compulsoryTheoryLessons ?? {}).length > 0,
        ...(() => {
          if (it.bundleName.includes("ASF")) {
            return { shortLabel: "ASF-Kurs", _sortOrder: 9 };
          } else if (it.bundleName.includes("Fahrangst")) {
            return { shortLabel: "Fahrangst-Training", _sortOrder: 9 };
          } else {
            return {
              isForDrivingLicense: true,
              shortLabel: it.drivingLicenseClass,
              _sortOrder: isForMotorcycle(it.drivingLicenseClass) ? 2 : isForTrailer(it.drivingLicenseClass) ? 3 : 1,
            };
          }
        })(),
      } satisfies BookedTraining;
    })
    .sort((a, b) => a._sortOrder - b._sortOrder);
}
