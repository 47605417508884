import { useResourceContext } from "react-admin";
import * as React from "react";
import { Button } from "@mui/material";
import { useRecordContext } from "ra-core";
import FirestoreIcon from "@mui/icons-material/LocalFireDepartment";
import { openInFirestoreConsole } from "../firebase";
import { DrivingSchool } from "../providers/drivingSchoolsProvider";
import { Instructor } from "../providers/instructorsProvider";
import { Student } from "../providers/studentsProvider";
import { autovioColors, backofficeTheme } from "../misc/backofficeTheme";

const _collectionByResource: { [resource: string]: string } = {
  drivingSchools: "driving_schools",
  instructors: "users",
  students: "users",
};

export function OpenInFirestoreButton({ showTitle }: { showTitle?: boolean }) {
  const resource = useResourceContext();
  const record = useRecordContext<DrivingSchool | Instructor | Student>();
  const { black } = autovioColors;
  const collection = _collectionByResource[resource];
  const path = record ? `${collection}/${record.id}` : collection;
  return (
    <Button
      variant="text"
      sx={{
        ...backofficeTheme.linkButtonStyle,
        color: black,
        "&:hover": {
          background: "none",
        },
      }}
      startIcon={<FirestoreIcon sx={{ ml: 1, fontSize: 20 }} />}
      title="in Firestore öffnen"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        openInFirestoreConsole(path);
      }}
    >
      {showTitle ? "In Firestore öffnen" : null}
    </Button>
  );
}
