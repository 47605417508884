import { FieldValues, Path } from "react-hook-form";
import { SelectInput } from "./SelectInput";
interface DrivingLessonTypeInputProps {
  source: Path<FieldValues>;
  options: Array<[string, string]>;
}
export const DrivingLessonTypeInput = ({ source, options }: DrivingLessonTypeInputProps) => {
  return (
    <SelectInput
      source={source}
      label="Art"
      options={options}
      validate={(value) => (value ? true : "Bitte wähle die Art der Fahrstunde aus.")}
    />
  );
};
