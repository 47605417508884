export function DrivingSchoolSearchIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 16.012v-4.274l1.05-3a.922.922 0 0 1 .272-.347.689.689 0 0 1 .44-.141h5.476a.748.748 0 0 1 .712.488l1.05 3v4.274a.471.471 0 0 1-.14.347.471.471 0 0 1-.348.141h-.524a.471.471 0 0 1-.347-.14.471.471 0 0 1-.141-.348v-.637H9v.637a.471.471 0 0 1-.14.347.471.471 0 0 1-.348.141h-.525a.471.471 0 0 1-.346-.14.471.471 0 0 1-.141-.348zM9 10.875h6l-.525-1.5h-4.95L9 10.875zM8.666 12v2.25V12zm1.084 1.875c.213 0 .39-.072.534-.216a.726.726 0 0 0 .216-.534.726.726 0 0 0-.216-.534.726.726 0 0 0-.534-.216.726.726 0 0 0-.534.216.726.726 0 0 0-.216.534c0 .213.072.39.216.534a.726.726 0 0 0 .534.216zm4.5 0c.213 0 .39-.072.534-.216a.726.726 0 0 0 .216-.534.726.726 0 0 0-.216-.534.726.726 0 0 0-.534-.216.726.726 0 0 0-.534.216.726.726 0 0 0-.216.534c0 .213.072.39.216.534a.726.726 0 0 0 .534.216zM4.5 10.5V9H6v1.5H4.5zM8.25 6V4.5h1.5V6h-1.5zM4.5 13.5V12H6v1.5H4.5zm0 3V15H6v1.5H4.5zm-3 0V6h3.75V1.5h7.5v5.25h-1.5V3h-4.5v4.5H3v9H1.5zm7.125-2.25h6.75V12h-6.75v2.25z"
        fill="#888"
        fillRule="nonzero"
      />
    </svg>
  );
}
