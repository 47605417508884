import { z } from "zod";
import { schemas } from "../generated/serverClient";
import { t } from "./types.js";
import { ServerAttachmentSchema } from "./Note.js";
import orderBy from "lodash/orderBy";

export const PostSchema = schemas.PostDto.extend({
  createdAt: t.dateTime(),
  updatedAt: t.dateTime(),
  attachments: z.array(ServerAttachmentSchema).optional(),
});

export const ThreadSchema = schemas.ThreadDto.extend({
  createdAt: t.dateTime(),
  updatedAt: t.dateTime(),
  posts: z.record(PostSchema).default({}),
  sortedPosts: z.array(PostSchema).default([]),
}).transform((thread) => {
  return {
    ...thread,
    sortedPosts: orderBy(Object.values(thread.posts), "createdAt", "desc"),
  };
});

export const CreatePostSchema = schemas.CreatePostDto;

export type Post = z.infer<typeof PostSchema>;
export type CreatePostDto = z.infer<typeof CreatePostSchema>;
export type Thread = z.infer<typeof ThreadSchema>;
