import { useContext } from "react";
import { ThreadContext } from "../contexts/ThreadContext.js";

export const useThreadId = (): string => {
  const context = useContext(ThreadContext);
  if (!context) {
    throw new Error("useThreadId must be used within a ThreadContext.Provider");
  }
  return context.threadId;
};
