import { Chip } from "@mui/material";
import { autovioColors } from "../misc/backofficeTheme";
import { Invoice, InvoiceStateEnum } from "../generated/backendClient";
import { Tooltip } from "@mui/material";
import { INVOICE_STATUS_LABEL_AND_COLOR } from "../backoffice.utils";

export const INVOICE_STATE_LABEL_AND_COLOR: { [K in InvoiceStateEnum]: { label: string; color: string } } = {
  UNPAID: { label: "️Warte auf Zahlung", color: autovioColors.greyUltraLight },
  UNPAID_WITH_PREPAID_CREDITS: { label: "️Guthaben nicht anwendbar", color: autovioColors.orange },
  AWAITING_PAYMENT: { label: "In Arbeit", color: autovioColors.greyUltraLight },
  PAID: { label: "Bezahlt", color: autovioColors.greenLight },
  REFUNDED: { label: "Erstattet", color: autovioColors.greyUltraLight },
  PAYMENT_REVOKED: { label: "Angefochten", color: autovioColors.orange },
  PARTIALLY_REFUNDED: { label: "Teilw. rückerstattet", color: autovioColors.greyUltraLight },
};

export function InvoiceStateBadge({ invoice }: { invoice: Invoice }) {
  return (
    <Tooltip title={INVOICE_STATUS_LABEL_AND_COLOR[invoice.state]?.label} placement="bottom-end">
      <Chip
        sx={{
          background: INVOICE_STATUS_LABEL_AND_COLOR[invoice.state]?.bgColor,
          color: INVOICE_STATUS_LABEL_AND_COLOR[invoice.state]?.color,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: 65,
          height: 20,
          fontSize: 9,
          fontWeight: "600",
        }}
        label={INVOICE_STATUS_LABEL_AND_COLOR[invoice.state]?.label?.toUpperCase()}
      />
    </Tooltip>
  );
}
