export function KPICreditsIcon() {
  return (
    <svg width="19" height="19" viewBox="0.5 0.5 19.5 19.5" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.27 9.931c.234 0 .43-.08.588-.238a.804.804 0 0 0 .238-.59.804.804 0 0 0-.238-.591.796.796 0 0 0-.587-.239c-.234 0-.43.08-.588.239a.804.804 0 0 0-.237.59c0 .235.079.432.237.59a.796.796 0 0 0 .588.24zM6.67 7.86h4.126V6.615H6.67V7.86zM3.784 18.22c-.412-1.368-.75-2.543-1.01-3.523-.262-.981-.471-1.834-.63-2.56a19.54 19.54 0 0 1-.33-1.896 13.634 13.634 0 0 1-.092-1.554c0-1.271.44-2.349 1.32-3.233.88-.884 1.952-1.327 3.217-1.327h4.125a4.2 4.2 0 0 1 1.413-1.201 3.95 3.95 0 0 1 1.887-.456c.344 0 .636.12.877.362a1.202 1.202 0 0 1 .33 1.13c-.021-1.492-.045.159-.073.228-.055.152-.106.304-.154.456a3.306 3.306 0 0 0-.114.497l1.877 1.886h1.795v5.782l-2.331.766-1.382 4.643H9.971v-1.658H8.32v1.658H3.783zm.928-1.244h2.372V15.32h4.125v1.658h2.372l1.3-4.352 2.103-.725V8.273h-1.072l-2.64-2.652c.013-.235.051-.522.113-.86.062-.339.148-.715.258-1.13a5.05 5.05 0 0 0-1.568.684c-.453.304-.783.656-.99 1.057H6.258c-.907 0-1.684.325-2.33.974a3.2 3.2 0 0 0-.97 2.342c0 .58.151 1.592.454 3.036.302 1.443.735 3.195 1.3 5.253z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}
