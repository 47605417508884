import { collection, doc, getDocsFromServer, onSnapshot } from "firebase/firestore";
import { httpsCallable, HttpsCallable } from "firebase/functions";
import memoizeOne from "memoize-one";
import { DeleteManyResult } from "react-admin";
import { QueryClient, useMutation, useQuery, useQueryClient, UseQueryResult } from "react-query";
import { z } from "zod";
import { reportError } from "../backoffice.utils";
import { firestore, functions } from "../firebase";
import { UserToBeDeleted } from "../providers/usersToBeDeletedProvider";
import { StudentStatus } from "../model/StudentStatus";
import { Student, studentsProvider } from "../providers/studentsProvider";
import { t } from "../model/types";
import { AttachmentSchema } from "../model/Attachment";
import { PostalAddress, PostalAddressSchema } from "../model/PostalAddress";
import { CancellationPolicy, CancellationPolicySchema } from "../model/CancellationPolicy";
import { ExamType } from "../model/ExamType";
import { DateTime } from "luxon";
import { Money, MoneySchema } from "../model/Money";
import { BufferTimes, BufferTimesSchema } from "../model/BufferTimes";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { isDefined } from "../utils/filters";
import { getAuthenticatedServerClient, schemas } from "./server.api";
import { updateApplied } from "../utils/updateApplied";
import { drivingLessonConverter } from "../providers/converter/drivingLessonConverter";

interface ProviderData {
  /**
   * The user identifier for the linked provider.
   */
  readonly uid: string;
  /**
   * The display name for the linked provider.
   */
  readonly displayName: string;
  /**
   * The email for the linked provider.
   */
  readonly email: string;
  /**
   * The photo URL for the linked provider.
   */
  readonly photoURL: string;
  /**
   * The linked provider ID (for example, "google.com" for the Google provider).
   */
  readonly providerId: string;
  /**
   * The phone number for the linked provider.
   */
  readonly phoneNumber: string;
}

export interface FirebaseAuthUser {
  readonly uid: string;
  readonly displayName?: string;
  readonly disabled: boolean;
  readonly providerData: Array<ProviderData>;
  readonly customClaims?: {
    [key: string]: any;
  };
}

export const fetchAutovioEmployeesOnce = memoizeOne(async function fetchAutovioEmployees() {
  try {
    const getAutovioEmployees = httpsCallable(functions, "api/backoffice/autovio-employees");
    const response = (await getAutovioEmployees()) as { data: Array<FirebaseAuthUser> };
    return response.data;
  } catch (error) {
    reportError(`Failed to fetch AUTOVIO employees`, error);
    fetchAutovioEmployeesOnce.clear();
    return [];
  }
});

export async function fetchFirebaseAuthUser(uid: string): Promise<FirebaseAuthUser> {
  const getUsers = httpsCallable(functions, "api/backoffice/users");
  const { data } = (await getUsers({ uid })) as { data: Array<FirebaseAuthUser> };
  if (data.length === 0) {
    throw new Error("No data");
  }
  return data[0];
}

export async function setUserRoles(
  userUid: string,
  roles: Array<string>,
  entitledForDrivingSchools?: Array<string>,
): Promise<void> {
  const setUserRoles = httpsCallable<
    {
      userUid: string;
      roles: Array<string>;
      entitledForDrivingSchools?: Array<string>;
    },
    void
  >(functions, "api/backoffice/users/roles");
  await setUserRoles({ userUid, roles, entitledForDrivingSchools });
  fetchAutovioEmployeesOnce.clear();
}

interface SetAvatarOverrideUrlRequest {
  readonly userUid: string;
  readonly avatarOverrideUrl: string;
}

const SetStudentStatusRequestSchema = z.object({
  studentUid: z.string(),
  status: z.enum(["active", "cancelled", "inactive", "onHold", "outstandingPayments"]),
  reason: z.string(),
});

type SetStudentStatusRequest = z.infer<typeof SetStudentStatusRequestSchema>;

export async function setUserAvatarOverride(userUid: string, avatarOverrideUrl: string): Promise<void> {
  const setAvatarOverrideUrl = httpsCallable<SetAvatarOverrideUrlRequest>(
    functions,
    "api/backoffice/users/avatarOverrideUrl",
  );
  await setAvatarOverrideUrl({ userUid, avatarOverrideUrl });
}

export async function setStudentStatus(
  student: Student,
  status: Exclude<StudentStatus, "completed">,
  reason: string,
): Promise<void> {
  const setStudentStatus = httpsCallable<SetStudentStatusRequest>(functions, "api/backoffice/users/set-student-status");
  const updateReceived = new Promise<void>((resolve) => {
    const unsubscribe = studentsProvider.onUpdate(student, (student) => {
      if (student.status === status) {
        unsubscribe();
        resolve();
      }
    });
  });
  await setStudentStatus({ studentUid: student.id, status, reason });
  await updateReceived;
}

export async function resetTheoryLearning(student: Student): Promise<void> {
  const resetTheoryLearning = httpsCallable<{ studentUid: string }, void>(
    functions,
    "api/backoffice/users/reset-theory-learning",
  );
  let unsubscribe: () => void = () => {
    throw new Error("unsubscribe has not been set");
  };
  const userDocumentHasCorrectTheoryLearningProgress = new Promise<void>((resolve) => {
    unsubscribe = onSnapshot(doc(firestore, `users/${student.id}`), (snapshot) => {
      const progress = snapshot.data()?.theoryKPIs?.theoryLearningProgress;
      if (progress?.numCorrectlyAnsweredQuestions === 0 && progress?.numIncorrectlyAnsweredQuestions === 0) {
        resolve();
      }
    });
  });
  await resetTheoryLearning({ studentUid: student.id });
  await userDocumentHasCorrectTheoryLearningProgress;
  unsubscribe();
}

export async function deleteUser(userUid: string): Promise<void> {
  const deleteUser = httpsCallable<{ userUid: string }, void>(functions, "api/backoffice/users/delete");
  await deleteUser({ userUid });
}

export async function deleteUsers(userUids: Array<string>): Promise<DeleteManyResult<UserToBeDeleted>> {
  const deleteUsers = httpsCallable<{ userUids: Array<string> }, Array<string>>(
    functions,
    "api/backoffice/users/delete-many",
  );
  const { data } = await deleteUsers({ userUids });
  return { data };
}

export class MissingAddressDataError extends Error {
  constructor(msg: string) {
    super(msg);
    Object.setPrototypeOf(this, MissingAddressDataError.prototype);
  }
}

const CreateNoteRequestSchema = t.object({
  studentUid: t.uid(),
  body: t.string(),
  attachments: t.optional(
    t.array(
      // size is optional in AttachmentSchema, but should be required for CreateNoteRequestSchema ...
      AttachmentSchema.omit({ id: true, size: true, lastChanged: true }).extend({
        size: t.number().int().positive(),
      }),
    ),
  ),
});

export async function createNote(data: z.infer<typeof CreateNoteRequestSchema>): Promise<void> {
  let unsubscribe: () => void = () => {
    throw new Error("unsubscribe has not been set");
  };
  const ref = collection(firestore, `users/${data.studentUid}/notes`);
  const numOldNotes = (await getDocsFromServer(ref)).docs.length;
  const newNoteCreated = new Promise<void>((resolve) => {
    unsubscribe = onSnapshot(ref, (snapshot) => {
      if (snapshot.docs.length > numOldNotes) {
        resolve();
      }
    });
  });
  try {
    const createNote = httpsCallable<z.infer<typeof CreateNoteRequestSchema>, void>(
      functions,
      "api/backoffice/notes/create",
    );
    await createNote(data);
    await newNoteCreated;
  } finally {
    unsubscribe();
  }
}

const EditNoteRequestSchema = t.object({
  studentUid: t.uid(),
  noteUid: t.uid(),
  body: t.string(),
});

export async function editNote(data: z.infer<typeof EditNoteRequestSchema>): Promise<void> {
  const editNote = httpsCallable<z.infer<typeof EditNoteRequestSchema>, void>(functions, "api/backoffice/notes/edit");
  await editNote(data);
}

const SetBranchRequestSchema = t.object({
  studentUid: t.uid(),
  // Might be the empty string "" to unassign a branch.
  branchUid: t.string(),
});

export async function setBranch(student: Student, branchId: string): Promise<void> {
  const setBranch = httpsCallable<z.infer<typeof SetBranchRequestSchema>, void>(
    functions,
    "api/backoffice/users/setBranch",
  );
  const updateReceived = new Promise<void>((resolve) => {
    const unsubscribe = studentsProvider.onUpdate(student, (student) => {
      if (branchId ? student.branchId === branchId : !student.branchId) {
        unsubscribe();
        resolve();
      }
    });
  });
  await setBranch({ studentUid: student.id, branchUid: branchId });
  await updateReceived;
}

const DrivingLessonTypeEnum = t.enum([
  "normal",
  "ueberlandfahrt",
  "autobahnfahrt",
  "nachtfahrt",
  "praktischePruefung",
  "schaltkompetenz",
  "theoretischePruefung",
  "fahrprobe",
]);

export const OnlineMeetingSchema = z.object({
  type: z.literal("OnlineMeeting"),
  url: z.string(),
});

export type OnlineMeeting = z.infer<typeof OnlineMeetingSchema>;

export const LocationSchema = PostalAddressSchema.or(OnlineMeetingSchema);

export const GetPriceSuggestionRequestSchema = z.object({
  eventType: t.enum(["DrivingLesson", "RemoteLesson"]),
  drivingLessonType: DrivingLessonTypeEnum.optional(),
  instructorUid: z.string().nonempty(),
  studentUid: z.string().nonempty(),
  bookedTrainingId: z.string().nonempty(),
  start: z.instanceof(DateTime as any).transform((val) => val.toString()),
  end: z.optional(z.instanceof(DateTime as any).transform((val) => val.toString())),
  location: z.optional(LocationSchema),
});
export type GetPriceSuggestionRequest = z.infer<typeof GetPriceSuggestionRequestSchema>;

const GetPriceSuggestionResponseSchema = z.object({
  data: z.object({
    price: z.object({
      studentPrice: z.object({
        gross: z.number().int().nonnegative(),
      }),
      authorityFees: z.object({
        gross: z.number().int().nonnegative(),
      }),
    }),
  }),
});

export async function getPriceSuggestion(request: GetPriceSuggestionRequest): Promise<{
  price: Money;
  authorityFee: Money;
}> {
  const callable = httpsCallable<GetPriceSuggestionRequest>(functions, "api/backoffice/price-suggestion");
  const { data } = GetPriceSuggestionResponseSchema.parse(await callable(request));
  return {
    price: { amount: data.price.studentPrice.gross / 100, currency: "EUR" },
    authorityFee: { amount: data.price.authorityFees.gross / 100, currency: "EUR" },
  };
}

const BookDrivingLessonRequestSchema = z.object({
  drivingLessonType: z.enum(["normal", "ueberlandfahrt", "autobahnfahrt", "nachtfahrt"]),
  instructorId: z.string().nonempty(),
  studentId: z.string().nonempty(),
  bookedTrainingId: z.string().nonempty(),
  studentRsvp: z.enum(["pending", "accepted"]),
  studentAgreedCancellationPolicy: z.optional(CancellationPolicySchema),
  dateTime: t.string(),
  duration: z.object({ minutes: z.number().int().gt(0) }),
  bufferTimes: t.optional(BufferTimesSchema),
  location: PostalAddressSchema,
  resourceIds: z.array(z.string().nonempty()).max(2),
  manualPrice: z.optional(MoneySchema),
});
type BookDrivingLessonRequest = z.infer<typeof BookDrivingLessonRequestSchema>;

export async function bookDrivingLesson({
  drivingLessonType,
  instructorId,
  studentId,
  bookedTrainingId,
  studentRsvp,
  studentAgreedCancellationPolicy,
  dateTime,
  duration,
  bufferTimes,
  location,
  resourceIds,
  manualPrice,
}: {
  drivingLessonType: "normal" | "ueberlandfahrt" | "autobahnfahrt" | "nachtfahrt";
  instructorId: string;
  studentId: string;
  bookedTrainingId: string;
  studentRsvp: "pending" | "accepted";
  studentAgreedCancellationPolicy?: CancellationPolicy;
  dateTime: DateTime;
  duration: { minutes: number };
  bufferTimes?: BufferTimes;
  location: PostalAddress;
  resourceIds: Array<string>;
  manualPrice?: Money;
}): Promise<void> {
  const callable = httpsCallable<BookDrivingLessonRequest>(functions, "api/backoffice/book-driving-lesson");
  const request = {
    drivingLessonType,
    instructorId,
    studentId,
    bookedTrainingId,
    studentRsvp,
    ...(studentAgreedCancellationPolicy ? { studentAgreedCancellationPolicy } : {}),
    dateTime: dateTime.toJSON(),
    duration,
    ...(bufferTimes ? { bufferTimes } : {}),
    location: {
      street: location.street,
      postalCode: location.postalCode,
      city: location.city,
    },
    resourceIds,
    ...(manualPrice ? { manualPrice } : {}),
  } satisfies BookDrivingLessonRequest;
  await callable(request);
}

export async function editDrivingLesson({
  eventId,
  instructorId,
  start,
  end,
  drivingLessonType,
  bookedTrainingId,
  resourceIds,
}: {
  eventId: string;
  instructorId: string;
  start: DateTime;
  end: DateTime;
  drivingLessonType: z.infer<typeof DrivingLessonTypeEnum>;
  bookedTrainingId: string;
  resourceIds: Array<string>;
}): Promise<void> {
  const serverClient = await getAuthenticatedServerClient();
  const payload = {
    instructorId,
    start,
    end,
    drivingLessonType,
    bookedTrainingId,
    resourceIds,
  } satisfies z.infer<typeof schemas.EditCalendarEventDto>;

  const updateReceived = new Promise<void>((resolve) => {
    let unsubscribe: () => void = () => {
      throw new Error("unsubscribe has not been set");
    };
    unsubscribe = onSnapshot(doc(firestore, `calendar_events/${eventId}`), (snapshot) => {
      const updatedDrivingLesson = drivingLessonConverter.fromFirestore(snapshot);
      const payloadToMap = {
        instructorId,
        start,
        end,
        drivingLessonType,
        resources: resourceIds,
        student: {
          bookedTrainingId,
        },
      };
      if (updateApplied(payloadToMap, updatedDrivingLesson)) {
        unsubscribe();
        resolve();
      }
    });
  });
  await serverClient.editEvent(payload, { params: { eventId } });
  await updateReceived;
}

const BookExamRequestSchema = z.object({
  examType: z.enum(["theoryExam", "practicalExam"]),
  instructorId: z.string().nonempty(),
  studentId: z.string().nonempty(),
  bookedTrainingId: z.string().nonempty(),
  studentRsvp: z.enum(["pending", "accepted"]),
  studentAgreedCancellationPolicy: z.optional(CancellationPolicySchema),
  dateTime: t.string(),
  bufferTimes: t.optional(BufferTimesSchema),
  location: PostalAddressSchema,
  resourceIds: z.optional(z.array(z.string().nonempty()).max(2)),
  manualPrice: z.optional(MoneySchema),
});
type BookExamRequest = z.infer<typeof BookExamRequestSchema>;

export async function bookExam({
  examType,
  instructorId,
  studentId,
  bookedTrainingId,
  studentRsvp,
  studentAgreedCancellationPolicy,
  dateTime,
  bufferTimes,
  location,
  resourceIds,
  manualPrice,
}: {
  examType: ExamType;
  instructorId: string;
  studentId: string;
  bookedTrainingId: string;
  studentRsvp: "pending" | "accepted";
  studentAgreedCancellationPolicy?: CancellationPolicy;
  dateTime: DateTime;
  bufferTimes?: BufferTimes;
  location: PostalAddress;
  resourceIds?: Array<string>;
  manualPrice?: Money;
}): Promise<void> {
  const callable = httpsCallable<BookExamRequest>(functions, "api/backoffice/book-exam");
  const request = {
    examType,
    instructorId,
    studentId,
    bookedTrainingId,
    studentRsvp,
    ...(studentAgreedCancellationPolicy ? { studentAgreedCancellationPolicy } : {}),
    dateTime: dateTime.toJSON(),
    ...(bufferTimes ? { bufferTimes } : {}),
    location: {
      street: location.street,
      postalCode: location.postalCode,
      city: location.city,
    },
    ...(resourceIds ? { resourceIds } : {}),
    ...(manualPrice ? { manualPrice } : {}),
  } satisfies BookExamRequest;
  await callable(request);
}

const SetReadyForTheoryExamRequestSchema = z.object({
  studentUid: t.uid(),
  isReady: t.boolean(),
});

export function useSetReadyForTheoryExamMutation() {
  const queryClient = useQueryClient();
  const mutation = useMutation(setReadyForTheoryExam, {
    onSuccess: async () => {
      void queryClient.invalidateQueries(["students"]);
    },
  });
  return mutation;
}

async function setReadyForTheoryExam(data: z.infer<typeof SetReadyForTheoryExamRequestSchema>): Promise<void> {
  let unsubscribe: () => void = () => {
    throw new Error("unsubscribe has not been set");
  };
  const studentUpdateReceived = new Promise<void>((resolve) => {
    unsubscribe = onSnapshot(doc(firestore, `users/${data.studentUid}`), (snapshot) => {
      if (snapshot.data()?.isReadyForTheoryExam === data.isReady) {
        resolve();
      }
    });
  });
  try {
    const callable = httpsCallable<z.infer<typeof SetReadyForTheoryExamRequestSchema>, void>(
      functions,
      "api/backoffice/users/set-ready-for-theory-exam",
    );
    await callable(data);
    await studentUpdateReceived;
  } finally {
    unsubscribe();
  }
}

const AddStudentToTheoryLessonRequestSchema = t.object({
  calendarEventUid: t.uid(),
  studentUid: t.uid(),
});

async function addStudentToTheoryLesson(request: z.infer<typeof AddStudentToTheoryLessonRequestSchema>): Promise<void> {
  let unsubscribe: () => void = () => {
    throw new Error("unsubscribe has not been set");
  };
  const studentWasAdded = new Promise<void>((resolve) => {
    unsubscribe = onSnapshot(doc(firestore, `calendar_events/${request.calendarEventUid}`), (snapshot) => {
      const data = snapshot.data();
      if (data?.students?.[request.studentUid]) {
        resolve();
      }
    });
  });
  try {
    const callable = httpsCallable<z.infer<typeof AddStudentToTheoryLessonRequestSchema>, void>(
      functions,
      "api/backoffice/event/add-student-to-theory-lesson",
    );
    await callable(request);
    await studentWasAdded;
  } finally {
    unsubscribe();
  }
}

export function useAddStudentToTheoryLessonMutation() {
  const queryClient = useQueryClient();
  const mutation = useMutation(addStudentToTheoryLesson, {
    onSuccess: async () => {
      void queryClient.invalidateQueries(["calendarEvents"]);
      void queryClient.invalidateQueries(["calendarEventHistory"]);
      void queryClient.invalidateQueries(["courses"]);
      void queryClient.invalidateQueries(["recommendations", "student"]);
    },
  });
  return mutation;
}

const RemoveStudentsFromTheoryLessonRequestSchema = t.object({
  calendarEventUid: t.uid(),
  studentUids: t.array(t.uid()),
});

async function removeStudentsFromTheoryLesson(
  request: z.infer<typeof RemoveStudentsFromTheoryLessonRequestSchema>,
): Promise<void> {
  let unsubscribe: () => void = () => {
    throw new Error("unsubscribe has not been set");
  };
  const studentsHaveBeenRemoved = new Promise<void>((resolve) => {
    unsubscribe = onSnapshot(doc(firestore, `calendar_events/${request.calendarEventUid}`), (snapshot) => {
      const data = snapshot.data();
      if (Object.keys(data?.students ?? {}).every((uid) => !request.studentUids.includes(uid))) {
        resolve();
      }
    });
  });
  try {
    const callable = httpsCallable<z.infer<typeof RemoveStudentsFromTheoryLessonRequestSchema>, void>(
      functions,
      "api/backoffice/event/remove-students-from-theory-lesson",
    );
    await callable(request);
    await studentsHaveBeenRemoved;
  } finally {
    unsubscribe();
  }
}

export function useRemoveStudentsFromTheoryLessonMutation() {
  const queryClient = useQueryClient();
  const mutation = useMutation(removeStudentsFromTheoryLesson, {
    onSuccess: async () => {
      void queryClient.invalidateQueries(["calendarEvents"]);
      void queryClient.invalidateQueries(["calendarEventHistory"]);
      void queryClient.invalidateQueries(["courses"]);
      void queryClient.invalidateQueries(["recommendations", "student"]);
    },
  });
  return mutation;
}

const DeleteAutovioCalendarEventRequestSchema = t.object({
  calendarEventUid: t.string().nonempty(),
  reason: t.optional(t.string().min(5)),
});

export function useDeleteEventMutation() {
  const queryClient = useQueryClient();
  const mutation = useMutation(deleteEvent, {
    onSuccess: async () => {
      void queryClient.invalidateQueries(["calendarEvents"]);
      void queryClient.invalidateQueries(["calendarEventHistory"]);
      void queryClient.invalidateQueries(["courses"]);
      void queryClient.invalidateQueries(["recommendations", "student"]);
    },
  });
  return mutation;
}

async function deleteEvent({ calendarEventUid, reason }: { calendarEventUid: string; reason?: string }): Promise<void> {
  let unsubscribe: () => void = () => {
    throw new Error("unsubscribe has not been set");
  };
  const calendarEventIsMarkedAsDeleted = new Promise<void>((resolve) => {
    unsubscribe = onSnapshot(doc(firestore, `calendar_events/${calendarEventUid}`), (snapshot) => {
      const data = snapshot.data();
      if (data?.deleted) {
        resolve();
      }
    });
  });
  try {
    const callable = httpsCallable<z.infer<typeof DeleteAutovioCalendarEventRequestSchema>, void>(
      functions,
      "api/backoffice/event/delete",
    );
    await callable({ calendarEventUid, ...(reason ? { reason } : {}) });
    await calendarEventIsMarkedAsDeleted;
  } finally {
    unsubscribe();
  }
}

const ResetPasswordRequestSchema = t.object({
  userUid: t.uid(),
  newPassword: t.string(),
});

export async function resetPassword(params: { userUid: string; newPassword: string }): Promise<void> {
  const callable = httpsCallable<z.infer<typeof ResetPasswordRequestSchema>, void>(
    functions,
    "api/backoffice/users/reset-password",
  );
  await callable(params);
}

export async function bookRecommendation({
  recommendation,
  startLocation,
  studentRsvp,
  studentAgreedCancellationPolicy,
}: {
  recommendation: Recommendation;
  startLocation: PostalAddress;
  studentRsvp: "pending" | "accepted";
  studentAgreedCancellationPolicy?: CancellationPolicy;
}): Promise<void> {
  const callable = httpsCallable<BookRecommendationRequest>(functions, "api/backoffice/recommendations/book");
  const request: BookRecommendationRequest = {
    uid: uuidv4(),
    start: recommendation.start.setZone("Europe/Berlin").toISO(),
    end: recommendation.end.setZone("Europe/Berlin").toISO(),
    resourceUids: recommendation.resourceUids,
    drivingLessonType: recommendation.drivingLessonType,
    startLocation,
    instructorUid: recommendation.instructorUid,
    studentUid: recommendation.studentUid,
    studentRsvp,
    bookedTrainingUid: recommendation.bookedTrainingId,
    ...(studentAgreedCancellationPolicy ? { studentAgreedCancellationPolicy } : {}),
  };
  await callable(request);
}

export const useBookRecommendationMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(bookRecommendation, {
    onSuccess: async () => {
      void queryClient.invalidateQueries(["calendarEvents"]);
      void queryClient.invalidateQueries(["calendarEventHistory"]);
      void queryClient.invalidateQueries(["courses"]);
      void queryClient.invalidateQueries(["recommendations", "student"]);
    },
  });
  return mutation;
};

export type RecommendationsData = {
  recommendations: RecommendationWithPrice[];
  removedRecommendations: Recommendation[];
  noResultReasonPerDay: { [isoDay: string]: NoRecommendationResultReason | undefined };
};
export const useStudentRecommendationsQuery = (
  props: UseStudentRecommendationsQueryProps | undefined,
): UseQueryResult<RecommendationsData> => {
  const queryClient = useQueryClient();
  const callable = httpsCallable<StudentRecommendationRangeQuery, RecommendationsInRangeDto>(
    functions,
    `api/backoffice/recommendations/student/range`,
  );
  useEffect(() => {
    void prefetchRecommendations(
      queryClient,
      (dateRange: { from: string; to: string }) => fetchRecommendations(callable, props, dateRange),
      props,
    );
  }, [props?.student.id, props?.instructorId, props?.startLocation, props?.bookedTrainingId, props?.from, props?.to]);
  return useQuery({
    queryKey: studentRecommendationsKey({ queryProps: props, dateRange: { from: props?.from, to: props?.to } }),
    queryFn: () => fetchRecommendations(callable, props, { from: props?.from, to: props?.to }),
    enabled: !!props && !!props?.startLocation,
  });
};

const studentRecommendationsKey = ({
  queryProps,
  dateRange,
}: {
  queryProps?: UseStudentRecommendationsQueryProps;
  dateRange?: { from?: string; to?: string };
}) => [
  "recommendations",
  "student",
  {
    studentUid: queryProps?.student?.id,
    instructorId: queryProps?.instructorId,
    bookedTrainingId: queryProps?.bookedTrainingId,
    startLocation: queryProps?.startLocation,
  },
  { from: dateRange?.from, to: dateRange?.to },
  { options: queryProps?.options },
];

const fetchRecommendations = async (
  callable: HttpsCallable<StudentRecommendationRangeQuery, RecommendationsInRangeDto>,
  props?: UseStudentRecommendationsQueryProps,
  dateRange?: { from?: string; to?: string },
) => {
  if (!props || !props.startLocation || !dateRange?.from || !dateRange?.to) {
    return [];
  }
  const postalAddress = props.student.postalAddress;
  if (!isPostalAddress(postalAddress)) {
    throw new MissingAddressDataError(`Missing address data, postalAddress: ${props.student.postalAddress}`);
  }
  const { data } = await callable({
    start: dateRange.from,
    end: dateRange.to,
    bookedTrainingId: props.bookedTrainingId,
    instructorUid: props.instructorId,
    studentUid: props.student.id,
    startLocation: props.startLocation,
    options: props.options,
  });
  const recommendationsPerDay = RecommendationsInRangeDtoSchema.parse(data);
  const noResultReasonPerDay: { [isoDay: string]: NoRecommendationResultReason | undefined } = Object.fromEntries(
    Object.entries(recommendationsPerDay).map(([day, data]) => [day, data.noResultReason]),
  );
  const recommendations = Object.values(recommendationsPerDay).flatMap((it) =>
    it.recommendations.map((it) => RecommendationWithPriceSchema.parse(it)),
  );
  const removedRecommendations = Object.values(recommendationsPerDay)
    .flatMap((it) => it.removedRecommendations?.map((it) => RecommendationSchema.parse(it)))
    .filter(isDefined);
  return { recommendations, removedRecommendations, noResultReasonPerDay };
};

const prefetchRecommendations = async (
  queryClient: QueryClient,
  fetchFn: (dateRange: { from: string; to: string }) => any,
  queryProps?: UseStudentRecommendationsQueryProps,
) => {
  if (!queryProps?.from || !queryProps?.to) {
    return;
  }
  const nextWeekFrom = DateTime.fromISO(queryProps?.from)
    .plus({ weeks: 1 })
    .toISO({ suppressMilliseconds: true });
  const nextWeekTo = DateTime.fromISO(queryProps?.to)
    .plus({ weeks: 1 })
    .toISO({ suppressMilliseconds: true });
  const nextWeekRange = { from: nextWeekFrom, to: nextWeekTo };
  // The results of this query will be cached like a normal query
  await queryClient.prefetchQuery({
    queryKey: studentRecommendationsKey({ queryProps, dateRange: nextWeekRange }),
    queryFn: () => fetchFn(nextWeekRange),
  });
};

function isPostalAddress(postalAddress: Partial<PostalAddress> | undefined): postalAddress is PostalAddress {
  return !!postalAddress && !!postalAddress.city && !!postalAddress.street && !!postalAddress.postalCode;
}

const BookableDrivingLessonTypeForRecommendationsSchema = z.enum(["normal"]);
const BookRecommendationRequestSchema = z.object({
  uid: z.string(),
  studentUid: z.string(),
  studentRsvp: z.union([z.literal("pending"), z.literal("accepted")]),
  bookedTrainingUid: z.string(),
  instructorUid: z.string(),
  start: z.string(),
  end: z.string(),
  drivingLessonType: BookableDrivingLessonTypeForRecommendationsSchema,
  startLocation: PostalAddressSchema,
  resourceUids: z.string().array(),
});
type BookRecommendationRequest = z.infer<typeof BookRecommendationRequestSchema>;

const StudentRecommendationRangeQuerySchema = z.object({
  studentUid: z.string(),
  instructorUid: z.string(),
  bookedTrainingId: z.string(),
  startLocation: PostalAddressSchema,
  start: z.string(),
  end: z.string(),
  options: z
    .object({
      returnRemoved: z.boolean().optional(),
      ignoreMaxDaysInAdvance: z.boolean().optional(),
      ignoreMaxDrivingLessonsPerWeek: z.boolean().optional(),
    })
    .optional(),
});

const RemoveReasonSchema = z.enum([
  "minTimeAhead",
  "maxDrivingLessonsPerDay",
  "maxDrivingLessonsPerWeek",
  "maxWorkTimePerDay",
  "maxBookableDrivingLessonsPerWeek",
  "maxDaysInAdvance",
  "pickBestRecommendations",
]);

const NoRecommendationResultReasonSchema = RemoveReasonSchema.or(
  z.enum(["noQueries", "isHoliday", "notWorkingToday", "unknown"]),
);
export type NoRecommendationResultReason = z.infer<typeof NoRecommendationResultReasonSchema>;

const RecommendationDtoSchema = z.object({
  instructorUid: z.string(),
  studentUid: z.string(),
  bookedTrainingId: z.string(),
  drivingLessonType: BookableDrivingLessonTypeForRecommendationsSchema,
  resourceUids: z.string().array(),
  score: z.number().optional(),
  individualScores: z
    .object({
      coverage: z.number(),
      duration: z.number(),
      startHour: z.number(),
      instructorSwitch: z.number(),
      resourceSwitch: z.number(),
      preferredResource: z.number(),
    })
    .optional(),
  start: z.string(),
  end: z.string(),
  removeReason: RemoveReasonSchema.optional(),
});
type RecommendationDto = z.infer<typeof RecommendationDtoSchema>;

const PriceCompositionSchema = z.object({
  gross: z.number(),
  net: z.number(),
  vat: z.number(),
});
const PriceSchema = z.object({
  studentPrice: PriceCompositionSchema,
  instructorPrice: PriceCompositionSchema.extend({ fee: z.number() }),
  authorityFees: PriceCompositionSchema,
});
const RecommendationWithPriceDtoSchema = RecommendationDtoSchema.extend({ price: PriceSchema });
export const RecommendationsInRangeDtoSchema = z.record(
  z.string(),
  z.object({
    recommendations: RecommendationWithPriceDtoSchema.array(),
    removedRecommendations: RecommendationDtoSchema.array().optional(),
    isHoliday: z.boolean().optional(),
    noResultReason: NoRecommendationResultReasonSchema.optional(),
  }),
);

export const transformRecommendation = <R extends RecommendationDto>(val: R) => ({
  ...val,
  id: `${val.instructorUid}-${val.studentUid}-${val.bookedTrainingId}--${val.drivingLessonType}-${val.start}-${val.end}`,
  start: DateTime.fromISO(val.start),
  end: DateTime.fromISO(val.end),
});

const RecommendationSchema = RecommendationDtoSchema.transform(transformRecommendation);
const RecommendationWithPriceSchema = RecommendationWithPriceDtoSchema.transform(transformRecommendation);
export type Recommendation = z.infer<typeof RecommendationSchema>;
export type RecommendationWithPrice = z.infer<typeof RecommendationWithPriceSchema>;
type StudentRecommendationRangeQuery = z.infer<typeof StudentRecommendationRangeQuerySchema>;
type RecommendationsInRangeDto = z.infer<typeof RecommendationsInRangeDtoSchema>;

interface UseStudentRecommendationsQueryProps {
  readonly from: string;
  readonly to: string;
  readonly student: Student;
  readonly startLocation?: PostalAddress;
  readonly bookedTrainingId: string;
  readonly instructorId: string;
  readonly options?: {
    readonly returnRemoved?: boolean;
    readonly ignoreMaxDaysInAdvance?: boolean;
    readonly ignoreMaxBookableDrivingLessonsPerWeek?: boolean;
  };
}
