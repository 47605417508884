import { StudentTab } from "./StudentTab";
import {
  Datagrid,
  FunctionField,
  List,
  ReferenceOneField,
  TextField,
  useGetRecordId,
  useRecordContext,
} from "react-admin";
import { DateField } from "../../fields/DateField";
import { LinkToFirestoreField } from "../../fields/LinkToFirestoreField";
import { EmptyState } from "../../misc/EmptyState";
import { EventStatusReasonTooltip as EventStatusReasonTooltip } from "../../misc/EventStatusReasonTooltip";
import { AutovioCalendarEvent, DrivingLesson } from "../../model/autovioCalendarEvents";
import { grants } from "../../backoffice.access_control";
import { useEffect, useState } from "react";
import { EventDialog } from "../../misc/calendar/EventDialog";
import { studentDrivingLessonsProvider } from "../../providers/studentDrivingLessonsProvider";

const DRIVING_LESSON_TYPE_LABELS = {
  normal: "Normal",
  ueberlandfahrt: "Überlandfahrt",
  autobahnfahrt: "Autobahnfahrt",
  nachtfahrt: "Nachtfahrt",
  praktischePruefung: "Praktische Prüfung",
  schaltkompetenz: "Schaltkompetenz",
  praktischeUnterweisung: "Praktische Unterweisung",
  fahrprobe: "ASF Fahrprobe",
};

const _renderLessonType = (record: DrivingLesson): string => {
  const label = DRIVING_LESSON_TYPE_LABELS[record.drivingLessonType] ?? "Unbekannt";
  const durationInMinutes = Math.round(record.end.diff(record.start).as("minutes"));
  return `${label} (${durationInMinutes} Minuten)`;
};

const _renderLessonStatus = (record: DrivingLesson): string => {
  switch (record.status) {
    case "invited":
      return "⏳️ Eingeladen";
    case "booked":
      return "✔ Gebucht";
    case "declinedByStudent":
      return "❌ Einladung abgelehnt";
    case "canceledByStudent":
      return "❌ Abgesagt vom Fahrschüler";
    case "canceledByInstructor":
      return "❌ Abgesagt vom Fahrlehrer";
    case "finished":
      return "🏁 Beendet";
    case "passed":
      return "👍 Bestanden";
    case "failed":
      return "👎 Durchgefallen";
    case "no-show":
      return "❌ No-Show";
  }
  console.error("Could not render status of record", record);
  return "? Unbekannt";
};

export function StudentDrivingLessonsList() {
  const studentUid = useGetRecordId();
  const [selectedDrivingLesson, setSelectedDrivingLesson_] = useState<DrivingLesson | undefined>(undefined);
  const setSelectedDrivingLesson = (drivingLesson?: DrivingLesson) => {
    // Side effect: update browser URL ...
    const url = new URL(location.href);
    if (drivingLesson) {
      if (url.searchParams.get("openDrivingLesson") !== drivingLesson.id) {
        url.searchParams.set("openDrivingLesson", drivingLesson.id);
        browserHistory.replace(url.toString());
      }
    } else {
      url.searchParams.delete("openDrivingLesson");
      url.searchParams.delete("showHistory");
      browserHistory.replace(url.toString());
    }
    setSelectedDrivingLesson_(drivingLesson);
  };
  useEffect(() => {
    const openDrivingLessonId = new URL(location.href).searchParams.get("openDrivingLesson");
    if (openDrivingLessonId) {
      void studentDrivingLessonsProvider
        .getOne("studentDrivingLessons", { id: openDrivingLessonId })
        .then(({ data }) => setSelectedDrivingLesson(data));
    }
  }, []);
  return (
    <StudentTab>
      <List
        resource="studentDrivingLessons"
        title=" " // <-- prevent that the default list title is rendered
        filter={{ studentUid }}
        sort={{ field: "start", order: "DESC" }}
        empty={<EmptyState label="Noch keine Fahrstunden gebucht." />}
        exporter={false}
        sx={{ marginTop: "-15px" }}
      >
        <Datagrid
          bulkActionButtons={false}
          rowClick={(_, __, drivingLesson) => {
            setSelectedDrivingLesson(drivingLesson as DrivingLesson);
            return false;
          }}
        >
          <DateField label="Datum" source="start" showTime />
          <FunctionField label="Art" sortBy="type" render={_renderLessonType} />
          <ReferenceOneField label="Fahrlehrer" reference="instructors" target="id" source="instructorId">
            <TextField source="name" />
          </ReferenceOneField>
          <LessonStatusField />
          {grants.includes("viewFirestore") && (
            <LinkToFirestoreField label="" source="id" collection="/calendar_events" />
          )}
        </Datagrid>
        {selectedDrivingLesson && (
          <EventDialog event={selectedDrivingLesson} isOpen onClose={() => setSelectedDrivingLesson(undefined)} />
        )}
      </List>
    </StudentTab>
  );
}

const LessonStatusField = () => {
  const event = useRecordContext<AutovioCalendarEvent>();
  return (
    <EventStatusReasonTooltip event={event}>
      <FunctionField source="status" render={_renderLessonStatus} />
    </EventStatusReasonTooltip>
  );
};
