import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, IconButton, Typography } from "@mui/material";
import { CSSProperties, useRef } from "react";
import { Tab } from "react-admin";
import { ThreadContext } from "../contexts/ThreadContext.js";
import { autovioColors } from "../misc/backofficeTheme";
import { Tabs } from "../misc/Tabs";
import { PostsList } from "./PostsList.js";
import { ThreadDocumentsList } from "./ThreadDocuments.js";

export function Thread({
  showToggleButton,
  handleDrawerToggle,
  open,
  style,
  threadId,
}: {
  handleDrawerToggle?: () => void;
  open?: boolean;
  showToggleButton?: boolean;
  style?: CSSProperties;
  threadId: string;
}) {
  const { green } = autovioColors;
  const ref = useRef<{ showForm: () => void }>();

  return (
    <ThreadContext.Provider value={{ threadId }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: "15px" }}>
        <Typography sx={{ flex: 1, fontWeight: "bold", fontSize: 15 }}>Notizen & Dokumente</Typography>
        <Box sx={{ display: "flex", gap: "20px" }}>
          <IconButton sx={{ color: green, width: 30, height: 30 }} onClick={() => ref.current?.showForm()}>
            <AddIcon style={{ fontSize: 24 }} />
          </IconButton>
          {showToggleButton && (
            <IconButton sx={{ color: green, width: 30, height: 30 }} onClick={() => handleDrawerToggle?.()}>
              <KeyboardArrowDownIcon style={{ fontSize: 24, transform: open ? "rotate(0deg)" : "rotate(180deg)" }} />
            </IconButton>
          )}
        </Box>
      </Box>
      <Tabs syncWithLocation={false} fullWidth>
        <Tab label="Notizen">
          <PostsList ref={ref} style={style} />
        </Tab>
        <Tab label="Dokumente">
          <ThreadDocumentsList ref={ref} style={style} />
        </Tab>
      </Tabs>
    </ThreadContext.Provider>
  );
}
