import type { Student } from "../providers/studentsProvider";
import { StudentBookedQuotesList } from "../lists/StudentBookedQuotesList";
import { AddBundleButton } from "../buttons/AddBundleButton";
import { DialogProps } from "../misc/DialogProps";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DialogCloseButton } from "../misc/DialogCloseButton";

export function BookedQuotesDialog({ student, ...dialogProps }: { student: Student } & DialogProps) {
  return (
    <Dialog {...dialogProps} maxWidth="md">
      <DialogTitle>Gebuchte Pakete</DialogTitle>
      <DialogCloseButton onClose={dialogProps.onClose} />
      <DialogContent>
        <StudentBookedQuotesList />
      </DialogContent>
      <DialogActions>
        <AddBundleButton />
      </DialogActions>
    </Dialog>
  );
}
