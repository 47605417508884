import { FC, memo } from "react";
import { LinearProgress, RecordContextProvider, useListContext } from "react-admin";
import ErrorIcon from "@mui/icons-material/Error";
import * as React from "react";

export interface CommaSeparatedFieldListProps {
  children: React.ReactElement;
  newLines?: boolean;
}

export const CommaSeparatedFieldList: FC<CommaSeparatedFieldListProps> = memo((props) => {
  const { children } = props;
  const { isLoading, error, data } = useListContext(props);

  if (isLoading) {
    return <LinearProgress />;
  }

  if (error) {
    return <ErrorIcon role="presentation" color="error" fontSize="small" />;
  }

  return (
    <>
      {data.map((record, index) => (
        <RecordContextProvider value={record} key={record.id ?? `index${index}`}>
          {children}
          {index < data.length - 1 ? ", " : ""}
          {index < data.length - 1 && props.newLines ? <br /> : ""}
        </RecordContextProvider>
      ))}
    </>
  );
});
