import { Box } from "@mui/material";
import { overviewCardStyle } from "../../../misc/backofficeTheme";
import { useAutovioContext } from "../../../hooks/useAutovioContext.js";
import { useGetOne } from "react-admin";
import type { DrivingSchool } from "../../../providers/drivingSchoolsProvider.js";
import { Thread } from "../../../lists/Thread.js";
import { NotesAndDocuments } from "../../../lists/NotesAndDocuments.js";

export function NotesAndDocumentsCard() {
  const [{ drivingSchoolId }] = useAutovioContext();
  if (!drivingSchoolId) {
    return;
  }
  const { data: drivingSchool } = useGetOne<DrivingSchool>(
    "drivingSchools",
    { id: drivingSchoolId },
    { enabled: !!drivingSchoolId },
  );
  if (!drivingSchool) {
    return;
  }
  return (
    <Box sx={overviewCardStyle}>
      {drivingSchool.threadId ? (
        <Thread threadId={drivingSchool.threadId} />
      ) : (
        <NotesAndDocuments source="drivingSchool" />
      )}
    </Box>
  );
}
